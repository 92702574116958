import React, { useState } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "./FormComponent.css";
import { useHistory } from 'react-router-dom';


export default function FirmwareFormComponent() {
    const token = useSelector((state) => state.loginData.token);
    const [firmwareData, setFirmwareData] = useState({
        firmware_version: '',
        changelog: '',
        directory: null,
        image: null,
        version: ''
    });
    const [isUploading, setIsUploading] = useState(false);
    const history = useHistory();

    function handleInputChange(e) {
        const { name, value } = e.target;
        setFirmwareData(prevState => ({
            ...prevState,
            [name]: value,
        }));
    }

   
    function handleFileChange(e) {
        const { name, files } = e.target;
        if (files.length > 0) { // Only set file if one was chosen
            setFirmwareData(prevState => ({
                ...prevState,
                [name]: files[0],
            }));
        } else {
            // Handle case where file was selected but then user cleared the selection
            setFirmwareData(prevState => ({
                ...prevState,
                [name]: null,
            }));
        }
    }

    function handleClearForm() {
        setFirmwareData({
            firmware_version: '',
            changelog: '',
            directory: null,
            image: null,
            version: ''
        });
    }
    const [validated, setValidated] = useState(false);
   
    function handleSubmit(e) {
        const form = e.currentTarget;
        if (form.checkValidity() === false) {
          e.preventDefault();
          e.stopPropagation();
        }
        setValidated(true);
        e.preventDefault();
        setIsUploading(true); // Begin the upload process
        const formData = new FormData();
        Object.keys(firmwareData).forEach(key => {
            formData.append(key, firmwareData[key] || ''); 
        });

        axios.post(`${process.env.REACT_APP_BASE_URL}/create_firmware`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${token}`,
            },
        })
        .then((res) => {
            toast.success(res.data.message || "Firmware uploaded successfully!");
            handleClearForm(); 
            history.push('/firmware');
        })
        .catch((error) => {
            toast.error(error.response?.data?.message || "An error occurred!");
        })
        .finally(() => {
            setIsUploading(false);
        });
    }

    return (
        <>
            <section className="login-main">
                <div className="container h-100">
                    <div className="row justify-content-center p-0 m-0 h-100">
                        <div className="col-xxl-8 col-lg-9 col-md-12 col-sm-12 py-5 h-100">
                            <div className="content">
                                <div className="card card-border-radius wider-card w-100 p-lg-3 p-sm-2">
                                    <div className="card-title">
                                        <h2 className="p-3 pb-1">Firmware Upload</h2>
                                    </div>
                                    <div className="card-body">
                                    <form
      className={`needs-validation ${validated ? 'was-validated' : ''}`}
      noValidate
      onSubmit={handleSubmit}  encType="multipart/form-data"
    >
                                        {/* <form onSubmit={handleSubmit} className="was-validated"> */}
                                            <div className="mb-4">
                                                <label htmlFor="firmware_version" className="form-label login-form-label">
                                                    <strong>Firmware Version</strong>
                                                </label>
                                                <input
                                                    type="text"
                                                    className="form-control login-form-input"
                                                    id="firmware_version"
                                                    name="firmware_version"
                                                    value={firmwareData.firmware_version}
                                                    onChange={handleInputChange}
                                                    required
                                                />
                                                <div className="invalid-feedback">
                                                    Please fill out this field.
                                                </div>
                                            </div>
                                            <div className="mb-4">
                                                <label htmlFor="changelog" className="form-label login-form-label">
                                                    <strong>Changelog</strong>
                                                </label>
                                                <textarea
                                                    className="form-control login-form-input"
                                                    id="changelog"
                                                    name="changelog"
                                                    value={firmwareData.changelog}
                                                    onChange={handleInputChange}
                                                    required
                                                />
                                                <div className="invalid-feedback">
                                                    Please fill out this field.
                                                </div>
                                            </div>
                                            <div className="mb-4">
                                                <label htmlFor="directory" className="form-label login-form-label">
                                                    <strong>Directory (File or Folder)</strong>
                                                </label>
                                                <input
                                                    type="file"
                                                    className="form-control login-form-input"
                                                    id="directory"
                                                    name="directory"
                                                    onChange={handleFileChange}
                                                    required
                                                />
                                                <div className="invalid-feedback">
                                                    Please upload the required file or folder.
                                                </div>
                                            </div>
                                            <div className="mb-4">
                                                <label htmlFor="image" className="form-label login-form-label">
                                                    <strong>Image (Image File)</strong>
                                                </label>
                                                <input
                                                    type="file"
                                                    className="form-control login-form-input"
                                                    id="image"
                                                    name="image"
                                                    accept="image/*"
                                                    onChange={handleFileChange}
                                                />
                                                <div className="invalid-feedback">
                                                    Please upload the required image.
                                                </div>
                                            </div>
                                            <div className="mb-4">
                                                <label htmlFor="version" className="form-label login-form-label">
                                                    <strong>Version</strong>
                                                </label>
                                                <input
                                                    type="text"
                                                    className="form-control login-form-input"
                                                    id="version"
                                                    name="version"
                                                    value={firmwareData.version}
                                                    onChange={handleInputChange}
                                                    required
                                                />
                                                <div className="invalid-feedback">
                                                    Please fill out this field.
                                                </div>
                                            </div>
                                            <div className="d-flex justify-content-start">
                                                <button 
                                                    type="submit" 
                                                    className="btn btn-sm text-light main-bg btn-style me-2" 
                                                    disabled={isUploading}
                                                >
                                                    <b>{isUploading ? "Uploading..." : "Upload Firmware"}</b>
                                                </button>
                                                <button 
                                                    type="button" 
                                                    className="btn btn-sm text-light main-bg btn-style" 
                                                    onClick={handleClearForm}
                                                    disabled={isUploading}
                                                >
                                                    <b>Clear Form</b>
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <ToastContainer />
        </>
    );
}
