import React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import "./loader.css";

const Loader = () => {
  return (
    <>
      <div className="loader-wrapper d-flex justify-content-center align-items-center">
        <CircularProgress color="inherit" size="3rem" />
      </div>
    </>
  );
};

export default Loader;
