import React, { useState } from "react";
import { Container, Row, Col } from "reactstrap";

import TabList from "./TabList";
import TabContent from "./TabContent";

import "./Tabs.css";

import PersonalSettingsForm from '../AccountSettingForms/PersonalSettingsForm'
import SecuritySettingsForm from '../AccountSettingForms/SecuritySettingsForm'
import ClinicalSettingsForm from '../AccountSettingForms/ClinicalSettingsForm'
import BillingForm from '../AccountSettingForms/BillingForm'

function Tabs(props) {
  const [activeTabId, setActiveTabId] = useState(0);

  function btnClick(id) {
    setActiveTabId(id);
  }

  return (
    <Container className="section__Jobs-container">
      <p className="my-3 ac-sett">Account Settings</p>
      <Row className="h-70vh">
        <Col sm="12" lg="3" className="bg-white px-4 bd-bg">
          <div className="section__Jobs-styledTab">
            <ul className="section__Jobs-styledTabList">
              {/* {props.data.map((job, index) => (
                <TabList
                  key={index}
                  onClick={btnClick}
                  data={job}
                  index={index}
                  activeTabId={activeTabId}
                />
              ))} */}
               {/* Tabs */}
                <TabList
                  onClick={btnClick}
                  data='Personal Settings'
                  index={0}
                  activeTabId={activeTabId}
                />

                <TabList
                  onClick={btnClick}
                  data='Security'
                  index={1}
                  activeTabId={activeTabId}
                />

                <TabList
                  onClick={btnClick}
                  data='Clinical Settings'
                  index={2}
                  activeTabId={activeTabId}
                />

                <TabList
                  onClick={btnClick}
                  data='Billing'
                  index={3}
                  activeTabId={activeTabId}
                />    
            </ul>
          </div>
        </Col>
        <Col  sm="12"  lg="8" className="bg-white margins-4 bd-bg">
          {/* {props.data.map((job, index) => (
            <TabContent
              key={index}
              index={index}
              activeTabId={activeTabId}
           >

            </TabContent>
          ))} */}



         

          
          

          {/* Tabs Content*/}
           <TabContent
              index={0}
              activeTabId={activeTabId}
           >
            <PersonalSettingsForm/>
            </TabContent>

            <TabContent
              index={1}
              activeTabId={activeTabId}
           >
            <SecuritySettingsForm/>
            </TabContent>

            <TabContent
              index={2}
              activeTabId={activeTabId}
           >
            <ClinicalSettingsForm/>
            </TabContent>

            <TabContent
              index={3}
              activeTabId={activeTabId}
           >
            <BillingForm/>
            </TabContent>
            
        </Col>
      </Row>
      
    </Container>
  );
}

export default Tabs;
