// import "../../assets/css/style2.css";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { listPatient, deletedPatientList } from "../../actions/patientActions";
import Modal from 'react-bootstrap/Modal';
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// swiper bundle styles
import "swiper/swiper-bundle.min.css";
// swiper core styles
import "swiper/swiper.min.css";
// modules styles
import "swiper/components/navigation/navigation.min.css";
import "swiper/components/pagination/pagination.min.css";
// import Swiper core and required modules
import SwiperCore, { Pagination, Scrollbar } from "swiper";

// import "../assets/css/pateint.css";
import "./deleted-patient-list.css";
import ListHeader from "../list-record-components/list-header/ListHeader";
import ListView from "../list-record-components/list-view/ListView";
import GridView from "../list-record-components/grid-view/GridView";
import Loader from "../loader/Loader";
// install Swiper modules
SwiperCore.use([Pagination, Scrollbar]);

const KEYS = [
  "name",
  "age",
  "gender",
  // "patient#",
  // "last_visit_date",
  "clinical_condition",
];

export default function DeletedPatientList() {
  const dispatch = useDispatch();
  const token = useSelector((state) => state.loginData.token);
  const patientList = useSelector((state) => state.patientList);
  const { error, loading, patients } = patientList;
  const [isEmpty, setIsEmpty] = useState(false);
  const [activeView, setActiveView] = useState("list");
  const [numberOfCols, setNumberOfCols] = useState(KEYS.length);
  const [keys, setKeys] = useState(KEYS);

  const [patientsData, setPatientsData] = useState([]);
  const [searchField, setSearchField] = useState("");
  const [assending, setAssending] = useState(true);

  const [show, setShow] = useState(false);
  const [patientDeleteId, setPatientDeleteId] = useState(null);


  const handleClose = () => setShow(false);

  useEffect(() => {
    dispatch(deletedPatientList(token));
  }, [dispatch, token]);

  const handleSarch = (e) => {
    const searchField = e.target.value.toLocaleLowerCase();
    setSearchField(searchField);
  };

  const handleActiveView = (view) => setActiveView(view);

  useEffect(() => {
    const transformedPatients = transformData(patients);
    setPatientsData(transformedPatients);
  }, [patients]);

  const deletePatientPermanently = async (index) => {
    setPatientDeleteId(index)
    setShow(true);
  };

  const restorePatient = async (id) => {
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_BASE_URL}/patient/restore-deleted-patient/${id}`,
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
      console.log(response.data);
      let newPatientList = patientsData.filter(
        (patient) => patient.id != id
      );
      setShow(false);
      setPatientsData(newPatientList);
      toast.success("Patient restored successfully")
    } catch (error) {
      console.error("Error restoring patient:", error);
      toast.error('Failed to restore patient');
    }
  };
  const deletePatientPermanentlyConfirm = async () => {
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_BASE_URL}/patient/hard-delete-patient/${patientDeleteId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
      console.log(response.data);
      let newPatientList = patientsData.filter(
        (patient) => patient.id != patientDeleteId
      );
      setShow(false);
      setPatientsData(newPatientList);
      toast.error("Patient history deleted permanently")
    } catch (error) {
      console.error("Error restoring patient:", error);
      toast.error('Failed to restore patient');
    }
  };

  const handleSort = () => {
    setAssending((prevState) => !prevState);
    let newPatients = [...patientsData];
    newPatients = newPatients.sort(function (a, b) {
      const patientNumberA = a.id;
      const patientNumberB = b.id;

      if (assending) {
        return patientNumberB - patientNumberA;
      } else {
        return patientNumberA - patientNumberB;
      }
    });
    setPatientsData(newPatients);
  };

  // to handle search filter by name
  useEffect(() => {
    if (searchField.length) {
      const filteredPatient = patientsData.filter((patient) => {
        return patient.name.toLocaleLowerCase().includes(searchField);
      });
      setPatientsData(filteredPatient);
    } else {
      setPatientsData(transformData(patients));
    }
  }, [searchField]);

  function transformData(inputData) {
    console.log("INPUT DATA: ", inputData);
    const fieldsToExtract = [
      "name",
      "age",
      "gender",
      "clinical_condition",
    ];
    const transformedData = [];

    inputData.forEach((item) => {
      const extractedFields = {};

      fieldsToExtract.forEach((field) => {
        extractedFields.id = item.id;
        if (field === "clinical_condition") {
          const conditionMap = {
            1: "seizure",
            2: "concussion",
            3: "stroke",
            0: "0",
          };
          extractedFields[field] = conditionMap[item[field]] || "null";
        } else if (field === "gender") {
          const genderMap = {
            1: "M",
            2: "F",
          };
          extractedFields[field] = genderMap[item[field]] || "null";
        } else {
          extractedFields[field] =
            item[field] !== undefined ? item[field] : "null";
        }
      });

      transformedData.push(extractedFields);
    });
    return transformedData;
  }

  return (
    <>
    <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Modal show={show} onHide={deletePatientPermanently} animation={false}>
        <Modal.Header closeButton>
          <Modal.Title>Patient Delete Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this patient?</Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="danger" onClick={deletePatientPermanentlyConfirm}>
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
      <section className="patient-list">
        <ListHeader
          title="Deleted Patients"
          activeView={activeView}
          handleActiveView={handleActiveView}
          handleSarch={handleSarch}
        />
        {loading ? (
          <Loader />
        ) : !error ? (
          <>
            {activeView === "list" ? (
              <ListView
                len={numberOfCols}
                data={patientsData}
                keys={keys}
                handleSort={handleSort}
                restorePatient={restorePatient}
                deletePatientPermanently={deletePatientPermanently}
                forHistoryPage={false}
                forDeletedPage={true}
              />
            ) : (
              <GridView
                len={numberOfCols}
                data={patientsData}
                keys={keys}
              />
            )}
          </>
        ) : (
          <div
            style={{ textAlign: "center", fontWeight: "bold", color: "red" }}
          >
            No Data Exists
          </div>
        )}
      </section>
    </>
  );
}
