import React, { useState } from "react";
import axios from "axios";
import { useDispatch } from "react-redux";
import { loginUser } from "../redux/logins";
import companyLogo from "../assets/img/logosync.png";
import { Link, useHistory } from "react-router-dom";
import Alert from "react-bootstrap/Alert";
import Footer from "../components/Footer";
import "../assets/css/login.css";

import { ReactComponent as ShowPasswordIcon } from "../assets/img/svg/show-password.svg";
import { ReactComponent as HidePasswordIcon } from "../assets/img/svg/hide-password.svg";
import { ReactComponent as LeftArrow } from "../assets/img/svg/left-triangle.svg";
import { ReactComponent as TickIcon } from "../assets/img/svg/tick.svg";
import { ReactComponent as CrossIcon } from "../assets/img/svg/cross.svg";
import { ReactComponent as IconTi } from "../assets/img/svg/circle-tick.svg";
import { ReactComponent as IconTin } from "../assets/img/svg/not-circle.svg";
export default function Login() {
  const dispatch = useDispatch();
  const [loginAttempt, setLoginAttempt] = useState(true);
  const history = useHistory();

  const [passwordType, setPasswordType] = useState('password');
  const togglePasswordType = () => setPasswordType((prevState) =>  prevState === 'password' ? 'text' : 'password');
  const [passwordStrength, setPasswordStrength] = useState(null);
  const onPasswordChange = (e) => {
    let passwordStrength = checkPasswordStrength(e.target.value);
    setPasswordStrength(passwordStrength);
  }
  const [upperCase, setUpperCase] = useState(false);
  const [lowerCase, setLowerCase] = useState(false);
  const [numericCase, setNumericCase] = useState(false);
  const [specialCase, setSpecialCase] = useState(false);

  const [isFocused, setIsFocused] = useState(false);

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  const checkPasswordStrength = (password) => {
    // Define regex patterns for different character types
    var patterns = {
        lowerCase: /[a-z]/,
        upperCase: /[A-Z]/,
        numbers: /[0-9]/,
        specialChars: /[^A-Za-z0-9\s]/
    };

    // Check if password meets the criteria
    var hasLowerCase = patterns.lowerCase.test(password);
    var hasUpperCase = patterns.upperCase.test(password);
    var hasNumbers = patterns.numbers.test(password);
    var hasSpecialChars = patterns.specialChars.test(password);

    setLowerCase(hasLowerCase);
    setUpperCase(hasUpperCase);
    setNumericCase(hasNumbers);
    setSpecialCase(hasSpecialChars);

    // Calculate strength based on criteria met
    var strength = 0;
    strength += hasLowerCase ? 1 : 0;
    strength += hasUpperCase ? 1 : 0;
    strength += hasNumbers ? 1 : 0;
    strength += hasSpecialChars ? 1 : 0;

    // Calculate password length bonus
    var lengthBonus = Math.min(2, password.replace(/\s/g, '').length / 8);
    strength += lengthBonus;

    // Return strength as a percentage
    console.log(Math.min(Math.round((strength / 6) * 100), 100));
    return Math.min(Math.round((strength / 6) * 100), 100);
  }


  const [validated, setValidated] = useState(false);
  function handleSubmit(e) {
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
    }
    setValidated(true);
    e.preventDefault();
    e.preventDefault();
    const login_credentials = {
      username: e.target[0].value,
      password: e.target[1].value,
    };
    axios
      .post(`${process.env.REACT_APP_BASE_URL}/login/`, login_credentials)
      .then((res) => {
        if (res.data.status === true) {
          setLoginAttempt(true);
          dispatch(
            loginUser({ userId: res.data.doctor_id, token: res.data.token })
          );
          localStorage.setItem("status", true);
          localStorage.setItem("userId", res.data.doctor.id);
          localStorage.setItem("token", res.data.token);
          // history.push({
          //   pathname: "/dashboard",
          // });
          window.location.href = "/dashboard"
        } else {
          setLoginAttempt(false);
        }
      });
  }

  return (
    <>
      <section className="login-main">
        <div className="container h-100">
          <div className="row justify-content-center p-0 m-0 h-100">
            <div className="col-xxl-5 col-lg-7 col-md-12 col-sm-12 py-5 h-100">
              <div className="content">
                <div className="div-image">
                <a href="/">
                  <img
                    src="https://censyn-firmware-data.s3.amazonaws.com/CenSyn-logo.svg"
                    className="logo-image-class"
                    alt="company logo"
                  />
                </a>
                </div>
                <div className="card card-border-radius w-100 p-lg-5 p-sm-2">
                  <div className="card-title">
                    <h2 className="p-3 pb-1">Sign in</h2>
                  </div>
                  <div className="card-body">
                  <form
      className={`needs-validation ${validated ? 'was-validated' : ''}`}
      noValidate
      onSubmit={handleSubmit}
    >
                    {/* <form onSubmit={handleSubmit} className="was-validated"> */}
                      <div className="mb-4">
                        <label
                          htmlFor="username"
                          className="form-label login-form-label"
                        >
                          Email
                        </label>
                        <input
                          type="email"
                          className="form-control login-form-input"
                          id="username"
                          required
                        />
                        <div className="valid-feedback">Valid.</div>
                        <div className="invalid-feedback">
                          Please fill out this field.
                        </div>
                      </div>
                      <div className="mb-4 password-field">
                        <div className="d-flex justify-content-between">
                          <label
                            htmlFor="password"
                            className="form-label login-form-label"
                          >
                            Password
                          </label>
                          <p className="forgot-text">
                            <Link to="/reset-password">
                              Forgot your Password?
                            </Link>
                          </p>
                        </div>
                        <input
                          type={passwordType}
                          className="form-control login-form-input"
                          id="password"
                          required
                          onChange={(e) => onPasswordChange(e)}
                          onFocus={handleFocus}
                          onBlur={handleBlur}
                        />
                        {
                          passwordType === 'password' ?
                          <ShowPasswordIcon onClick={togglePasswordType} className="eye-icon" /> :
                          <HidePasswordIcon onClick={togglePasswordType} className="eye-icon" />
                        }
                         
                        {isFocused && 
                     
                            <div className="password-checker">
                             <p className="margins-b">Password Requirements</p>
                            <div className="check-list">
                              <div className={`check ${upperCase ? 'line-through' : ''}`}>
                                {upperCase ? <IconTi /> : <IconTin />}
                                One Upper Case
                              </div>
                              <div className={`check ${lowerCase ? 'line-through' : ''}`}>
                                {lowerCase ? <IconTi /> : <IconTin />}
                                One Lower Case
                              </div>
                              <div className={`check ${numericCase ? 'line-through' : ''}`}>
                                {numericCase ? <IconTi /> : <IconTin />}
                                One Numeric
                              </div>
                              <div className={`check ${specialCase ? 'line-through' : ''}`}>
                                {specialCase ? <IconTi /> : <IconTin />}
                                One Special Character
                              </div>
                            </div>
                            <div className="strength-container">
                            <div className="instructions">Strength: &nbsp;&nbsp;
                              {passwordStrength <= 50 && <span className="weak">Weak</span>}
                              {(passwordStrength > 50 && passwordStrength <= 80) && <span className="medium">Medium</span>}
                              {passwordStrength > 80 && <span className="strong">Strong</span>}
                            </div>
                              <div className="strengths">
                                <div className={`strength ${(passwordStrength <= 50 || passwordStrength > 50) && 'weak-bg'}`} />
                                <div className={`strength ${((passwordStrength > 50 && passwordStrength <= 80) || passwordStrength > 80) && 'medium-bg'}`} />
                                <div className={`strength ${passwordStrength > 80 && 'strong-bg'}`} />
                              </div>
                            </div>
                            <LeftArrow className="arrow" />
                          </div>
                          }
                      </div>
                      <div className="mb-4">
                        <div className="new">
                          <div className="form-group-new">
                            <input type="checkbox" id="html" />
                            <label htmlFor="html">Stay signed in</label>
                          </div>
                        </div>
                      </div>
                      <div className="d-grid">
                        <button
                          type="submit"
                          className="btn text-light main-bg btn-style"
                        >
                          <b>Login</b>
                        </button>
                        <p className="text-center my-4 bottom-text">
                          Don't have an account?{" "}
                          <Link to="/register">Sign Up</Link>
                        </p>
                      </div>
                      {!loginAttempt && (
                        <div style={{ paddingTop: "15px" }}>
                          <Alert variant="danger">
                            <p>Invalid Username or Password</p>
                          </Alert>
                        </div>
                      )}
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
