import React, { useState, useEffect } from "react";
import axios from "axios";
import companyLogo from "../assets/img/logosync.png";
import { useHistory, useLocation } from "react-router-dom";
import Footer from "../components/Footer";
import Alert from "react-bootstrap/Alert";
import "../assets/css/login.css";

import { ReactComponent as ShowPasswordIcon } from "../assets/img/svg/show-password.svg";
import { ReactComponent as HidePasswordIcon } from "../assets/img/svg/hide-password.svg";
import { ReactComponent as LeftArrow } from "../assets/img/svg/left-triangle.svg";
import { ReactComponent as TickIcon } from "../assets/img/svg/tick.svg";
import { ReactComponent as CrossIcon } from "../assets/img/svg/cross.svg";

export default function ResetPasswordConfirm() {
  const [showSuccess, setShowSuccess] = useState(false);
  const history = useHistory();
  const location = useLocation();

  const [uid, setUid] = useState("");
  const [token, setToken] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [resetAttempt, setResetAttempt] = useState(true);

  // password fields states
  const [passwordType, setPasswordType] = useState('password');
  const [confirmPasswordType, setConfirmPasswordType] = useState('password');
  const togglePasswordType = () => setPasswordType((prevState) =>  prevState === 'password' ? 'text' : 'password');
  const toggleConfirmPasswordType = () => setConfirmPasswordType((prevState) =>  prevState === 'password' ? 'text' : 'password');
  const [passwordStrength, setPasswordStrength] = useState(null);
  const [confirmPasswordStrength, setConfirmPasswordStrength] = useState(null);
  const onPasswordChange = (e) => {
    setNewPassword(e.target.valeu);
    let passwordStrength = checkPasswordStrength(e.target.value);
    setPasswordStrength(passwordStrength);
  }
  const onConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.valeu);
    let passwordStrength = checkPasswordStrength(e.target.value);
    setConfirmPasswordStrength(passwordStrength);
  }
  const [upperCase, setUpperCase] = useState(false);
  const [lowerCase, setLowerCase] = useState(false);
  const [numericCase, setNumericCase] = useState(false);
  const [specialCase, setSpecialCase] = useState(false);

  const [isFocused, setIsFocused] = useState(false);
  const [isConfirmFocused, setIsConfirmFocused] = useState(false);

  const togglePasswordFocused = () => setIsFocused((prevState) => !prevState);
  const toggleConfirmFocused = () => setIsConfirmFocused((prevState) => !prevState);

  const checkPasswordStrength = (password) => {
    // Define regex patterns for different character types
    var patterns = {
        lowerCase: /[a-z]/,
        upperCase: /[A-Z]/,
        numbers: /[0-9]/,
        specialChars: /[^A-Za-z0-9\s]/
    };

    // Check if password meets the criteria
    var hasLowerCase = patterns.lowerCase.test(password);
    var hasUpperCase = patterns.upperCase.test(password);
    var hasNumbers = patterns.numbers.test(password);
    var hasSpecialChars = patterns.specialChars.test(password);

    setLowerCase(hasLowerCase);
    setUpperCase(hasUpperCase);
    setNumericCase(hasNumbers);
    setSpecialCase(hasSpecialChars);

    // Calculate strength based on criteria met
    var strength = 0;
    strength += hasLowerCase ? 1 : 0;
    strength += hasUpperCase ? 1 : 0;
    strength += hasNumbers ? 1 : 0;
    strength += hasSpecialChars ? 1 : 0;

    // Calculate password length bonus
    var lengthBonus = Math.min(2, password.replace(/\s/g, '').length / 8);
    strength += lengthBonus;

    // Return strength as a percentage
    console.log(Math.min(Math.round((strength / 6) * 100), 100));
    return Math.min(Math.round((strength / 6) * 100), 100);
  }
  // password fields states

  useEffect(() => {
    const url = window.location.href.split("/").slice(-3);
    setUid(url[0]);
    setToken(url[1]);
  }, [location]);

  function handleSubmit(e) {
    e.preventDefault();

    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/password-reset/confirm/${uid}/${token}/`,
        {
          new_password1: newPassword,
          new_password2: confirmPassword,
          uid: uid,
          token: token,
        }
      )
      .then((res) => {
        if (res.status === 200) {
          setShowSuccess(true);
          history.push("/login");
        } else {
          setResetAttempt(false);
        }
      })
      .catch((error) => {
        // Handle errors, if any
        console.error("Error resetting password:", error);
      });
  }

  return (
    <>
      <section className="login-main">
        <div className="container h-100">
          <div className="row justify-content-center p-0 m-0 h-100">
            <div className="col-xxl-5 col-lg-7 col-md-12 col-sm-12 py-5 h-100">
              <div className="content">
                <div className="div-image">
                  {/* <img
                    src={companyLogo}
                    className="logo-image-class"
                    alt="company logo"
                  /> */}
                  <img
                    src="https://censyn-firmware-data.s3.amazonaws.com/CenSyn-logo.svg"
                    className="logo-image-class"
                    alt="company logo"
                  />
                </div>
                <div className="card card-border-radius w-100 p-lg-5 p-sm-2">
                  <div className="card-title">
                    <h2 className="p-3 pb-1">Reset Password Confirm</h2>
                  </div>
                  <div className="card-body">
                    {showSuccess && (
                      <Alert variant="success">
                        Password reset successfully!
                      </Alert>
                    )}
                    <form onSubmit={handleSubmit} className="was-validated">
                      <div className="mb-4 password-field reset">
                        <label
                          htmlFor="username"
                          className="form-label login-form-label"
                        >
                          New Password
                        </label>
                        <input
                          type={passwordType}
                          className="form-control login-form-input"
                          id="newPassword"
                          value={newPassword} // Add this line to bind the value
                          required
                          onChange={(e) => onPasswordChange(e)}
                          onFocus={togglePasswordFocused}
                          onBlur={togglePasswordFocused}
                        />
                        {
                          passwordType === 'password' ?
                          <ShowPasswordIcon onClick={togglePasswordType} className="eye-icon" /> :
                          <HidePasswordIcon onClick={togglePasswordType} className="eye-icon" />
                        }
                        <div className="valid-feedback">Valid.</div>
                        <div className="invalid-feedback">
                          Please fill out this field.
                        </div>
                        {isFocused && 
                            <div className="password-checker">
                            <div className="check-list">
                              <div className="check">
                                {upperCase ? <TickIcon /> : <CrossIcon />}
                                One Upper Case
                              </div>
                              <div className="check">
                                {lowerCase ? <TickIcon /> : <CrossIcon />}
                                One Lower Case
                              </div>
                              <div className="check">
                                {numericCase ? <TickIcon /> : <CrossIcon />}
                                One Numeric
                              </div>
                              <div className="check">
                                {specialCase ? <TickIcon/> : <CrossIcon />}
                                One Special Character
                              </div>
                            </div>
                            <div className="strength-container">
                              <div className="instructions">Strength: &nbsp;
                                {passwordStrength <= 50 && <span className="weak">Weak</span>}
                                {(passwordStrength > 50 && passwordStrength <= 80) && <span className="medium">Medium</span>}
                                {passwordStrength > 80 && <span className="strong">Strong</span>}
                              </div>
                              <div className="strengths">
                                <div className={`strength ${(passwordStrength <= 50 || passwordStrength > 50) && 'weak-bg'}`} />
                                <div className={`strength ${((passwordStrength > 50 && passwordStrength <= 80) || passwordStrength > 80) && 'medium-bg'}`} />
                                <div className={`strength ${passwordStrength > 80 && 'strong-bg'}`} />
                              </div>
                            </div>
                            <LeftArrow className="arrow" />
                          </div>
                          }
                      </div>
                      <div className="mb-4 confirm-password-field reset">
                        <label
                          htmlFor="username"
                          className="form-label login-form-label"
                        >
                          Confirm Password
                        </label>
                        <input
                          type={confirmPasswordType}
                          className="form-control login-form-input"
                          id="confirmPassword"
                          value={confirmPassword}
                          required
                          onChange={(e) => onConfirmPasswordChange(e)}
                          onFocus={toggleConfirmFocused}
                          onBlur={toggleConfirmFocused}
                        />
                        {
                          confirmPasswordType === 'password' ?
                          <ShowPasswordIcon onClick={toggleConfirmPasswordType} className="eye-icon" /> :
                          <HidePasswordIcon onClick={toggleConfirmPasswordType} className="eye-icon" />
                        }
                        <div className="valid-feedback">Valid.</div>
                        <div className="invalid-feedback">
                          Please fill out this field.
                        </div>
                        {isConfirmFocused && 
                            <div className="password-checker">
                            <div className="check-list">
                              <div className="check">
                                {upperCase ? <TickIcon /> : <CrossIcon />}
                                One Upper Case
                              </div>
                              <div className="check">
                                {lowerCase ? <TickIcon /> : <CrossIcon />}
                                One Lower Case
                              </div>
                              <div className="check">
                                {numericCase ? <TickIcon /> : <CrossIcon />}
                                One Numeric
                              </div>
                              <div className="check">
                                {specialCase ? <TickIcon/> : <CrossIcon />}
                                One Special Character
                              </div>
                            </div>
                            <div className="strength-container">
                            <div className="instructions">Strength: &nbsp;
                                {confirmPasswordStrength <= 50 && <span className="weak">Weak</span>}
                                {(confirmPasswordStrength > 50 && confirmPasswordStrength <= 80) && <span className="medium">Medium</span>}
                                {confirmPasswordStrength > 80 && <span className="strong">Strong</span>}
                              </div>
                              <div className="strengths">
                                <div className={`strength ${(confirmPasswordStrength <= 50 || confirmPasswordStrength > 50) && 'weak-bg'}`} />
                                <div className={`strength ${((confirmPasswordStrength > 50 && confirmPasswordStrength <= 80) || confirmPasswordStrength > 80) && 'medium-bg'}`} />
                                <div className={`strength ${confirmPasswordStrength > 80 && 'strong-bg'}`} />
                              </div>
                            </div>
                            <LeftArrow className="arrow" />
                          </div>
                          }
                      </div>
                      <div className="d-grid">
                        <button
                          type="submit"
                          className="btn text-light main-bg btn-style"
                        >
                          <b>Reset Password</b>
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
