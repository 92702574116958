import React from "react";
import andy_image from "../assets/img/andy-300.png";
import ayushi_image from "../assets/img/ayushi-300.png";
import eeg_pen_image from "../assets/img/GEPS.png";
import pen_and_app from "../assets/img/penandapp-sized.png";
import trevor_image from "../assets/img/trevor-300.png";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";


function HomePage() {
  return (
    <div>
      <div id="layoutDefault">
        <div className="bg-info.bg-gradient" id="layoutDefault_content">
          <main>
            <header>
              <div className="container ">
                <Navbar />
              </div>
            </header>

            <section
              className="bg-info.bg-gradien py-15"
              style={{ fontFamily: "Lato" }}
            >
              <div className="container">
                <div className="row align-items-center mt-120">
                  <div className="col-md-8">
                    <img
                      className="full-img"
                      src={pen_and_app}
                      alt="pen-and-app"
                    />
                  </div>
                  <div className="col-md-4">
                    <h1>Modernize brain health screening</h1>
                    <p
                      className="lato-light-font-area"
                      style={{ fontFamily: "Lato" }}
                    >
                      From the intensive care unit to the sidelines of sport
                      games, the CenSyn platform is the next-generation 2 minute
                      solution to brain health screening.
                    </p>
                  </div>
                </div>
              </div>
            </section>

            <section className="bg-white py-10" style={{ fontFamily: "Lato" }}>
              <div className="container px-5" id="technology">
                <div className="row gx-5 align-items-center justify-content-center">
                  <div className="col-lg-6">
                    <div className="badge bg-primary-soft text-primary badge-marketing rounded-pill mb-3">
                      Our Platform
                    </div>
                    <div className="mb-5">
                      <h2>Here's how it works</h2>
                      <p className="lead">
                        CenSyn's PenEEG™ and proprietary software enables
                        long-term brain health management{" "}
                      </p>
                    </div>
                    <div className="row gx-5">
                      <div className="col-md-6 mb-4">
                        <h6>PenEEG™</h6>
                        <p className="mb-2 small">
                          Handheld PenEEG™ device to record brain electrical
                          activity on-the-go
                        </p>
                        <a className="small text-arrow-icon" href="/technology">
                          Learn More
                          <i data-feather="arrow-right"></i>
                        </a>
                      </div>
                      <div className="col-md-6 mb-4">
                        <h6>iOS app</h6>
                        <p className="mb-2 small mb-0">
                          iPhone and iPad application to wirelessly view EEG
                          data anytime, anywhere
                        </p>
                        <a className="small text-arrow-icon" href="/technology">
                          Learn More
                          <i data-feather="arrow-right"></i>
                        </a>
                      </div>
                    </div>
                    <div className="row gx-5">
                      <div className="col-md-6 mb-4">
                        <h6>Detection Algorithms</h6>
                        <p className="mb-2 small mb-0">
                          Proprietary machine learning algorithms to detect
                          presence of altered mental status
                        </p>
                        <a className="small text-arrow-icon" href="/technology">
                          Learn More
                          <i data-feather="arrow-right"></i>
                        </a>
                      </div>
                      <div className="col-md-6 mb-4">
                        <h6>Digital cognitive tests</h6>
                        <p className="small mb-0">
                          Tests to determine cognitive performance including
                          SCAT-5 and n-Back
                        </p>
                        <a className="small text-arrow-icon" href="/technology">
                          Learn More
                          <i data-feather="arrow-right"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-9 col-lg-6" data-aos="slide-left">
                    <div className="mb-4">
                      <div className="content-skewed content-skewed-left">
                        <img
                          className="img-fluid content-skewed-item  rounded-3"
                          src={eeg_pen_image}
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section
              className="bg-info.bg-gradient py-10"
              style={{ fontFamily: "Lato" }}
            >
              <div className="container px-5 ">
                <div className="row gx-5 justify-content-center ">
                  <div className="col-lg-6" id="platform">
                    <div className="text-center">
                      <div className="text-xs text-uppercase-expanded text-primary mb-2">
                        Our Solutions
                      </div>
                      <h2 className="mb-5">
                        Making brain health screening more accessible
                      </h2>
                    </div>
                  </div>
                </div>
                <div className="row gx-5">
                  <div className="col-lg-4 col-md-6 mb-5" data-aos="fade-up">
                    <a
                      className="card text-center text-decoration-none h-100 lift"
                      href="#!"
                    >
                      <div className="card-body py-5 ">
                        <div className="icon-stack icon-stack-lg bg-green-soft text-green mb-4">
                          <i data-feather="layers"></i>
                        </div>
                        <h5>Healthy</h5>
                        <p className="card-text small">
                          Longitudinal tracking of reaction time, fatigue, brain
                          performance & healthy background activity{" "}
                        </p>
                      </div>
                    </a>
                  </div>
                  <div
                    className="col-lg-4 col-md-6 mb-5"
                    data-aos="fade-up"
                    data-aos-delay="100"
                  >
                    <a
                      className="card text-center text-decoration-none h-100 lift"
                      href="#!"
                    >
                      <div className="card-body py-5">
                        <div className="icon-stack icon-stack-lg bg-yellow-soft text-red mb-4">
                          <i data-feather="tool"></i>
                        </div>
                        <h5>Seizure</h5>
                        <p className="card-text small">
                          Status epilepticus (SE), Non-convulsive status
                          epilepticus (NCSE), Refractory status epilepticus
                          (RFCE) screening
                        </p>
                      </div>
                    </a>
                  </div>
                  <div
                    className="col-lg-4 col-md-6 mb-5"
                    data-aos="fade-up"
                    data-aos-delay="150"
                  >
                    <a
                      className="card text-center text-decoration-none h-100 lift"
                      href="#!"
                    >
                      <div className="card-body py-5">
                        <div className="icon-stack icon-stack-lg bg-red-soft text-yellow mb-4">
                          <i data-feather="layout"></i>
                        </div>
                        <h5>Burst Suppression</h5>
                        <p className="card-text small">
                          Measure depth of anaesthesia levels during sedation &
                          drug addiction recovery screening
                        </p>
                      </div>
                    </a>
                  </div>
                  <div
                    className="col-lg-4 col-md-6 mb-5 mb-lg-0"
                    data-aos="fade-up"
                  >
                    <a
                      className="card text-center text-decoration-none h-100 lift"
                      href="#!"
                    >
                      <div className="card-body py-5">
                        <div className="icon-stack icon-stack-lg bg-gray-soft text-yellow mb-4">
                          <i data-feather="layout"></i>
                        </div>

                        <h5>Concussion</h5>
                        <p className="card-text small">
                          Traumatic brain injury EEG screening on the sidelines
                          of sport games & athlete concussion recovery tracking
                        </p>
                      </div>
                    </a>
                  </div>
                  <div
                    className="col-lg-4 col-md-6 mb-5 mb-md-0"
                    data-aos="fade-up"
                    data-aos-delay="100"
                  >
                    <a
                      className="card text-center text-decoration-none h-100 lift"
                      href="#!"
                    >
                      <div className="card-body py-5">
                        <div className="icon-stack icon-stack-lg bg-blue-soft text-yellow mb-4">
                          <i data-feather="layout"></i>
                        </div>
                        <h5>Stroke</h5>
                        <p className="card-text small">
                          Out of hospital stroke & recovery screening for
                          accelerated treatments and preparedness
                        </p>
                      </div>
                    </a>
                  </div>
                  <div
                    className="col-lg-4 col-md-6"
                    data-aos="fade-up"
                    data-aos-delay="150"
                  >
                    <a
                      className="card text-center text-decoration-none h-100 lift"
                      href="#!"
                    >
                      <div className="card-body py-5">
                        <div className="icon-stack icon-stack-lg bg-pink-soft text-yellow mb-4">
                          <i data-feather="layout"></i>
                        </div>

                        <h5>Mental Health</h5>
                        <p className="card-text small">
                          Mental health, PTSD, schizophrenia screening to aid in
                          mental health treatments and behavior shaping
                        </p>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </section>
            <section
              className="bg-info.bg-gradient pt-10"
              style={{ fontFamily: "Lato" }}
            >
              <div className="container px-5" id="meetTheTeam">
                <div className="text-center mb-5">
                  <h2>Founding Team</h2>
                  <p className="lead">
                    Neuroscience, business & passionate about mental health
                  </p>
                </div>
                <div className="row gx-5 z-1">
                  <div
                    className="col-lg-4 mb-5 mb-lg-n10 "
                    data-aos="fade-up"
                    data-aos-delay="100"
                  >
                    <div className="card pricing h-100">
                      <div className="card-body p-5">
                        <div className="text-center">
                          <div className="col-md-16 text-center team-members">
                            <div className="image-effect">
                              <div className="social-box">
                                <a
                                  href="https://www.linkedin.com/in/ayushihpatel"
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  <span
                                    className="fa fa-linkedin"
                                    style={{ fontSize: "4em" }}
                                  />
                                </a>
                              </div>
                              <img
                                className="mw-300"
                                src={ayushi_image}
                                alt="andy"
                              />
                            </div>

                            <h3 className="member">Ayushi Patel</h3>
                            <p>Chief Executive Officer</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 mb-5 mb-lg-n10" data-aos="fade-up">
                    <div className="card pricing h-100">
                      <div className="card-body p-5">
                        <div className="text-center">
                          <div className="col-md-16 text-center team-members">
                            <div className="image-effect">
                              <div className="social-box">
                                <a
                                  href="https://www.linkedin.com/in/andybhushan"
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  <span
                                    className="fa fa-linkedin"
                                    style={{ fontSize: "4em" }}
                                  />
                                </a>
                              </div>
                              <img
                                className="mw-300"
                                src={andy_image}
                                alt="andy"
                              />
                            </div>

                            <h3 className="member">Andy Bhushan</h3>
                            <p>Chief Operating Officer</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="col-lg-4 mb-lg-n10"
                    data-aos="fade-up"
                    data-aos-delay="100"
                  >
                    <div className="card pricing h-100">
                      <div className="card-body p-5">
                        <div className="text-center">
                          <div className="col-md-16 text-center team-members">
                            <div className="image-effect">
                              <div className="social-box">
                                <a
                                  href="https://www.linkedin.com/in/trevor-silence"
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  <span
                                    className="fa fa-linkedin"
                                    style={{ fontSize: "4em" }}
                                  />
                                </a>
                              </div>
                              <img
                                className="mw-300"
                                src={trevor_image}
                                alt="andy"
                              />
                            </div>

                            <h3 className="member">Trevor Silence</h3>
                            <p>Chief Technology Officer</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="svg-border-rounded text-dark">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 144.54 17.34"
                  preserveAspectRatio="none"
                  fill="currentColor"
                >
                  <path d="M144.54,17.34H0V0H144.54ZM0,0S32.36,17.34,72.27,17.34,144.54,0,144.54,0"></path>
                </svg>
              </div>
            </section>
            <section className="bg-dark pb-10 pt-15">
              <div className="container px-5">
                <div className="row gx-5 mb-10 mt-5">
                  <div className="col-lg-6 mb-5">
                    <div className="d-flex h-100">
                      <div className="icon-stack flex-shrink-0 bg-teal text-white">
                        1
                      </div>
                      <div className="ms-4">
                        <h5 className="text-white">What is CenSyn?</h5>
                        <p className="text-white-50">
                          CenSyn is a pre-FDA stage medical device company to
                          accelerate screening of various brain health
                          conditions
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 mb-5">
                    <div className="d-flex h-100">
                      <div className="icon-stack flex-shrink-0 bg-teal text-white">
                        2
                      </div>
                      <div className="ms-4">
                        <h5 className="text-white">
                          Why did we create CenSyn?
                        </h5>
                        <p className="text-white-50">
                          Our mission is to make brain health screening and
                          monitoring more accessible to diverese patient
                          populations
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 mb-5 mb-lg-0">
                    <div className="d-flex h-100">
                      <div className="icon-stack flex-shrink-0 bg-teal text-white">
                        3
                      </div>
                      <div className="ms-4">
                        <h5 className="text-white">How secure is CenSyn?</h5>
                        <p className="text-white-50">
                          CenSyn follows the highest standard for data security,
                          cloud compliance and maintaines strict guidelines for
                          HIPAA regulations
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="d-flex h-100">
                      <div className="icon-stack flex-shrink-0 bg-teal text-white">
                        4
                      </div>
                      <div className="ms-4">
                        <h5 className="text-white">How can I get involved?</h5>
                        <p className="text-white-50">
                          We are always looking for clinical pilots for our
                          platform. Send an email inquiry to info@censyn.com for
                          all questions on research participation!
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section
              className="bg-img-cover overlay overlay-light overlay-80 pt-15"
              style={{
                backgroundImage:
                  "https://source.unsplash.com/e3OUQGT9bWU/1400x900",
              }}
            >
              <div style={{ height: "35" }}></div>
              <div className="svg-border-rounded text-white">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 144.54 17.34"
                  preserveAspectRatio="none"
                  fill="currentColor"
                >
                  <path d="M144.54,17.34H0V0H144.54ZM0,0S32.36,17.34,72.27,17.34,144.54,0,144.54,0"></path>
                </svg>
              </div>
            </section>
            <section className="bg-white pb-10" style={{ fontFamily: "Lato" }}>
              <div className="container px-5">
                <div className="row gx-5 mb-5">

                  <div className="row-6 mt-0 mt-lg-n10 z-1">
                    <a
                      className="card text-decoration-none h-100 lift"
                      href="#!"
                    >
                      <div className="card-body py-5">
                        <div className="d-flex align-items-center">
                          <div className="icon-stack icon-stack-xl bg-teal text-white flex-shrink-0">
                            <i data-feather="code"></i>
                          </div>
                          <div className="ms-4">
                            <h4 className="text">Press Releases</h4>
                            <p className="card-text text-gray-600">
                              
                                <div className="card-body p-0">
                                  <time
                                    className="timestamp"
                                    dateTime="2020-02-07"
                                  >
                                    December 15, 2022
                                  </time>
                                  <h4 className="card-title"> 
                                  CenSyn and HonorHealth announce strategic partnership
                                  </h4>
                                  <p className="card-text">
                                  CenSyn and HonorHealth are pleased to announce a strategic partnership to collaborate on CenSyn's Pre-FDA medical platform for on-the-go screening of seizure, concussion and long-term brain health-related disorders.
                                  </p>
                                  <a
                                    target="_blank" rel="noreferrer"
                                    href="https://www.prnewswire.com/news-releases/censyn-and-honorhealth-announce-strategic-partnership-301704535.html"
                                    className="read-more text-primary"
                                  >
                                    Read More &gt;
                                  </a>
                                </div>
                             
                            </p>
                          </div>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
                
              </div>
              {/* <div className="svg-border-waves text-dark"></div> */}
            </section>
          </main>
        </div>
        <div id="layoutDefault_footer">
          <Footer />
        </div>
      </div>
    </div>
  );
}

export default HomePage;
