import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { useSelector } from "react-redux";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "./FormComponent.css";

export default function FirmwareUpdate() {
    const { id } = useParams();
    const token = useSelector((state) => state.loginData.token);
    const [firmwareData, setFirmwareData] = useState({
        firmware_version: '',
        changelog: '',
        version: ''
    });
    const [isUpdating, setIsUpdating] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        fetchFirmwareData();
    }, [id]);

    const fetchFirmwareData = async () => {
        setIsLoading(true);
        try {
            const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/firmware/${id}`, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                  },
            });
            if (response.data.status) {
                setFirmwareData({
                    firmware_version: response.data.data.firmware_version,
                    changelog: response.data.data.changelog,
                    version: response.data.data.version
                });
            } else {
                toast.error(response.data.message || "Failed to fetch firmware data");
            }
        } catch (error) {
            toast.error(error.response?.data?.message || "An error occurred while fetching firmware data");
        } finally {
            setIsLoading(false);
        }
    };

    function handleInputChange(e) {
        const { name, value } = e.target;
        setFirmwareData(prevState => ({
            ...prevState,
            [name]: value,
        }));
    }

    function handleClearForm() {
        fetchFirmwareData();
    }

    function handleSubmit(e) {
        e.preventDefault();
        setIsUpdating(true);
        axios.patch(`${process.env.REACT_APP_BASE_URL}/firmware/${id}`, firmwareData, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
        })
        .then((res) => {
            if (res.data.status) {
                toast.success(res.data.message || "Firmware updated successfully!");
            } else {
                toast.error(res.data.message || "Failed to update firmware");
            }
        })
        .catch((error) => {
            toast.error(error.response?.data?.message || "An error occurred while updating!");
        })
        .finally(() => {
            setIsUpdating(false);
        });
    }

    if (isLoading) {
        return <div>Loading...</div>;
    }

    return (
        <>
            <section className="login-main">
                <div className="container h-100">
                    <div className="row justify-content-center p-0 m-0 h-100">
                        <div className="col-xxl-8 col-lg-9 col-md-12 col-sm-12 py-5 h-100">
                            <div className="content">
                                <div className="card card-border-radius wider-card w-100 p-lg-3 p-sm-2">
                                    <div className="card-title">
                                        <h2 className="p-3 pb-1">Update Firmware</h2>
                                    </div>
                                    <div className="card-body">
                                        <form onSubmit={handleSubmit} className="was-validated">
                                            <div className="mb-4">
                                                <label htmlFor="firmware_version" className="form-label login-form-label">
                                                    <strong>Firmware Version</strong>
                                                </label>
                                                <input
                                                    type="text"
                                                    className="form-control login-form-input"
                                                    id="firmware_version"
                                                    name="firmware_version"
                                                    value={firmwareData.firmware_version}
                                                    onChange={handleInputChange}
                                                    required
                                                />
                                                <div className="invalid-feedback">
                                                    Please fill out this field.
                                                </div>
                                            </div>
                                            <div className="mb-4">
                                                <label htmlFor="changelog" className="form-label login-form-label">
                                                    <strong>Changelog</strong>
                                                </label>
                                                <textarea
                                                    className="form-control login-form-input"
                                                    id="changelog"
                                                    name="changelog"
                                                    value={firmwareData.changelog}
                                                    onChange={handleInputChange}
                                                    required
                                                />
                                                <div className="invalid-feedback">
                                                    Please fill out this field.
                                                </div>
                                            </div>
                                            <div className="mb-4">
                                                <label htmlFor="version" className="form-label login-form-label">
                                                    <strong>Version</strong>
                                                </label>
                                                <input
                                                    type="text"
                                                    className="form-control login-form-input"
                                                    id="version"
                                                    name="version"
                                                    value={firmwareData.version}
                                                    onChange={handleInputChange}
                                                    required
                                                />
                                                <div className="invalid-feedback">
                                                    Please fill out this field.
                                                </div>
                                            </div>
                                            <div className="d-flex justify-content-start">
                                                <button 
                                                    type="submit" 
                                                    className="btn btn-sm text-light main-bg btn-style me-2" 
                                                    disabled={isUpdating}
                                                >
                                                    <b>{isUpdating ? "Updating..." : "Update Firmware"}</b>
                                                </button>
                                                <button 
                                                    type="button" 
                                                    className="btn btn-sm text-light main-bg btn-style" 
                                                    onClick={handleClearForm}
                                                    disabled={isUpdating}
                                                >
                                                    <b>Reset Form</b>
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <ToastContainer />
        </>
    );
}
