// import "../../assets/css/style2.css";
import React, { useEffect, useState } from "react";
import { formatDate, formatDOB } from "../../helper";
import { useDispatch, useSelector } from "react-redux";
import { listPatient } from "../../actions/patientActions";
import ListHeading from "../list-record-components/list-header/listHeading";
import Table from "../react-table-component/ReactTable";
import { ArrowDownward, ArrowForwardIos, MoreVert } from "@mui/icons-material";

const conditionMap = {
  1: "seizure",
  2: "concussion",
  3: "stroke",
  0: "0",
};

export default function Patients() {
  const dispatch = useDispatch();
  const [selectedRows, setSelectedRows] = useState([]);
  const token = useSelector((state) => state.loginData.token);
  const patientList = useSelector((state) => state.patientList);
  const { error, loading, patients } = patientList;
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const simulateProgress = () => {
      let interval = setInterval(() => {
        setProgress(
          (prevProgress) => (prevProgress >= 100 ? 100 : prevProgress + 20) // Simulate increments
        );
      }, 500);
      return () => clearInterval(interval);
    };

    simulateProgress();

    dispatch(listPatient(token))
      .then(() => {
        setProgress(100);
      })
      .catch(() => {
        setProgress(100);
      });
  }, [dispatch, token]);

  const [searchField, setSearchField] = useState("");

  const handleSearch = (e) => {
    const searchField = e.target.value.toLocaleLowerCase();
    setSearchField(searchField);
  };

  // const fetchData = useCallback(({ pageSize, pageIndex, params, sortBy }) => {
  //   const queryParams = {
  //     page: pageIndex + 1,
  //     // sortBy: sortBy[0]?.id || "createdAt",
  //     // sortOrder: sortBy[0]?.desc === false ? "asc" : "desc",
  //     // search: params,
  //   };
  //   dispatch(listPatient(token, queryParams));
  // }, []);

  const [expandedRows, setExpandedRows] = useState([]);

  const toggleRowExpansion = (rowIndex) => {
    setExpandedRows((prevExpandedRows) => {
      if (prevExpandedRows.includes(rowIndex)) {
        // Remove the row index if it's already expanded
        const newExpandedRows = prevExpandedRows.filter(
          (index) => index !== rowIndex
        );

        return newExpandedRows;
      } else {
        // Add the row index if it's not expanded
        const newExpandedRows = [...prevExpandedRows, rowIndex];
        return newExpandedRows;
      }
    });
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Name",
        accessor: "name",
        Cell: ({ row }) => {
          const isExpanded = expandedRows.includes(row.index);
          return (
            <div
              onClick={() => toggleRowExpansion(row.index)}
              style={{ cursor: "pointer" }}
            >
              {isExpanded ? (
                <ArrowDownward style={{ fontSize: 12, marginRight: 4 }} />
              ) : (
                <ArrowForwardIos style={{ fontSize: 12, marginRight: 4 }} />
              )}
              {row.original.name}
            </div>
          );
        },
      },
      {
        Header: "DOB",
        accessor: "dob",
        Cell: ({ row }) => {
          return <div>{formatDOB(row.original.dob)}</div>;
        },
      },
      {
        Header: "MRN",
        accessor: "id",
      },
      {
        Header: "Condition",
        accessor: "condition",
        Cell: ({ row }) => {
          return <div>{conditionMap[row.original.clinical_condition]}</div>;
        },
      },
      {
        Header: "Last Visit",
        accessor: "lastvisit",
        Cell: ({ row }) => {
          return (
            <div>{formatDate(row.original?.recent_visits[0]?.timestamp)}</div>
          );
        },
      },
      {
        Header: "First Visit",
        accessor: "first_visit_date",
        Cell: ({ row }) => {
          return <div>{formatDate(row.original.first_visit_date)}</div>;
        },
      },
      {
        Header: "Review Pending",
        accessor: "review",
        Cell: ({ row }) => {
          return "Yes";
        },
      },

      {
        Header: "",
        accessor: "action",
        disableSortBy: true,
        Cell: ({ row }) => {
          return <MoreVert />;
        },
      },
    ],
    [expandedRows]
  );

  const handleSelectedRowsChange = (selectedIds) => {
    setSelectedRows(selectedIds);
  };

  const filteredPatients = React.useMemo(() => {
    return (
      (patients &&
        patients?.filter((patient) => {
          const patientName = patient.name.toLowerCase();
          const dob = formatDOB(patient.dob);
          const mrn = patient.id.toString();
          const condition = conditionMap[patient.clinical_condition];

          const searchValue = searchField.toLowerCase();

          return (
            patientName.includes(searchValue) ||
            dob.includes(searchValue) ||
            mrn.includes(searchValue) ||
            condition.includes(searchValue)
          );
        })) ||
      []
    );
  }, [patients, searchField]);

  const [pillBtn, setPillBtn] = useState(null);

  return (
    <>
      <section className="patient-list">
        <ListHeading title="Patient Overview" handleSearch={handleSearch} />
        {selectedRows?.length > 0 ? (
          <div>
            <button className="button btn-default rounded-pill">
              START REVIEW
            </button>
            <button className="button btn-default rounded-pill">
              GROUP PATIENTS
            </button>
          </div>
        ) : (
          <div>
            <button
              className={`button ${
                pillBtn === "EPILEPTICUS" ? "btn-filled" : "btn-outline"
              }  rounded-pill`}
              onClick={() => setPillBtn("EPILEPTICUS")}
            >
              EPILEPTICUS
            </button>
            <button
              className={`button ${
                pillBtn === "CONCUSSION" ? "btn-filled" : "btn-outline"
              }  rounded-pill`}
              onClick={() => setPillBtn("CONCUSSION")}
            >
              CONCUSSION
            </button>
          </div>
        )}

        <div className="ms-2">
          {selectedRows?.length > 0 ? (
            <p>{selectedRows?.length} rows selected</p>
          ) : (
            ""
          )}
        </div>

        <Table
          columns={columns}
          data={filteredPatients ?? []}
          // params={searchField}
          // fetchData={fetchData}
          // pageCount={patients?.data?.pagination?.total_pages ?? 1}
          loading={loading}
          tableOf="Patients"
          progress={progress}
          onSelectedRowsChange={handleSelectedRowsChange}
        />
      </section>
    </>
  );
}
