import dayjs from "dayjs";

export const formatDate = (date) => {
  return dayjs(date).format("MMM-DD-YYYY");
};

export const formatTime = (date) => {
  return dayjs(date).format("HH:mm:ss");
};

export const formatDOB = (date) => {
  return dayjs(date).format("DD-MM-YYYY");
};

export const formatName = (name) => {
  if (!name) return "";
  return name
    .toLowerCase()
    .split(" ")
    .map((part) => part.charAt(0).toUpperCase() + part.slice(1))
    .join(" ");
};
