import * as React from "react";
import LinearProgress from "@mui/material/LinearProgress";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

function LinearProgressWithLabel({ value, sx }) {
  return (
    <Box sx={{ width: "100%", textAlign: "center" }}>
      {/* Centered Label Below */}
      <Typography
        variant="body2"
        sx={{ color: "text.secondary", fontWeight: "bold" }}
      >{`${Math.round(value)}%`}</Typography>
      {/* Progress Bar */}
      <LinearProgress
        variant="determinate"
        value={value}
        sx={{ ...sx, mt: 1 }} // Adjust the height and add margin bottom for spacing
      />
    </Box>
  );
}

export default function LinearWithValueLabel({
  initialProgress = 5,
  increment = 5,
  interval = 800,
}) {
  const [progress, setProgress] = React.useState(initialProgress);

  React.useEffect(() => {
    const timer = setInterval(() => {
      setProgress((prevProgress) =>
        prevProgress >= 100 ? initialProgress : prevProgress + increment
      );
    }, interval);
    return () => {
      clearInterval(timer);
    };
  }, [initialProgress, increment, interval]);

  return (
    <Box sx={{ width: "100%" }}>
      <LinearProgressWithLabel
        value={progress}
        sx={{
          height: 10,
        }}
      />
    </Box>
  );
}
