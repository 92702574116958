import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import ClipLoader from 'react-spinners/ClipLoader';
import AdminPanelHeader from './AdminPanelHeader';

export default function Epochs(props) {
	const [mongoData, setMongoData] = useState([]);
	const token = useSelector((state) => state.loginData.token);
	const [isEmpty, setIsEmpty] = useState(false);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		const getData = async () => {
			const response = await axios.get(
				`${process.env.REACT_APP_BASE_URL}/save_recordings`,
				{
					headers: {
						'Content-Type': 'application/json',
						Authorization: token,
					},
				}
			);
			console.log('response is', response);
			if (response.data.code === 401) {
				setLoading(false);
				localStorage.clear();
				window.location.reload();
			} else {
				setLoading(false);
				setMongoData(response.data.data || []);
				// setPatientVisits(response.data.data.visits);
				// setDoctorName(response.data.data.doctor_name);
				if (response.data.data.length > 0) {
					setIsEmpty(false);
				} else {
					setIsEmpty(true);
				}
			}
		};
		getData();
	}, []);

	return (
		<div>
			<AdminPanelHeader />
			<div className='overview-title' style={{ textAlign: 'center' }}>
				<h3>Epochs</h3>
			</div>

			{loading ? (
				<div
					style={{
						textAlign: 'center',
						paddingTop: '2%',
					}}
				>
					{' '}
					<ClipLoader /> <h3 style={{ fontFamily: 'lato' }}>Loading...</h3>
				</div>
			) : (
				<div>
					{isEmpty ? (
						<div
							className='overview-title'
							style={{ textAlign: 'center', paddingTop: '10px' }}
						>
							<h3 style={{ color: 'red' }}>No epochs available </h3>
						</div>
					) : (
						<div>
							<table class='table'>
								<thead>
									<tr
										style={{
											color: '#166e78',
											fontSize: '22px',
											textAlign: 'center',
										}}
									>
										<th scope='col'>#</th>

										<th scope='col'>Condition</th>
										<th scope='col'>Date</th>
										<th scope='col'>Time</th>
										<th scope='col'>Recordings</th>
									</tr>
								</thead>
								<tbody>
									{mongoData.map((item, index) => (
										<tr style={{ textAlign: 'center' }}>
											<td>{index + 1}</td>
											<td>{item.condition}</td>
											<td>{item.date}</td>
											<td>{item.time}</td>
											<td>
												{' '}
												<Link
													to={`/epochs-display/${item.key}`}
													className='btn btn-info btn-block '
													//   onClick={() => {
													//     {
													//       localStorage.setItem("patientVisitId", item.id);
													//     }
													//   }}
												>
													View
												</Link>
											</td>
										</tr>
									))}
								</tbody>
							</table>
						</div>
					)}
				</div>
			)}
		</div>
	);
}
