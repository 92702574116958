import React from "react";
import searchIcon from "../../../assets/img/svg/search-icon.svg";
import filterIcon from "../../../assets/img/svg/filter-icon.svg";

import "./listHeader.css";

const ListHeading = ({ title, handleSearch }) => {
  return (
    <div className="list-header">
      <div className="left-header">
        <h2>{title}</h2>
        <div className="search-bar">
          <img src={searchIcon} alt="search icon" className="search-icon" />
          <input
            type="text"
            placeholder="Search"
            onChange={(e) => handleSearch(e)}
          />
          <div className="filter-container">
            <img src={filterIcon} alt="filter icon" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ListHeading;
