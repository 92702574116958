
import React from 'react'
import { useFormik } from "formik";
import * as Yup from 'yup';

import './PersonalSettings.css'

const passwordRules = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{5,}$/;
// min 5 characters, 1 upper case letter, 1 lower case letter, 1 numeric digit.

const onSubmit =(values,actions)=>{
  console.log("values",values )
  console.log("actions",actions)

  actions.resetForm()
}


function SecuritySettingsForm() {
  const formik = useFormik({

    initialValues:{
      currentPassword:'',
      newPassword:'',
      confirmPassword:'',
    },
    validationSchema:Yup.object({
    currentPassword:Yup.string().required("Required"),
    newPassword: Yup.string().min(5).matches(passwordRules, { message: "Please create a stronger password" }).required("Required"),
    confirmPassword:Yup.string().oneOf([Yup.ref("newPassword"), null], "Passwords must match").required("Required"),
    }),
    onSubmit
  })
  
  return (
   <form onSubmit={formik.handleSubmit}>
   <div class="tab-pane container active pt-4" id="home">
        <div class="row g-3 d-flex justify-content-between">
          <div class="col-auto">
            <label for="currentPassword" class="col-form-label">Current Password:</label>
          </div>
          <div class="col-auto ">
            <input 
            value={formik.values.currentPassword} 
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            placeholder='Enter your current password'
            type="password" id="currentPassword" class={`form-control control-css ${(formik.errors.currentPassword && formik.touched.currentPassword) && "input-error"}`} aria-describedby="passwordHelpInline"/>
             {formik.errors.currentPassword && formik.touched.currentPassword && <p className="error">{formik.errors.currentPassword}</p>}
          </div>
        </div>
        <div class="row g-3 mt-3 d-flex justify-content-between">
          <div class="col-auto">
            <label for="newPassword" class="col-form-label">New Password:</label>
          </div>
          <div class="col-auto width-container">
            <input 
              value={formik.values.newPassword} 
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              placeholder='Enter new password'
            type="password" id="newPassword" class={`form-control control-css ${(formik.errors.newPassword && formik.touched.newPassword) && "input-error"}`} aria-describedby="passwordHelpInline"/>
             {formik.errors.newPassword && formik.touched.newPassword && <p className="error">{formik.errors.newPassword}</p>}
          </div>
        </div>
        <div class="row g-3 mt-3 d-flex justify-content-between">
          <div class="col-auto">
            <label for="confirmPassword" class="col-form-label">Confirm Password:</label>
          </div>
          <div class="col-auto ">
            <input
              value={formik.values.confirmPassword} 
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              placeholder='Enter the new password'
              type="password" id="confirmPassword" class={`form-control control-css ${(formik.errors.confirmPassword && formik.touched.confirmPassword) && "input-error"}`} aria-describedby="passwordHelpInline"/>
               {formik.errors.confirmPassword && formik.touched.confirmPassword && <p className="error">{formik.errors.confirmPassword}</p>}
          </div>
        </div>
          <div class="d-flex justify-content-end mt-4  btn-po">
            <button class="btn btn-outline-primary mar-r  control-css" onClick={formik.handleReset}>Clear Changes</button>
            <button class="btn btn-primary control-css" type='submit'>Save changes</button>
          </div>
    </div>
   </form>
  )
}

export default SecuritySettingsForm