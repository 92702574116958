import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useSelector } from "react-redux";
import {
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, Paper, Checkbox, Modal, Box
} from '@mui/material';
import { Link, useHistory } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "./firmware-list.css";
import trashIcon from "../../assets/img/svg/trashcan.svg";
import edit from "../../assets/img/svg/edit-icon-white.svg";
import download from "../../assets/img/svg/download.svg";


const formatDate = (dateString) => {
  try {
    const dateOptions = { 
      year: 'numeric', 
      month: 'short', 
      day: 'numeric' 
    };
    const date = new Date(dateString);
    if (isNaN(date.getTime())) {
      throw new Error('Invalid date');
    }
    return new Intl.DateTimeFormat('en-US', dateOptions).format(date);
  } catch (error) {
    console.error('Error formatting date:', error);
    return 'Invalid date';
  }
}

const FirmwareList = () => {
    const [firmware, setFirmware] = useState([]);
    const [openModal, setOpenModal] = useState(false);
    const [firmwareToDelete, setFirmwareToDelete] = useState(null);
    const token = useSelector(state => state.loginData.token);
    const history = useHistory();
  
    useEffect(() => {
      const fetchFirmware = async () => {
        try {
          const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/firmware/list-all`, {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          });
          setFirmware(response.data.data || []);
        } catch (error) {
          console.error('Error fetching firmware data:', error);
          toast.error("No firmware data found");
        }
      };

      fetchFirmware();
    }, [token]);
  

    const handleDeleteFirmware = async (firmwareId) => {
      try {
        await axios.delete(`${process.env.REACT_APP_BASE_URL}/firmware/${firmwareId}`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });
        setFirmware(prevFirmware => prevFirmware.filter(fw => fw.id !== firmwareId));
        toast.success("Firmware deleted successfully");
      } catch (error) {
        console.error('Error deleting firmware:', error);
        toast.error("You are unauthorized to delete this firmware");
      }
    };

    const handleOpenModal = (firmwareId) => {
      setFirmwareToDelete(firmwareId);
      setOpenModal(true);
    };
  
    const handleCloseModal = () => {
      setFirmwareToDelete(null);
      setOpenModal(false);
    };
  
    const handleConfirmDelete = () => {
      handleDeleteFirmware(firmwareToDelete);
      handleCloseModal();
    };

    const handleDownload = (fw) => {
      window.open(fw.path_to_folder, '_blank');
    };
  
    return (
      <div key={firmware.length} style={{ backgroundColor: "white", padding: "30px" }}>
        <h3>Firmware List</h3>
        <Link to={`/firmware-create`}>
            <button className="button btn-default" style={{ padding: '0.35rem 0.7rem', fontSize: '0.9rem' }}>
                Add Firmware
            </button>
        </Link>
        <TableContainer component={Paper}>
          <Table aria-label="firmware table">
            <TableHead>
              <TableRow>
                {/* <TableCell /> */}
                <TableCell><Typography variant="h6">Firmware version</Typography></TableCell>
                <TableCell><Typography variant="h6">Date Uploaded</Typography></TableCell>
                <TableCell><Typography variant="h6">Changelog</Typography></TableCell>
                <TableCell><Typography variant="h6">File size (Byte)</Typography></TableCell>
                <TableCell><Typography variant="h6">IOS Version</Typography></TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {firmware.map((fw) => (
                <TableRow key={fw.id}>
                  <TableCell>{fw.firmware_version}</TableCell>
                  <TableCell>{formatDate(fw.date_uploaded)}</TableCell>
                  <TableCell>
                    {fw.changelog.length > 60 
                      ? `${fw.changelog.slice(0, 60)}...` 
                      : fw.changelog}
                  </TableCell>
                  <TableCell>{fw.file_size}</TableCell>
                  <TableCell>{fw.version}</TableCell>
                  <TableCell>
                  <button 
                    onClick={() => handleDownload(fw)}
                    className="button btn-default" 
                    style={{ padding: '0.35rem 0.7rem', fontSize: '0.9rem' }}
                  >
                    <img 
                      src={download} 
                      alt="download" 
                      title="Download firmware" 
                      style={{ width: '20px', height: '20px' }} 
                    />
                  </button>
                    <Link to={`/firmware-update/${fw.id}`}>
                      <button className="button btn-default" style={{ padding: '0.35rem 0.7rem', fontSize: '0.9rem' }}>
                        <img 
                          src={edit} 
                          alt="edit" 
                          title="Edit firmware" 
                          style={{ width: '20px', height: '20px' }} 
                        />
                      </button>
                    </Link>
                    <button 
                      className="button btn-danger" 
                      style={{ padding: '0.35rem 0.7rem', fontSize: '0.9rem' }}
                      onClick={() => handleOpenModal(fw.id)}
                    >
                      <img 
                        src={trashIcon} 
                        alt="Delete" 
                        style={{ width: '25px', height: '25px' }} 
                      />
                    </button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <ToastContainer />

        {/* Confirmation modal */}
        <Modal
          open={openModal}
          onClose={handleCloseModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: 400,
              bgcolor: 'background.paper',
              border: '2px solid #000',
              boxShadow: 24,
              p: 4,
            }}
          >
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Confirm Firmware Deletion
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              Are you sure you want to delete this firmware?
            </Typography>
            <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}>
              <button
                className="button btn-default"
                style={{ marginRight: '0.5rem' }}
                onClick={handleCloseModal}
              >
                Cancel
              </button>
              <button
                className="button btn-danger"
                onClick={handleConfirmDelete}
              >
                Delete
              </button>
            </Box>
          </Box>
        </Modal>

      </div>
    );
  };
  
  export default FirmwareList;
