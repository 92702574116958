
import React from 'react'
import { useFormik } from "formik";
import * as Yup from 'yup';


const onSubmit =(values,actions)=>{
  console.log("values",values )

  actions.resetForm()
}

function ClinicalSettingsForm() {
  const formik = useFormik({

    initialValues:{
      medicalId:'',
      hospital:'',
    },
    validationSchema:Yup.object({
    medicalId:Yup.string().required("Required"),
    hospital:Yup.string().required("Required")
    }),
    onSubmit
  })
  return (
   <form onSubmit={formik.handleSubmit}>
     <div class="tab-pane container active pt-4" id="home">
        <div class="row g-3 d-flex justify-content-between">
          <div class="col-auto">
            <label htmlFor="medicalId" class="col-form-label">Medical Id:</label>
          </div>
          <div class="col-auto ">
            <input 
            value={formik.values.medicalId} 
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            placeholder='Enter the Medical Id'
             id="medicalId" class={`form-control control-css ${(formik.errors.medicalId && formik.touched.medicalId) && "input-error"}`} aria-describedby="passwordHelpInline"/>
                 {formik.errors.medicalId && formik.touched.medicalId && <p className="error">{formik.errors.medicalId}</p>}
          </div>
        </div>
        <div class="row g-3  mt-2 d-flex justify-content-between">
          <div class="col-auto">
            <label htmlFor="hospital" class="col-form-label">Hospital:</label>
          </div>
          <div class="col-auto ">
            <input 
            value={formik.values.hospital} 
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            placeholder='Enter the Hospital'
             id="hospital" class={`form-control control-css ${(formik.errors.hospital && formik.touched.hospital) && "input-error"}`} aria-describedby="passwordHelpInline"/>
                 {formik.errors.hospital && formik.touched.hospital && <p className="error">{formik.errors.hospital}</p>}
          </div>
        </div>
      
          <div class="d-flex justify-content-end mt-4  btn-po">
            <button class="btn btn-outline-primary mar-r control-css" onClick={formik.handleReset}>Clear Changes</button>
            <button class="btn btn-primary control-css" type='submit'>Save changes</button>
          </div>
    </div>
   </form>
  )
}

export default ClinicalSettingsForm