import axios from "axios";
import { React, useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import ClipLoader from "react-spinners/ClipLoader";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import companyLogo from "../assets/img/Company-Logo-white.png";
import unknown_user from "../assets/img/default.jpg";

const AdminPanelHeader = () => {
  const [smShow, setSmShow] = useState(false);
  const [userPicture, setUserPicture] = useState(null);
  const token = useSelector((state) => state.loginData.token);
  const doctorId = useSelector((state) => state.loginData.userId);
  const [docImageS3, setDocImageS3] = useState(unknown_user);
  const [loading, setLoading] = useState(false);
  const [requestStatus, setRequestStatus] = useState(false);

  useEffect(() => {
    const getData = async () => {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/doc_image`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
      if (response.data.code === 401) {
        localStorage.clear();
        window.location.reload();
      } else {
        if (response.data.message !== "error") {
          if (response.data.doc_image === true) {
            setDocImageS3(response.data.data);
          }
        } else {
        }
      }
    };

    getData();
  }, []);

  const onChangeHandler = (event) => {
    let reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = (e) => {
      setUserPicture(e.target.result);
    };
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    setRequestStatus(true);
    if (userPicture == null) {
      setLoading(false);
      setRequestStatus(false);
      toast.error("Please select a picture first");
      return;
    }
    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/doc_image`,
        {
          doctor_image: userPicture,
          doctor_id: doctorId,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      )
      .then((response) => {
        setRequestStatus(false);
        if (response.data.code === 401) {
          localStorage.clear();
          window.location.reload();
        } else {
          if (response.data.status === false) {
            // setIsDataSaved(false);
            toast.error(response.data.data);
          } else {
            setDocImageS3(response.data.data);
            setUserPicture(null);
            setLoading(false);
            toast.success("Picture Changed successfully");
            // setShow(false);
          }
        }
      });
  };
  return (
    <div style={{ width: "100%" }}>
      <ToastContainer
        position="top-center"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Modal
        size="sm"
        show={smShow}
        onHide={() => setSmShow(false)}
        aria-labelledby="example-modal-sizes-title-sm"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-sm">
            Edit Picture
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div style={{ textAlign: "center" }}>
            <img
              src={docImageS3}
              onClick={() => setSmShow(true)}
              alt="user-img"
              width={"200px"}
              height={"200px"}
            />
          </div>
          <Form onSubmit={(e) => handleSubmit(e)}>
            <Form.Group controlId="formFile" className="mb-3">
              <Form.Label> </Form.Label>
              <Form.Control
                type="file"
                accept="image/*"
                onChange={onChangeHandler}
              />
            </Form.Group>

            {loading ? (
              <div
                style={{
                  textAlign: "center",
                }}
              >
                {" "}
                <ClipLoader />{" "}
              </div>
            ) : null}

            <div style={{ textAlign: "center" }}>
              <Button variant="primary" type="submit" disabled={requestStatus}>
                Upload
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
      <header>
        <div className="dashboard-header">
          <span title="Dashboard">
            <div className="dashbord-logo">
              <Link className="na.vbar-brand" to="/dashboard">
                <img src={companyLogo} alt="Logo" />
              </Link>
            </div>
          </span>
          <div className="header-search">
            <form className="form-inline">
              <input
                className="form-control mr-sm-2"
                type="search"
                placeholder="Search"
                aria-label="Search"
              />
            </form>
            <div className="mobile-search">
              <a href="/#">
                <i className="fas fa-search" />
              </a>
            </div>
          </div>
          {/* <div className="mobile-search">
            <a href="/#"/>
          </div> */}
          <span title="View or Edit Picture">
            <div className="im" style={{ maxWidth: "24rem" }}>
              <div className="user-img">
                <a>
                  <img
                    className="image--cover"
                    src={docImageS3}
                    onClick={() => setSmShow(true)}
                    alt="user-img"
                  />
                  {/* <Button onClick={() => setSmShow(true)} className="me-2">Small modal</Button> */}
                </a>
              </div>
            </div>
          </span>
        </div>
      </header>
    </div>
  );
};

export default AdminPanelHeader;
