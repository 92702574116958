import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import axios from "axios";

import { useParams } from "react-router-dom";

import ListHeader from "../list-record-components/list-header/ListHeader";
import ListView from "../list-record-components/list-view/ListView";
import GridView from "../list-record-components/grid-view/GridView";

import dummyRecordingData from "../../utils/dummyRecordingData.json";
import Modal from 'react-bootstrap/Modal';
import { toast, ToastContainer } from "react-toastify";
import { Button } from "react-bootstrap";

import "./deleted-visit-list.css";

const KEYS = ["Name", "Date/Time", "Location", "Condition"];

const DeletedVisitList = () => {
  const [activeView, setActiveView] = useState("list");
  const [numberOfCols, setNumberOfCols] = useState(KEYS.length + 1);
  const [keys, setKeys] = useState(KEYS);
  const [gridKeys, setGridKeys] = useState([]);

  const [recordingsData, setRecordingsData] = useState(dummyRecordingData.data);
  const [searchField, setSearchField] = useState("");
  const [assending, setAssending] = useState(true);

  const [patientVisits, setPatientVisits] = useState([]);
  const [patientName, setPatientName] = useState();
  const token = useSelector((state) => state.loginData.token);
  const [isEmpty, setIsEmpty] = useState(false);
  const [visitDeleteId, setVisitDeleteId] = useState(null);
  const [show, setShow] = useState(false);
  const [visitsData, setVisitsData] = useState([]);

  const handleClose = () => setShow(false);


  const { id } = useParams();

  useEffect(() => {
    const getData = async () => {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/patient/deleted-patient-visits`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
      if (response.data.code === 404) {
        // localStorage.clear();
        // window.location.reload();
      } else {
        setPatientVisits(transformData(response.data.data));
        setPatientName(response.data.data.patient_name);
        if (response.data.data.length > 0) {
          setIsEmpty(false);
        } else {
          setIsEmpty(true);
        }
      }
    };
    getData();
  }, [id, token, patientName]);

  const handleActiveView = (view) => setActiveView(view);
  const handleSarch = (e) => {
    const searchField = e.target.value.toLocaleLowerCase();
    setSearchField(searchField);
  };

  useEffect(() => {
    const { data } = dummyRecordingData;
    setRecordingsData(data);
  }, []);

  const handleDelete = (index) => {
    let recordingToDelete = recordingsData[index];
    let newRecordingList = recordingsData.filter(
      (recording) => recording["patient#"] !== recordingToDelete["patient#"]
    );
    setRecordingsData(newRecordingList);
  };

  const deletePatientVisitPermanently = (index) => {
    console.log("VISIT DELETE ID: " + index);
    setVisitDeleteId(index)
    setShow(true);
  };

  const restorePatientVisit = async (id) => {
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_BASE_URL}/patient/restore-deleted-patient-visit/${id}`,
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
      if (response.data.code === 200) {
        let newVisitList = patientVisits.filter(
          (visitdetails) => visitdetails.id != id
        );
        setShow(false);
        setPatientVisits(newVisitList);
        toast.success("Patient visit Restored...")
      }
    } catch (error) {
      console.error("Error restoring patient visit:", error);
      toast.error('Failed to restore visit');
    }
  };



  const handleConfirmDelete = async ()=> {
    const response = await axios.delete(
      `${process.env.REACT_APP_BASE_URL}/patient/hard-delete-patient-visit/${visitDeleteId}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      }
    );
    if (response.data.code === 404) {
      localStorage.clear();
      window.location.reload();
    } else {
      if (response.data.code === 200) {
        let newVisitList = patientVisits.filter(
          (visitdetails) => visitdetails.id != visitDeleteId
        );
        setShow(false);
        setPatientVisits(newVisitList);
        toast.error("Patient visit deleted...")
      }
    }
  }
  

  const handleSort = () => {
    setAssending((prevState) => !prevState);
    let newRecordings = [...recordingsData];
    console.log("BEFORE SORTING: ", newRecordings);
    newRecordings = newRecordings.sort(function (a, b) {
      const recordingA = a["recording#"];
      const recordingB = b["recording#"];

      if (assending) {
        return recordingB - recordingA;
      } else {
        return recordingA - recordingB;
      }
    });
    console.log("AFTER SORTING: ", newRecordings);
    setRecordingsData(newRecordings);
  };

  // to handle search filter by name
  useEffect(() => {
    if (searchField.length) {
      const filteredRecording = recordingsData.filter((recording) => {
        return recording.name.toLocaleLowerCase().includes(searchField);
      });
      setRecordingsData(filteredRecording);
    } else {
      setRecordingsData(dummyRecordingData.data);
    }
  }, [searchField]);

  function transformData(inputData) {
    if (!inputData || inputData.length === 0) {
      return [];
    }
    const fieldsToExtract = [
      "id",
      "name",
      "timestamp",
      "location",
      "clinical_condition",
    ];
    const transformedData = [];

    inputData.forEach((item) => {
      const extractedFields = {};

      fieldsToExtract.forEach((field) => {
        if (field === "clinical_condition") {
          const conditionMap = {
            1: "stroke",
            2: "concussion",
            3: "seizure",
            0: "0",
          };
          extractedFields[field] = conditionMap[item[field]] || "null";
        } else if (field === "name") {
          extractedFields[field] = item[field] || "null";
        } else if (field === "timestamp") {
          extractedFields[field] = convertDateFormat(item[field]);
        } else if (field === "recording_link") {
          extractedFields[field] = `/recording/${item.id}`;
        } else {
          extractedFields[field] =
            item[field] !== undefined ? item[field] : "null";
        }
      });
      transformedData.push(extractedFields);
    });
    setGridKeys(Object.keys(transformedData[0]));
    console.log("GRID KEYS: ", Object.keys(transformedData[0]));
    return transformedData;
  }

  const convertDateFormat = (inputDate) => {
    const date = new Date(inputDate);
    const year = date.getFullYear().toString().slice(-2);
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const ampm = hours >= 12 ? "pm" : "am";
    const formattedHours = (hours % 12 || 12).toString().padStart(2, "0");
    const formattedMinutes = minutes.toString().padStart(2, "0");

    return `${month}/${day}/${year} ${formattedHours}:${formattedMinutes}${ampm}`;
  };

  return (
    
    <section className="subject-list">
      <ListHeader
        title="Deleted Patient Visits"
        activeView={activeView}
        handleActiveView={handleActiveView}
        handleSarch={handleSarch}
      />
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Modal show={show} onHide={handleClose} animation={false}>
        <Modal.Header closeButton>
          <Modal.Title>Visit Delete Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this visit?</Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="danger" onClick={handleConfirmDelete}>
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
      {!isEmpty ? (
        <>
          {activeView === "list" ? (
            <ListView
              len={numberOfCols}
              data={patientVisits}
              keys={keys}
              handleDelete={handleDelete}
              handleSort={handleSort}
              restorePatientVisit={restorePatientVisit}
              deletePatientVisitPermanently={deletePatientVisitPermanently}
              forHistoryPage={false}
              forVisitDeletePage={false}
              forVisitDeleteHistoryPage={true}

            />
          ) : (
            <GridView
              len={numberOfCols}
              data={patientVisits}
              keys={gridKeys}
              handleDelete={handleDelete}
              name={patientName}
            />
          )}
        </>
      ) : (
        <div class="center-container">
          <div class="centered-text">No Data Found...</div>
        </div>
      )}
    </section>
  );
};

export default DeletedVisitList;
