import axios from "axios";
import React from "react";
import { useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { logoutUser } from "../redux/logins";

function DashSideNav() {
  const dispatch = useDispatch();
  const history = useHistory();

  function logout() {
    axios.get(`${process.env.REACT_APP_BASE_URL}/logout`).then((res) => {
      if (res.data.status === true) {
        dispatch(logoutUser());
        localStorage.clear();
        history.push({
          pathname: "/login",
        });
      }
    });
  }

  return (
    <div>
      <div id="layoutSidenav_nav ">
        <nav className="sidenav shadow-right sidenav-light">
          <div className="sidenav-menu">
            <div className="nav accordion" id="accordionSidenav">
              <div className="sidenav-menu-heading d-sm-none">Account</div>

              <div className="sidenav-menu-heading">Core</div>

              <a
                className="nav-link collapsed"
                href="/dashboard"
                // data-bs-toggle="collapse"
                // data-bs-target="#collapseDashboards"
                // aria-expanded="false"
                // aria-controls="collapseDashboards"
              >
                <div className="nav-link-icon">
                  <i data-feather="activity"></i>
                </div>
                Portal
                <div className="sidenav-collapse-arrow">
                  <i className="fas fa-angle-down"></i>
                </div>
              </a>

              <div className="sidenav-menu-heading">Patients</div>

              <Link
                className="nav-link collapsed"
                to={"/patients"}
                // data-bs-toggle="collapse"
                // data-bs-target="#collapsePages"
                // aria-expanded="false"
                // aria-controls="collapsePages"
              >
                <div className="nav-link-icon">
                  <i data-feather="grid"></i>
                </div>
                New Patient
                <div className="sidenav-collapse-arrow">
                  <i className="fas fa-angle-down"></i>
                </div>
              </Link>

              <Link
                className="nav-link collapsed"
                to={"/listpatients"}
                // data-bs-toggle="collapse"
                // data-bs-target="#collapseFlows"
                // aria-expanded="false"
                // aria-controls="collapseFlows"
              >
                <div className="nav-link-icon">
                  <i data-feather="globe"></i>
                </div>
                Manage
                <div className="sidenav-collapse-arrow">
                  <i className="fas fa-angle-down"></i>
                </div>
              </Link>

              <Link to="/all-recordings">
                <span
                  className="nav-link collapsed"
                  href="/#"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseFlows"
                  aria-expanded="false"
                  aria-controls="collapseFlows"
                >
                  <div className="nav-link-icon">
                    <i data-feather="repeat"></i>
                  </div>
                  Recordings
                  <div className="sidenav-collapse-arrow">
                    <i className="fas fa-angle-down"></i>
                  </div>
                </span>
              </Link>

              <div className="sidenav-menu-heading">AI</div>

              <Link to="/epochs">
                <span
                  className="nav-link collapsed"
                  href="/#"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseLayouts"
                  aria-expanded="false"
                  aria-controls="collapseLayouts"
                >
                  <div className="nav-link-icon">
                    <i data-feather="layout"></i>
                  </div>
                  Epochs
                  <div className="sidenav-collapse-arrow">
                    <i className="fas fa-angle-down"></i>
                  </div>
                </span>
              </Link>

              <a
                className="nav-link collapsed"
                href="/#"
                data-bs-toggle="collapse"
                data-bs-target="#collapseComponents"
                aria-expanded="false"
                aria-controls="collapseComponents"
              >
                <div className="nav-link-icon">
                  <i data-feather="package"></i>
                </div>
                Similarity
                <div className="sidenav-collapse-arrow">
                  <i className="fas fa-angle-down"></i>
                </div>
              </a>

              {/* <a className="nav-link collapsed" href="/#" data-bs-toggle="collapse" data-bs-target="#collapseUtilities" aria-expanded="false" aria-controls="collapseUtilities">
                                    <div className="nav-link-icon"><i data-feather="tool"></i></div>
                                    Utilities
                                    <div className="sidenav-collapse-arrow"><i className="fas fa-angle-down"></i></div>
                                </a> */}

              <div className="sidenav-menu-heading">Settings</div>

              <a className="nav-link" href="/account-settings">
                <div className="nav-link-icon">
                  <i data-feather="bar-chart"></i>
                </div>
                Profile
              </a>

              <a className="nav-link" href="tables.html">
                <div className="nav-link-icon">
                  <i data-feather="filter"></i>
                </div>
                Payment
              </a>
            </div>
          </div>

          <div className="sidenav-footer">
            <div className="sidenav-footer-content">
              <div className="sidenav-footer-subtitle">Logged in as:</div>
              <div className="sidenav-footer-title"></div>
            </div>
          </div>

          <button className="logout btn btn-lg btn-danger" onClick={logout}>
            Sign Out
          </button>
        </nav>
      </div>
    </div>
  );
}

export default DashSideNav;
