import axios from "axios";

import {
  EEG_FILE_REQUEST,
  EEG_FILE_SUCCESS,
  EEG_FILE_FAIL,
  EEG_FILE_FILTER_REQUEST,
  EEG_FILE_FILTER_SUCCESS,
  EEG_FILE_FILTER_FAIL,
  PWELCH_PLOT_REQUEST,
  PWELCH_PLOT_SUCCESS,
  PWELCH_PLOT_FAIL,
} from "../constants/graphConstants";

export const getEegGraph = (token, id) => async (dispatch) => {
  try {
    dispatch({ type: EEG_FILE_REQUEST });

    const { data } = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/eeg_file/${id}`,
      {
        headers: {
          Authorization: `${token}`,
        },
      }
    );

    if (data.code === 401) {
      localStorage.clear();
      window.location.reload();
    } else {
      dispatch({
        type: EEG_FILE_SUCCESS,
        payload: data,
      });
    }
  } catch (error) {
    dispatch({
      type: EEG_FILE_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.detail,
    });
  }
};

export const getEegGraphFilter =
  (token, id, lff, hff, notch) => async (dispatch) => {
    try {
      dispatch({ type: EEG_FILE_FILTER_REQUEST });

      const { data } = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/filters_v1`,
        {
          patient_visit_id: id,
          LFF: lff,
          HFF: hff,
          Notch: notch,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );

      if (data.code === 401) {
        localStorage.clear();
        window.location.reload();
      } else {
        dispatch({
          type: EEG_FILE_FILTER_SUCCESS,
          payload: data?.data[0],
        });
      }

      return data?.data[0];
    } catch (error) {
      dispatch({
        type: EEG_FILE_FILTER_FAIL,
        payload:
          error.response && error.response.data.detail
            ? error.response.data.detail
            : error.detail,
      });
    }
  };

export const getPwelchPlot = (token, id) => async (dispatch) => {
  try {
    dispatch({ type: PWELCH_PLOT_REQUEST });

    const { data } = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/pwelch_plot/${id}`,
      {
        headers: {
          Authorization: `${token}`,
        },
      }
    );
    if (data.code === 401) {
      localStorage.clear();
      window.location.reload();
    } else {
      dispatch({
        type: PWELCH_PLOT_SUCCESS,
        payload: data,
      });
    }
    return data;
  } catch (error) {
    dispatch({
      type: PWELCH_PLOT_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.detail,
    });
  }
};
