import {
  EEG_FILE_REQUEST,
  EEG_FILE_SUCCESS,
  EEG_FILE_FAIL,
  PWELCH_PLOT_REQUEST,
  PWELCH_PLOT_SUCCESS,
  PWELCH_PLOT_FAIL,
  EEG_FILE_FILTER_REQUEST,
  EEG_FILE_FILTER_SUCCESS,
  EEG_FILE_FILTER_FAIL,
} from "../constants/graphConstants";

export const eegFileReducer = (state = {}, action) => {
  switch (action.type) {
    case EEG_FILE_REQUEST:
      return { loading: true };

    case EEG_FILE_SUCCESS:
      return { loading: false, success: true, eegGraphData: action.payload };

    case EEG_FILE_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const eegFilterReducer = (state = {}, action) => {
  switch (action.type) {
    case EEG_FILE_FILTER_REQUEST:
      return { loading: true };

    case EEG_FILE_FILTER_SUCCESS:
      return {
        loading: false,
        success: true,
        eegGraphDataFilter: action.payload,
      };

    case EEG_FILE_FILTER_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const pwelchPlotReducer = (state = {}, action) => {
  switch (action.type) {
    case PWELCH_PLOT_REQUEST:
      return { loading: true };

    case PWELCH_PLOT_SUCCESS:
      return { loading: false, success: true, pwelchData: action.payload };

    case PWELCH_PLOT_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};
