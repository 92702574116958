import React from "react";

import searchIcon from "../../../assets/img/svg/search-icon.svg";
import filterIcon from "../../../assets/img/svg/filter-icon.svg";
import gridView from "../../../assets/img/svg/grid-view.svg";
import listView from "../../../assets/img/svg/list-view.svg";

import "./listHeader.css";

const ListHeader = ({ title, handleSearch, activeView, handleActiveView }) => {
  return (
    <div className="list-header">
      <div className="left-header">
        <h2>{title}</h2>
        <div className="search-bar">
          <img src={searchIcon} alt="search icon" className="search-icon" />
          <input
            type="text"
            placeholder="Search"
            onChange={(e) => handleSearch(e)}
          />
        </div>
      </div>
      <div className="right-header">
        <div className="view-container">
          <img
            src={gridView}
            className={activeView === "grid" && "active-view"}
            onClick={() => handleActiveView("grid")}
            alt="grid view"
          />
          <img
            src={listView}
            className={activeView === "list" && "active-view"}
            onClick={() => handleActiveView("list")}
            alt="list view"
          />
        </div>
        <div className="filter-container">
          Filter <img src={filterIcon} alt="filter icon" />
        </div>
      </div>
    </div>
  );
};

export default ListHeader;
