import React, {useState, useEffect} from 'react';

import ListHeader from "../list-record-components/list-header/ListHeader";
import ListView from "../list-record-components/list-view/ListView";
import GridView from "../list-record-components/grid-view/GridView";

import dummySubjectData from "../../utils/dummySubjectData.json";

import './subject-list-updated.css';

const SubjectListUpdated = () => {
    const [activeView, setActiveView] = useState('list');
    const [numberOfCols, setNumberOfCols] = useState(0);
    const [keys, setKeys] = useState([]);

    const [subjectsData, setSubjectsData] = useState(dummySubjectData.data);
    const [searchField, setSearchField] = useState("");
    const [assending, setAssending] = useState(true);

    const handleActiveView = (view) => setActiveView(view);
    const handleSarch = (e) => {
        const searchField = e.target.value.toLocaleLowerCase();
        setSearchField(searchField);
    }

    useEffect(() => {
        const {data} = dummySubjectData;
        setSubjectsData(data);
        setNumberOfCols(Object.keys(subjectsData[0]).length);
        setKeys(Object.keys(subjectsData[0]));
      }, []);
    
      const handleDelete = (index) => {
        let subjectToDelete = subjectsData[index];
        let newSubjectList = subjectsData.filter(patient => patient['patient#'] !== subjectToDelete['patient#']);
        setSubjectsData(newSubjectList);
      }

      const handleSort = () => {
        setAssending(prevState => !prevState);
        let newSubject = [...subjectsData];
        console.log("BEFORE SORTING: ", newSubject);
        newSubject = newSubject.sort(function (a, b) {
          const subjectNumberA = a.age;
          const subjectNumberB = b.age;
    
          if(assending) {
            return subjectNumberB - subjectNumberA;
          } else {
            return subjectNumberA - subjectNumberB;
          }
        });
        console.log("AFTER SORTING: ", newSubject);
        setSubjectsData(newSubject);
      }
    
      // to handle search filter by name
      useEffect(() => {
        if(searchField.length) {
          const filteredSubject = subjectsData.filter((subject) => {
            return subject.name.toLocaleLowerCase().includes(searchField);
          });
          setSubjectsData(filteredSubject);
        } else {
          setSubjectsData(dummySubjectData.data);
        }
      }, [searchField]);

    return (
        <section className="subject-list">
            <ListHeader title="Subject" activeView={activeView} handleActiveView={handleActiveView} handleSarch={handleSarch} />
            {
                activeView === 'list' ?
                <ListView len={numberOfCols} data={subjectsData} keys={keys} handleDelete={handleDelete} handleSort={handleSort} />
                : 
                <GridView len={numberOfCols} data={subjectsData} keys={keys} handleDelete={handleDelete}/>
            }
      </section>
    )
}

export default SubjectListUpdated