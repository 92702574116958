import React from "react";
import { Link } from "react-router-dom";
import companyLogo from "../assets/img/Company-Logo-white.png";

function Footer() {
  return (
    <div>
      <div id="layoutDefault_footer">
        <footer className="footer pt-10 pb-5 mt-auto bg-grey footer-grey">
          <div className="container px-5">
            <div className="row gx-5">
              <div className="col-lg-3">
                <div className="footer-brand">CenSyn</div>
                <div className="mb-3">Instant insights, one mind at a time</div>
                <div className="icon-list-social mb-5">
                  <a className="icon-list-social-link" href="#!">
                    <i className="fab fa-instagram"></i>
                  </a>
                  <a className="icon-list-social-link" href="#!">
                    <i className="fab fa-facebook"></i>
                  </a>
                  <a className="icon-list-social-link" href="#!">
                    <i className="fab fa-github"></i>
                  </a>
                  <a className="icon-list-social-link" href="#!">
                    <i className="fab fa-twitter"></i>
                  </a>
                </div>
              </div>
              <div className="col-lg-9">
                <div className="row gx-5">
                  <div className="col-lg-3 col-md-6 mb-5 mb-lg-0">
                    <div className="text-uppercase-expanded text-xs mb-4">
                      Product
                    </div>
                    <ul className="list-unstyled mb-0">
                      <li className="mb-2">
                        <a href="#!">PenEEG</a>
                      </li>
                      <li className="mb-2">
                        <a href="#!">Platform</a>
                      </li>
                      <li className="mb-2">
                        <a href="#!">Algorithms</a>
                      </li>
                      <li className="mb-2">
                        <a href="#!">Clinical Support</a>
                      </li>
                    </ul>
                  </div>
                  <div className="col-lg-3 col-md-6 mb-5 mb-lg-0">
                    <div className="text-uppercase-expanded text-xs mb-4">
                      Team
                    </div>
                    <ul className="list-unstyled mb-0">
                      <li className="mb-2">
                        <a href="#!">Founders</a>
                      </li>
                      <li className="mb-2">
                        <a href="#!">Advisors</a>
                      </li>
                      <li className="mb-2">
                        <a href="#!">Collaborators</a>
                      </li>
                    </ul>
                  </div>
                  <div className="col-lg-3 col-md-6 mb-5 mb-md-0">
                    <div className="text-uppercase-expanded text-xs mb-4">
                      Traction
                    </div>
                    <ul className="list-unstyled mb-0">
                      <li className="mb-2">
                        <a href="#!">Research</a>
                      </li>
                      <li className="mb-2">
                        <a href="#!">Press Releases</a>
                      </li>
                    </ul>
                  </div>
                  <div className="col-lg-3 col-md-6">
                    <div className="text-uppercase-expanded text-xs mb-4">
                      Legal
                    </div>
                    <ul className="list-unstyled mb-0">
                      <li className="mb-2">
                        <a href="#!">Privacy Policy</a>
                      </li>
                      <li className="mb-2">
                        <a href="#!">Terms and Conditions</a>
                      </li>
                      <li>
                        <a href="#!">Contact us</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <hr className="my-5" />
            <div className="row gx-5 align-items-center">
              <div className="col-md-6 small">Copyright &copy; CenSyn 2022</div>
              <div className="col-md-6 text-md-end small">
                <a href="#!">Privacy Policy</a>
                &middot;
                <a href="#!">Terms &amp; Conditions</a>
              </div>
            </div>
          </div>
        </footer>
      </div>
    </div>
  );
}

export default Footer;
