
import React from 'react'

function BillingForm() {
  return (
   <div>
     {/* <div class="tab-pane container active pt-4" id="home">
                      <div class="row g-3 d-flex justify-content-between">
                        <div class="col-auto">
                          <label for="inputPassword6" class="col-form-label">Medical Id:</label>
                        </div>
                        <div class="col-auto ">
                          <input type="password" id="inputPassword6" class="form-control control-css" aria-describedby="passwordHelpInline"/>
                        </div>
                      </div>
                      <div class="row g-3  mt-2 d-flex justify-content-between">
                        <div class="col-auto">
                          <label for="inputPassword6" class="col-form-label">Hospital:</label>
                        </div>
                        <div class="col-auto ">
                          <input type="password" id="inputPassword6" class="form-control control-css" aria-describedby="passwordHelpInline"/>
                        </div>
                      </div>
                    
                        <div class="d-flex justify-content-end mt-4  btn-po">
                          <button class="btn btn-outline-primary mar-r control-css">Clear Changes</button>
                          <button class="btn btn-primary control-css">Save changes</button>
                        </div>
                  </div> */}
                  billings
   </div>
  )
}

export default BillingForm