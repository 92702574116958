import React, { useState, useEffect } from "react";
import companyLogo from "../../assets/img/logosync.png";
import { Alert } from "react-bootstrap";
import Footer from "../Footer";
import "../assets/css/login.css";
import { Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { useParams } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function EditPatient() {
  const params = useParams();
  const history = useHistory();
  const token = useSelector((state) => state.loginData.token);
  const dispatch = useDispatch();
  const [patientData, setPatientData] = useState({
    name: "",
    dob: "",
    gender: "",
    clinical_condition: "",
    blood_group: "",
    age: 0,
    id_number: "",
  });
  const [editAttempt, setEditAttempt] = useState(true);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/patient/patient-details/${params.id}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      })
      .then((res) => {
        setPatientData(res.data);
      })
      .catch((error) => {
        history.push("/patient-list");
        console.error("Error fetching patient data:", error);
        toast.error("Invalid patient")
      });
  }, []); 


  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setPatientData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    axios
      .put(`${process.env.REACT_APP_BASE_URL}/patient/${params.id}`,patientData, {
        headers: {
        "Content-Type": "application/json",
        Authorization: token,
      }}, 
      )
      .then((res) => {
        if (res.data.status === true) {
          setEditAttempt(true);
          console.log("Patient edited successfully");
          history.push("/patient-list");
          toast.success("Patient edited successfully")
          // Handle success (e.g., redirect to a patient list page)
        } else {
          setEditAttempt(false);
          console.log("Failed to edit patient");
        }
      })
      .catch((error) => {
        console.error("Error editing patient:", error);
        setEditAttempt(false);
      });
  };

  return (
    <div>
      <>
      <ToastContainer
        position="top-right"
        autoClose={4000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
        <section className="login-main">
          <div className="container h-100">
            <div className="row justify-content-center p-0 m-0 h-100">
              <div className="col-xxl-5 col-lg-5 col-md-12 col-sm-12 py-5 h-100">
                <div className="content">
                  <div className="div-image">
                    {/* <img
                      src={companyLogo}
                      className="logo-image-class"
                      alt="company logo"
                    /> */}
                    <img src="https://censyn-firmware-data.s3.amazonaws.com/CenSyn-logo.svg" alt="censyn"/>
                  </div>
                  <div className="card card-border-radius w-100 p-lg-5 p-sm-2">
                    <div className="card-title">
                      <h2 className="p-3 pb-1">Update Patient Information</h2>
                    </div>
                    <div className="card-body">
                      <form onSubmit={handleSubmit} className="was-validated">
                        {/* Name */}
                        <div className="mb-4">
                          <label
                            htmlFor="name"
                            className="form-label login-form-label"
                          >
                            Name
                          </label>
                          <input
                            type="text"
                            className="form-control login-form-input"
                            id="name"
                            name="name"
                            value={patientData.name}
                            onChange={handleInputChange}
                            required
                          />
                          <div className="invalid-feedback">
                            Please fill out this field.
                          </div>
                        </div>
                        {/* DOB */}
                        <div className="d-flex justify-content-between">
                        <div className="mb-4 res-m">
                          <label
                            htmlFor="dob"
                            className="form-label login-form-label"
                          >
                            DOB
                          </label>
                          <input
                            type="date"
                            className="form-control login-form-input"
                            id="dob"
                            name="dob"
                            value={patientData.dob}
                            onChange={handleInputChange}
                            required
                            style={{ width: "100%" }}
                          />
                          <div className="invalid-feedback">
                            Please fill out this field.
                          </div>
                        </div>
                        {/* Gender */}
                        <div className="mb-4">
                          <label
                            htmlFor="gender"
                            className="form-label login-form-label"
                          >
                            Gender
                          </label>
                          <select
                            className="form-control login-form-input"
                            id="gender"
                            name="gender"
                            value={patientData.gender}
                            onChange={handleInputChange}
                            required
                            style={{ height: "50px" }}
                          >
                            <option value="">Select Gender</option>
                            <option value="1">Male</option>
                            <option value="2">Female</option>
                            <option value="3">Others</option>
                          </select>
                          <div className="invalid-feedback">
                            Please fill out this field.
                          </div>
                        </div>
                        </div>
                        {/* Clinical Condition */}
                        <div className="mb-4">
                          <label
                            htmlFor="clinical_condition"
                            className="form-label login-form-label"
                          >
                            Clinical Condition
                          </label>
                          <select
                            className="form-control login-form-input custom-dropdown"
                            id="clinical_condition"
                            name="clinical_condition"
                            value={patientData.clinical_condition}
                            onChange={handleInputChange}
                            required
                            style={{ height: "50px", width: "100%" }}
                          >
                            <option value="">Select Clinical Condition</option>
                            <option value="1">Seizure</option>
                            <option value="2">Concussions</option>
                            <option value="3">Stroke</option>
                          </select>
                          <div className="invalid-feedback">
                            Please fill out this field.
                          </div>
                        </div>
                        {/* Blood Group */}
                        <div className="mb-4">
                          <label
                            htmlFor="blood_group"
                            className="form-label login-form-label"
                          >
                            Blood Group
                          </label>
                          <select
                            className="form-control login-form-input"
                            id="blood_group"
                            name="blood_group"
                            value={patientData.blood_group}
                            onChange={handleInputChange}
                            required
                            style={{ height: "50px", width: "100%" }}
                          >
                            <option value="">Select Blood Group</option>
                            <option value="1">A+</option>
                            <option value="2">O+</option>
                            <option value="3">B+</option>
                            <option value="4">AB+</option>
                            <option value="5">A-</option>
                            <option value="6">O-</option>
                            <option value="7">B-</option>
                            <option value="8">AB-</option>
                          </select>
                          <div className="invalid-feedback">
                            Please fill out this field.
                          </div>
                        </div>
                        {/* ID Number */}
                        <div className="d-flex justify-content-between">
                        <div className="mb-4 res-m">
                          <label
                            htmlFor="id_number"
                            className="form-label login-form-label"
                          >
                            ID Number
                          </label>
                          <input
                            type="text"
                            className="form-control login-form-input"
                            id="id_number"
                            name="id_number"
                            value={patientData.id_number}
                            onChange={handleInputChange}
                            required
                          />
                          <div className="invalid-feedback">
                            Please fill out this field.
                          </div>
                        </div>
                        {/* Age */}
                        <div className="mb-4">
                          <label
                            htmlFor="age"
                            className="form-label login-form-label"
                          >
                            Age
                          </label>
                          <input
                            type="text"
                            className="form-control login-form-input"
                            id="age"
                            name="age"
                            value={patientData.age}
                            onChange={handleInputChange}
                            required
                          />
                          <div className="invalid-feedback">
                            Please fill out this field.
                          </div>
                        </div>
                        </div>
                        <div className="d-grid">
                          <button
                            type="submit"
                            className="btn text-light main-bg btn-style"
                          >
                            <b>Save Changes</b>
                          </button>
                        </div>
                        {!editAttempt && (
                          <div style={{ paddingTop: "15px" }}>
                            <Alert variant="danger">
                              <p>Something went wrong!</p>
                            </Alert>
                          </div>
                        )}
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </>
    </div>
  );
}

export default EditPatient;
