import React, { useState } from "react";
import InputMask from "react-input-mask";
import validator from "validator";
import "../assets/css/style2.css";
import Footer from "../components/Footer"

export default function PaymentCard() {
  const [errorMessage, setErrorMessage] = useState("");

  const [card, setCard] = useState({
    cardnumber: "",
    carddate: "",
    csvcode: "",
  });

  const handleInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    console.log(name, value);

    if (value.length > 4) return;
    
    setCard({ ...card, [name]: value });

    if (validator.isCreditCard(value)) {
      setErrorMessage("");
    } else {
      setErrorMessage("Enter valid CreditCard Number!");
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  return (
    <>
      <div className="login-bg">
        <div className="boxes-in-bg" />
      </div>
      {/* Login Section */}
      <section className="login-page">
        <div className="container">
          <div className="row align-items-center justify-content-center">
          <div className="col-lg-3 "></div>
            <div className=" col-lg-6  col-md-8 col-sm-12">
              <div className="card cardresponsive">
                <div className="card-header">
                  <div className="row">
                    <div className="col-md-12 col-5 pt-2">
                      <h6 className="m-0">
                        <strong>Payment Details</strong>
                      </h6>
                    </div>
                    <div className="col-md-8 col-12 icons">
                      <i
                        className="fa fa-cc-visa fa-2x ms-1"
                        aria-hidden="true"
                      ></i>
                      <i
                        className="fa fa-cc-mastercard fa-2x ms-1"
                        aria-hidden="true"
                      ></i>
                      <i
                        className="fa fa-cc-discover fa-2x ms-1"
                        aria-hidden="true"
                      ></i>
                      <i
                        className="fa fa-cc-amex fa-2x ms-1"
                        aria-hidden="true"
                      ></i>
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  <form action="" onSubmit={handleSubmit}>
                    <div className="form-group">
                      <label for="validationTooltipCardNumber">
                        <strong>CARD NUMBER</strong>
                      </label>
                      <div class="input-group">
                        <input
                          type="text"
                          className="form-control "
                          id="validationTooltipCardNumber"
                          placeholder="Card Number"
                          name="cardnumber"
                          value={card.cardnumber}
                          onChange={handleInput}
                          required
                        />

                        <div className="input-group-prepend">
                          <span
                            className="input-group-text rounded-right"
                            id="validationTooltipCardNumber"
                          >
                            <i class="fa fa-credit-card"></i>
                          </span>
                        </div>
                      </div>
                      <p
                        style={{
                          color: "red",
                          fontSize: "11px",
                        }}
                      >
                        {errorMessage}
                      </p>
                    </div>
                    <div className="row">
                      <div className="col-md-8 col-12">
                        <div class="form-group">
                          <label for="exampleInputExpirationDate">
                            <strong>EXPIRATION DATE</strong>
                          </label>

                          <InputMask
                            mask="99/99"
                            className="form-control"
                            id="exampleInputExpirationDate"
                            placeholder="MM / YY"
                            name="carddate"
                            value={card.carddate}
                            onChange={handleInput}
                            required
                          ></InputMask>
                        </div>
                      </div>
                      <div className="col-md-4 col-12">
                        <div class="form-group">
                          <label for="exampleInputCvcCode">
                            <strong>CVC CODE</strong>
                          </label>
                          <input
                            type="number"
                            className="form-control"
                            id="exampleInputCvcCode"
                            placeholder="CVC"
                            name="csvcode"
                            value={card.csvcode}
                            onChange={handleInput}
                            maxLength="4"
                            required
                          />
                        </div>
                      </div>
                    </div>
                    {/* <div className="form-group">
                      <label for="exampleInputCouponCode">
                        <strong>COUPON CODE</strong>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="exampleInputCouponCode"
                        placeholder="Coupon Code"
                      />
                    </div> */}
                    <div className="text-center">
                      <button className="btn btn-info w-50 p-3 text-center">
                        Start Subscription
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div className="col-lg-3"></div>
          </div>
        </div>
      </section>
  <Footer />
    </>
  );
}
