import axios from "axios";
import zoomPlugin from "chartjs-plugin-zoom";
import React, { useEffect, useRef, useState } from 'react';
import { Button, Modal } from "react-bootstrap";
import { Chart } from "react-chartjs-2";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import backwardIcon from "../../assets/img/svg/backward-icon.svg";
import fastBackwardIcon from "../../assets/img/svg/fast-backward-icon.svg";
import fastForwardIcon from "../../assets/img/svg/fast-forward-icon.svg";
import forwardIcon from "../../assets/img/svg/forward-icon.svg";
import tableViewIcon from "../../assets/img/svg/graph-table-view.svg";
import tileViewIcon from "../../assets/img/svg/graph-tile-view.svg";
import playIcon from "../../assets/img/svg/play-icon.svg";

import './recording2.css';

const CompareVisit = (props) => {
  const history = useHistory();
  const loc = useLocation();

  const [lffValue, setLffValue] = useState("Select");
  const [hffValue, setHffValue] = useState("Select");
  const [notchValue, setNotchValue] = useState("Select");
  const [alert, setAlert] = useState();

  const [charts, setCharts] = useState([]);
  const chartRefs = useRef([]);
  const [dataCount, setDataCount] = useState(0);
  const [eegData, setEegData] = useState([]);
  const [patientData, setPatientData] = useState();
  const [genderData, setGenderData] = useState();
  const [date, setDate] = useState();
  const [time, setTime] = useState();
  const [conditionData, setConditionData] = useState();
  const [filteredEegData, setFilteredEegData] = useState([]);
  const [isFilter, setIsFilter] = useState(false);
  const [loading, setLoading] = useState(true);
  const [groupedData, setGroupedData] = useState([]);
  const [currentLocationIndex, setCurrentLocationIndex] = useState(0);

  const token = useSelector((state) => state.loginData.token);
  Chart.register(zoomPlugin);

  const [requestStatus, setRequestStatus] = useState(false);


  const [graphView, setGraphView] = useState('tile');
  const [relatedIds, setRelatedIds] = useState('notes');

  const toggleGraphView = (view) => setGraphView(view);

  const handleUpdateGraph = (locationIndex, dataCount = 0) => {
    if (!groupedData || !chartRefs.current) {
      console.log('groupedData or chartRefs are not defined');
      return;
    }
    const locationData = groupedData[locationIndex];
    if (!locationData) {
      console.log(`Location data not found for index ${locationIndex}`);
      return;
    }
  
    const locationKey = Object.keys(locationData)[0];
    const actualData1 = locationData[locationKey];
    const newCharts = [];
  
    actualData1.forEach((item, index) => {
      const canvasRef = chartRefs.current[index];
  
      if (!canvasRef) {
        console.log(`Canvas reference not found for index ${index}`);
        return;
      }
  
      const location = (item.patient_data?.location || "N/A").split('-')[0] || "";
      let chart = charts[index];
  
      if (chart) {
        chart.destroy();
      }
  
      const newChart = createGraph(canvasRef, item, `CH1_Voltage`, location, dataCount);
  
      if (typeof canvasRef === 'object' && canvasRef !== null) {
        canvasRef.chart = newChart;
      } else {
        console.log(`Invalid canvas reference for index ${index}`);
      }
  
      newCharts.push(newChart);
    });
  
    setCharts(newCharts);
    console.log("CHARTS CREATED UPDATED");
  };

  

  const handleBackward = () => {
    const newLocationIndex = currentLocationIndex - 1;
    if (newLocationIndex >= 0) {
      setCurrentLocationIndex(newLocationIndex);
      handleUpdateGraph(newLocationIndex, dataCount);
    }
  }

  const handleForward = () => {
    const newLocationIndex = currentLocationIndex + 1;
    if (newLocationIndex < groupedData.length) {
      setCurrentLocationIndex(newLocationIndex);
      handleUpdateGraph(newLocationIndex, dataCount);
    }
  }

  const createGraph = (canvasRef, data, label, location, dataCount) => {
    if (!canvasRef) {
      console.error("Canvas reference is null or undefined");
      return;
    }

    const chart = new Chart(canvasRef, {
      type: "line",
      data: {
        labels: data.length === 0 ? [] : data.length[dataCount],
        datasets: [
          {
            label: label,
            data: data.length === 0 ? [] : isFilter ? filteredEegData[label][dataCount] : data[label][dataCount],
            backgroundColor: "rgba(0, 0, 0, 0)", 
            borderColor: "rgba(0, 0, 0, 1)", 
            borderWidth: 0.4,
            pointRadius: 0,
          },
        ]
      },
      width: 400,
      height: 1000,
      options: {
        scales: {
          x: {
            ticks: {
              type: 'linear',
              stepSize: 1,
              maxTicksLimit: 10, 
              beginAtZero: true,
            },
            title: {
              display: true,
              text: "Time (seconds)",
            },
          },
          y: {
            title: {
              display: true,
              text: [
                "EEG (uV)", 
                location,
              ],
            },
          },
        },
        normalized: true,
        maintainAspectRatio: false,
        animation: false,
        spanGaps: true,
        datasets: {
          line: {
            pointRadius: 0, 
          },
        },
        elements: {
          point: {
            radius: 0, 
          },
        },
        plugins: {
          decimation: {
            enabled: true,
          },
          zoom: {
            pan: {
              enabled: true,
              mode: "x",
              rangeMin: { x: 0 },
              rangeMax: { x: 100 },
            },
            zoom: {
              wheel: {
                enabled: true,
                mode: "xy",
                rangeMin: { x: 0 },
                rangeMax: { x: 100 },
              },
              pinch: {
                enabled: true,
              },
              mode: "x",
            },
          },
        },
      },
    });
    return chart;
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const searchParams = new URLSearchParams(loc.search);
        const idsFromParams = searchParams.getAll('id').map(id => parseInt(id));
        let data = JSON.stringify({
          "ids": idsFromParams
        });
        if (idsFromParams) {
          let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: `${process.env.REACT_APP_BASE_URL}/compare_recording`,
            headers: { 
              'Content-Type': 'application/json', 
              'Authorization': `Bearer ${token}`
            },
            data : data
          };
          const eegResponse = await axios.request(config)
            .then((response) => {
              let data = response.data.data;
              if (data.visit_0.length > 0 || data.visit_1.length > 0) {
                groupDataByLocation(data.visit_0, data.visit_1);
              }
            })
            .catch((error) => {
              console.log(error);
            });
          if (eegResponse.data.code === 401) {
            localStorage.clear();
            window.location.reload();
          }
        } else {
          setAlert("relatedResponse.data.message");
        }
      } catch (error) {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (groupedData.length > 0 && currentLocationIndex < groupedData.length) {
      const locationData = groupedData[currentLocationIndex];
      const locationKey = Object.keys(locationData)[0];
      const actualData1 = locationData[locationKey];
      const actualData = actualData1[0];
  
      const patient_gender = +actualData.patient_data?.gender;
      const genderDisplay = patient_gender === 1 ? "M" : patient_gender === 2 ? "F" : "N/A";
      
      const patient_condition = +actualData.patient_data?.clinical_condition;
      const conditionDisplay = 
        patient_condition === 1 ? "Seizure" :
        patient_condition === 2 ? "Concussions" :
        patient_condition === 3 ? "Stroke" : "N/A";
      
      const timestamp = actualData.patient_data?.timestamp;
      
      if (timestamp) {
        const [date, time] = timestamp.split(' ');
        setDate(date);
        setTime(time);
      }
  
      setGenderData(genderDisplay);
      setConditionData(conditionDisplay);
      setPatientData(actualData.patient_data);
      setEegData((x) => actualData1);
      setLoading(false);
      setTimeout(() => {
        handleUpdateGraph(currentLocationIndex, dataCount);
      }, 0);
    }

  }, [groupedData, currentLocationIndex]);
  
  useEffect(() => {
    if (groupedData.length > 0) {
      setCurrentLocationIndex(0);
    }
  }, [groupedData]);


  const groupDataByLocation = (visit0, visit1) => {
    const combinedVisits = [...visit0, ...visit1];
    const grouped = combinedVisits.reduce((acc, visitItem) => {
      const location = visitItem.patient_data.location;
      if (!acc[location]) {
        acc[location] = [];
      }
      acc[location].push(visitItem);
      return acc;
    }, {});

    const groupedArray = Object.keys(grouped).map(location => ({
      [location]: grouped[location]
    }));

    groupedArray.sort((a, b) => {
      const aLength = Object.values(a)[0].length;
      const bLength = Object.values(b)[0].length;
      return bLength - aLength;
    });

    setGroupedData(groupedArray);
    setLoading(false);
  };

  const applyFilters = () => {

    axios.post(`${process.env.REACT_APP_BASE_URL}/filters_v1`, {
      patient_visit_id: relatedIds,
      LFF: lffValue,
      HFF: hffValue,
      Notch: notchValue,
    }, {
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    })
    .then((response) => {
      const { code, data, status } = response.data;

      if (code === 200) {
        const actualData1 = data;
        const newCharts = [];

        actualData1.forEach((item, index) => {
          const canvasRef = chartRefs.current[index];
          const location = (item.patient_data?.location || "N/A").split('-')[0] || "";
          let chart = charts[index];
          if (chart) {
            chart.destroy();
            const newChart = createGraph(canvasRef, item, `CH1_Voltage`, location, dataCount);
            canvasRef.chart = newChart;
            newCharts.push(newChart);
          }
        });

        setTimeout(() => {
          setCharts(newCharts);
        }, 3000);
      } else if (code === 401) {
        localStorage.clear();
        window.location.reload();
      } else {
        if (status === false) {
          // Handle false status appropriately
        } else {
          setLoading(true);
          setIsFilter(false);
          setFilteredEegData([]);
          setFilteredEegData(data);
          setIsFilter(true);
          setLoading(false);
        }
      }
    })
    .catch((error) => {
      console.error("Error applying filters:", error);
      // Handle error appropriately, e.g., show a notification to the user
    });
  };

  const handleBack = () => {
    window.history.back();
  };

  if (loading) {
    return <div>Loading...</div>; 
  }

  return (
    <div>
      <ToastContainer
        position="top-center"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      {alert ? (
        <Modal.Dialog>
          <Modal.Header>
            <Modal.Title>Data Not Available</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>No data was found for this patient visit</p>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="primary"
              onClick={(e) => {
                history.goBack();
              }}
            >
              Go Back
            </Button>
          </Modal.Footer>
        </Modal.Dialog>
      ) : (
        <div className='recording2'>
          <div className='recording-graph-panel'>
            <div className='info-container'>
              <div className='personal-info'>
              <button 
                  onClick={handleBack} 
                  style={{
                    background: 'transparent',
                    color: 'black',
                    fontSize: '22px',
                    border: 'none',
                    cursor: 'pointer',
                    display: 'flex',
                    alignItems: 'center',
                    marginBottom: '1rem'
                  }}
                >
                  <span style={{width: '20px', marginRight: '1rem'}}>

                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.2 288 416 288c17.7 0 32-14.3 32-32s-14.3-32-32-32l-306.7 0L214.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z"/></svg>
                  </span>
                  Back
                </button>

                <h1>{patientData?.name}</h1>
                <div className='info-wrapper'>
                  <div className='info'>
                    <p>Patient#: </p>
                    <p>{patientData?.id_number || "N/A"}</p>
                  </div>
                  <div className='info'>
                    <p>Age / Gender: </p>
                    <p>{patientData?.age || "N/A"} / {genderData || "N/A"}</p>
                  </div>
                  <div className='info'>
                    <p>Condition: </p>
                    <p>{conditionData || "N/A"}</p>
                  </div>
                  <div className='info'>
                    <p>Date of Visit: </p>
                    <p>{date || "N/A"}</p>
                  </div>
                  <div className='info'>
                    <p>Time of Visit: </p>
                    <p>{time || "N/A"}</p>
                  </div>
                </div>
              </div>
              <div className='btns-container'>
                <button className="button btn-invert">Add Notes</button>
                <button className="button btn-default">Share Report</button>
              </div>
            </div>
            <div className='eeg-graph-panel'>
              <div className='eeg-header'>
                <div className='header-stats'>
                  <div className='header-stat'>
                    <p>Display: <span>10s</span></p>
                  </div>
                  <div className='header-stat'>
                    <p>Scale: <span>10uv/mm</span></p>
                  </div>
                  <div className='header-stat'>
                    <p>LFF: </p>
                    <div className="dropdown" style={{ paddingLeft: "4px" }}>
                      <button
                        className="btn green-btn2 btn-sm dropdown-toggle"
                        type="button"
                        id="dropdownMenu2"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                        defaultValue="1Hz"
                        style={{ fontWeight: "bold", fontSize: "10" }}
                      >
                        {lffValue}
                      </button>
                      <div className="dropdown-menu" aria-labelledby="dropdownMenu2">
                        <button className="dropdown-item" type="button" onClick={() => setLffValue("N/A")}>
                          N/A
                        </button>
                        <button className="dropdown-item" type="button" onClick={() => setLffValue("1Hz")}>
                          1Hz
                        </button>
                        <button className="dropdown-item" type="button" onClick={() => setLffValue("5Hz")}>
                          5Hz
                        </button>
                        <button className="dropdown-item" type="button" onClick={() => setLffValue("15Hz")}>
                          15Hz
                        </button>
                        <button className="dropdown-item" type="button" onClick={() => setLffValue("20Hz")}>
                          20Hz
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className='header-stat'>
                    <p>HFF: </p>
                    <div className="dropdown" style={{ paddingLeft: "4px" }}>
                      <button
                        className="btn green-btn2 btn-sm dropdown-toggle"
                        type="button"
                        id="dropdownMenu2"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                        defaultValue="HFF4"
                        style={{ fontWeight: "bold", fontSize: "10" }}
                      >
                        {hffValue}
                      </button>
                      <div className="dropdown-menu" aria-labelledby="dropdownMenu2">
                        <button className="dropdown-item" type="button" onClick={() => setHffValue("N/A")}>
                          N/A
                        </button>
                        <button className="dropdown-item" type="button" onClick={() => setHffValue("15Hz")}>
                          15 Hz
                        </button>
                        <button className="dropdown-item" type="button" onClick={() => setHffValue("30Hz")}>
                          30 Hz
                        </button>
                        <button className="dropdown-item" type="button" onClick={() => setHffValue("40Hz")}>
                          40 Hz
                        </button>
                        <button className="dropdown-item" type="button" onClick={() => setHffValue("70Hz")}>
                          70 Hz
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className='header-stat'>
                    <p>Notch: </p>
                    <div className="dropdown" style={{ paddingLeft: "4px" }}>
                      <button
                        className="btn btn-sm green-btn2 dropdown-toggle"
                        type="button"
                        id="dropdownMenu2"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                        defaultValue="HFF4"
                        style={{ fontWeight: "bold", fontSize: "10" }}
                      >
                        {notchValue}
                      </button>
                      <div className="dropdown-menu" aria-labelledby="dropdownMenu2">
                        <button className="dropdown-item" type="button" onClick={(e) => { setNotchValue("N/A"); }}>
                          N/A
                        </button>
                        <button className="dropdown-item" type="button" onClick={(e) => { setNotchValue("50Hz"); }}>
                          50 Hz
                        </button>
                        <button className="dropdown-item" type="button" onClick={(e) => { setNotchValue("60Hz"); }}>
                          60 Hz
                        </button>
                      </div>
                    </div>
                    <button
                      className="btn green-btn32 btn-sm"
                      type="button"
                      style={{
                        fontWeight: "bold",
                        fontSize: "6",
                      }}
                      disabled={requestStatus}
                      onClick={() => {
                        applyFilters(lffValue, hffValue, notchValue);
                      }}
                    >
                      Apply Filters
                    </button>
                  </div>
                </div>
                <div className='header-view-container'>
                  <img src={tileViewIcon} alt="tile view" onClick={() => toggleGraphView('tile')} className={graphView === 'tile' ? 'header-active-view' : ''} />
                  <img src={tableViewIcon} alt="table view" onClick={() => toggleGraphView('table')} className={graphView === 'table' ? 'header-active-view' : ''} />
                </div>
              </div>
              <div className='eeg-graph'>
                {eegData.map((data, index) => (
                  <div key={index} className="graph-box">
                    <canvas ref={(el) => (chartRefs.current[index] = el)} height="200" />
                  </div>
                ))}
              </div>
              <div className='eeg-graph-controls'>
                <button
                  disabled={currentLocationIndex === 0}
                  className='control-button'
                  onClick={handleBackward}
                >
                  <img src={fastBackwardIcon} alt="fast backward" />  
                </button>
                <img src={backwardIcon} alt="backward" />
                <img src={playIcon} alt="play" />
                <img src={forwardIcon} alt="forward" />
                <button
                  disabled={currentLocationIndex === groupedData.length - 1}
                  className='control-button'
                  onClick={handleForward}
                >
                  <img src={fastForwardIcon} alt="fast forward" />
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CompareVisit;
