import React, {useState, useEffect} from 'react';

import ListHeader from "../list-record-components/list-header/ListHeader";
import ListView from "../list-record-components/list-view/ListView";
import GridView from "../list-record-components/grid-view/GridView";

import dummyStudiesData from "../../utils/dummyStudiesData.json";

import "./studies-list-updated.css";

const StudiesListUpdated = () => {
  const [activeView, setActiveView] = useState('list');
  const [numberOfCols, setNumberOfCols] = useState(0);
  const [keys, setKeys] = useState([]);

  const [studiesData, setStudiesData] = useState(dummyStudiesData.data);
  const [searchField, setSearchField] = useState("");
  const [assending, setAssending] = useState(true);

  const handleActiveView = (view) => setActiveView(view);
  const handleSarch = (e) => {
      const searchField = e.target.value;
      setSearchField(searchField);
  }

  useEffect(() => {
      const {data} = dummyStudiesData;
      setStudiesData(data);
      setNumberOfCols(Object.keys(studiesData[0]).length);
      setKeys(Object.keys(studiesData[0]));
    }, []);
  
    const handleDelete = (index) => {
      let studyToDelete = studiesData[index];
      let newStudiesList = studiesData.filter(study => study['IRB#'] !== studyToDelete['IRB#']);
      setStudiesData(newStudiesList);
    }

    const handleSort = () => {
      setAssending(prevState => !prevState);
      let newStudies = [...studiesData];
      console.log("BEFORE SORTING: ", newStudies);
      newStudies = newStudies.sort(function (a, b) {
        const studyNumberA = a["IRB#"];
        const studyNumberB = b["IRB#"];
  
        if(assending) {
          return studyNumberB - studyNumberA;
        } else {
          return studyNumberA - studyNumberB;
        }
      });
      console.log("AFTER SORTING: ", newStudies);
      setStudiesData(newStudies);
    }
  
    // to handle search filter by irb_no
    useEffect(() => {
      if(searchField.length) {
        const filteredStudies = studiesData.filter((study) => {
          return study['IRB#'].includes(searchField);
        });
        setStudiesData(filteredStudies);
      } else {
        setStudiesData(dummyStudiesData.data);
      }
    }, [searchField]);
  return (
    <section className="studies-list">
    <ListHeader title="Studies" activeView={activeView} handleActiveView={handleActiveView} handleSarch={handleSarch} />
    {
        activeView === 'list' ?
        <ListView len={numberOfCols} data={studiesData} keys={keys} handleDelete={handleDelete} handleSort={handleSort} />
        : 
        <GridView len={numberOfCols} data={studiesData} keys={keys} handleDelete={handleDelete}/>
    }
   </section>
  )
}

export default StudiesListUpdated