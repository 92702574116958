import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import zoomPlugin from "chartjs-plugin-zoom";
import { Button, Modal } from "react-bootstrap";
import { Chart } from "react-chartjs-2";
import { useSelector, useDispatch } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useParams, useHistory } from "react-router-dom";
import { getPatientVisitDetails } from "../../actions/patientActions";
import { getEegGraph } from "../../actions/graphActions";
import fastBackwardIcon from "../../assets/img/svg/fast-backward-icon.svg";
import backwardIcon from "../../assets/img/svg/backward-icon.svg";
import playIcon from "../../assets/img/svg/play-icon.svg";
import pauseIcon from "../../assets/img/svg/pause-icon.svg";
import fastForwardIcon from "../../assets/img/svg/fast-forward-icon.svg";
import forwardIcon from "../../assets/img/svg/forward-icon.svg";
import BackIcon from "../../assets/img/svg/back-icon.svg";
import { ArrowForwardIos, ArrowBackIos } from "@mui/icons-material";

import "./recording2.css";
import { formatName, formatDate } from "../../helper";
import PwelchAndHeatMap from "./PwelchAndHeatMap";

import LinearWithValueLabel from "../loader/LinearLoaderLablel";

const conditionMap = {
  1: "seizure",
  2: "concussion",
  3: "stroke",
};
const genderMap = {
  1: "M",
  2: "F",
};

Chart.register(zoomPlugin);

const TestRecording = (props) => {
  const params = useParams();
  const history = useHistory();
  const dispatch = useDispatch();

  const [value, setValue] = useState([0, 1000]);

  const [lffValue, setLffValue] = useState("Select");
  const [hffValue, setHffValue] = useState("Select");
  const [notchValue, setNotchValue] = useState("Select");
  const [alert, setAlert] = useState();

  const [charts, setCharts] = useState([]);
  const chartRefs = useRef([]);
  const [dataCount, setDataCount] = useState(0);
  const [dataLength, setDataLength] = useState(null);

  const [eegData, setEegData] = useState(null);
  const [patientData, setPatientData] = useState();
  const [genderData, setGenderData] = useState();
  const [date, setDate] = useState();
  const [time, setTime] = useState();
  const [conditionData, setConditionData] = useState();
  const [filteredEegData, setFilteredEegData] = useState([]);
  const [isFilter, setIsFilter] = useState(false);
  const [loading, setLoading] = useState(true);

  // For Spectogram

  const [isHeatMap, setIsHeatMap] = useState(false);
  // for play and pause funcitonality
  const [isPlaying, setIsPlaying] = useState(false);
  const intervalRef = useRef(null);

  const token = useSelector((state) => state.loginData.token);

  const [requestStatus, setRequestStatus] = useState(false);

  const [isDataSaved, setIsDataSaved] = useState(false);

  const [relatedIds, setRelatedIds] = useState("notes");

  const { eegGraphData } = useSelector((state) => state.eegGraph);
  const { visitDetails } = useSelector((state) => state.patientVisitDetails);
  const [location, setLocation] = useState("");
  const [currentIndex, setCurrentIndex] = useState(0);

  console.log("eeg data : ", eegGraphData);
  console.log("visit details : ", visitDetails);
  console.log("loading  : ", loading);

  function updateGraph(dataCount) {
    const newCharts = [];
    for (let i = 0; i < 2; i++) {
      const canvasRef = chartRefs?.current[i];
      let chart = charts[i];
      const channelName = i === 0 ? "CH1_Voltage" : "CH2_Voltage";

      const location = `${
        (visitDetails?.data?.[currentIndex]?.location || "N/A").split("-")[0] ||
        ""
      }`;

      if (chart) {
        chart.destroy();
        const newChart = newCharts.push(
          createGraph(
            canvasRef,
            eegGraphData?.data,
            channelName,
            location,
            dataCount
          )
        );
        canvasRef.chart = newChart;
        newCharts.push(newChart);
      }
    }
  }

  // function handleUpdateGraph(dataCount) {
  //   const newCharts = [];
  //   eegData?.forEach((item, index) => {
  //     const canvasRef = chartRefs.current[index];
  //     const location =
  //       (item.patient_data?.location || "N/A").split("-")[0] || "";
  //     let chart = charts[index];
  //     if (chart) {
  //       chart.destroy();
  //       const newChart = createGraph(
  //         canvasRef,
  //         item,
  //         `CH1_Voltage`,
  //         location,
  //         dataCount
  //       );
  //       canvasRef.chart = newChart;
  //       newCharts.push(newChart);
  //     }
  //   });
  //   setCharts(newCharts);
  //   console.log("CHARTS CREATED UPDATED");
  // }

  const handleGraphButton = (type) => {
    let newCount;
    switch (type) {
      case "fast-backward":
        newCount = 0;
        stopPlaying();
        break;
      case "backward":
        newCount = Math.max(0, dataCount - 1);
        stopPlaying();
        break;
      case "forward":
        newCount = Math.min(dataLength - 1, dataCount + 1);
        stopPlaying();
        break;
      case "fast-forward":
        newCount = dataLength - 1;
        stopPlaying();

        break;
      case "play":
        if (!isPlaying) {
          if (newCount === dataLength - 1) {
            setDataCount(0);
          }
          startPlaying();
        }
        return;
      case "pause":
        stopPlaying(); // Pause the play interval
        return;
      default:
        return;
    }

    setDataCount(newCount);
    setValue([0, 1000]);
    updateGraph(newCount);
  };

  const startPlaying = () => {
    setIsPlaying(true);

    intervalRef.current = setInterval(() => {
      setDataCount((prevCount) => {
        if (prevCount < dataLength - 1) {
          const newCount = prevCount + 1;
          setValue([0, 1000]);
          if (chartRefs.current) {
            chartRefs.current.forEach((canvasRef) => {
              if (canvasRef && canvasRef.chart) {
                canvasRef.chart.destroy();
              }
            });
          }
          updateGraph(newCount);
          return newCount;
        } else {
          stopPlaying();
          return -1;
        }
      });
    }, 500);
  };

  const stopPlaying = () => {
    setIsPlaying(false);
    clearInterval(intervalRef.current);
  };

  // Ensure the interval is cleared if the component is unmounted
  useEffect(() => {
    return () => clearInterval(intervalRef.current);
  }, []);

  const handleBack = () => {
    window.history.back();
  };

  // Function to create a graph
  const createGraph = (canvasRef, data, label, location, dataCount) => {
    if (!canvasRef) {
      console.error("Canvas reference is null or undefined");
      return;
    }

    const chart = new Chart(canvasRef, {
      type: "line",
      data: {
        labels: data.length === 0 ? [] : data.length[dataCount],
        datasets: [
          {
            label: label,
            data:
              data.length === 0
                ? []
                : isFilter
                ? filteredEegData[label][dataCount]
                : data[label][dataCount],
            backgroundColor: "rgba(0, 0, 0, 0)",
            borderColor: "rgba(0, 0, 0, 1)",
            borderWidth: 0.4,
            pointRadius: 0,
          },
        ],
      },
      width: 400,
      height: 1000,
      options: {
        scales: {
          x: {
            ticks: {
              type: "linear",
              stepSize: 1,
              maxTicksLimit: 10,
              beginAtZero: true,
            },
            title: {
              display: true,
              text: "Time (seconds)",
            },
          },
          y: {
            title: {
              display: true,
              text: ["EEG (uV)", location],
            },
          },
        },
        normalized: true,
        maintainAspectRatio: false,
        animation: false,
        spanGaps: true,
        datasets: {
          line: {
            pointRadius: 0,
          },
        },
        elements: {
          point: {
            radius: 0,
          },
        },
        plugins: {
          decimation: {
            enabled: true,
          },
          zoom: {
            pan: {
              enabled: true,
              mode: "x",
              rangeMin: { x: 0 },
              rangeMax: { x: 100 },
            },
            zoom: {
              wheel: {
                enabled: true,
                mode: "xy",
                rangeMin: { x: 0 },
                rangeMax: { x: 100 },
              },
              pinch: {
                enabled: true,
              },
              mode: "x",
            },
          },
        },
      },
    });
    canvasRef.chart = chart;
    return chart;
  };

  useEffect(() => {
    const fetchVisit = async () => {
      try {
        const visits = await dispatch(getPatientVisitDetails(token, params.id));

        if (visits?.data?.length > 0) {
          const relatedIds = visits?.data?.map((record) => record.id);
          setRelatedIds(relatedIds);
        } else {
          toast.error("Visit details not found");
        }
      } catch (error) {
        setLoading(false);
        setAlert(error.response);
        toast.error("Error fetching data of patient visit", {
          autoClose: 3000,
          position: "top-right",
        });
      }
    };

    fetchVisit();
  }, [dispatch, token, params.id]);

  useEffect(() => {
    const fetchEegData = async () => {
      try {
        if (visitDetails?.data?.length > 0) {
          setLoading(true);
          await dispatch(getEegGraph(token, relatedIds[currentIndex]));
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
        setAlert(error.response);
        toast.error("Error fetching data", {
          autoClose: 3000,
          position: "top-right",
        });
      }
    };

    fetchEegData();
  }, [dispatch, token, currentIndex, visitDetails, relatedIds]);

  useEffect(() => {
    if (!loading && eegGraphData) {
      console.log("After loading set patient");
      setPatientData(eegGraphData?.data?.patient_data);
      const currentData = eegGraphData?.data;
      setDataLength(currentData.CH1_Voltage?.length || 0);
      setEegData(eegGraphData?.data);

      chartRefs.current.forEach((canvasRef) => {
        if (canvasRef?.chart) {
          canvasRef.chart.destroy();
          canvasRef.chart = null;
        }
      });

      const newCharts = [];
      setTimeout(() => {
        for (let i = 0; i < 2; i++) {
          const canvasRef = chartRefs?.current[i];
          const channelName = i === 0 ? "CH1_Voltage" : "CH2_Voltage";
          console.log("canvassss : ", canvasRef);

          const location = `${
            (visitDetails?.data?.[currentIndex]?.location || "N/A").split(
              "-"
            )[0] || ""
          }`;

          console.log(i, location, currentData[channelName]);

          newCharts.push(
            createGraph(
              canvasRef,
              currentData,
              channelName,
              location,
              dataCount
            )
          );

          setCharts(newCharts);
        }
      }, 1000);
    }

    return () => {
      chartRefs.current.forEach((canvasRef) => {
        if (canvasRef?.chart) {
          canvasRef.chart.destroy();
          canvasRef.chart = null;
        }
      });
    };
  }, [eegGraphData, loading]);

  // Apply filters
  const applyFilters = () => {
    setIsHeatMap(false);
    setIsDataSaved(true);

    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/filters_v1`,
        {
          patient_visit_id: relatedIds,
          LFF: lffValue,
          HFF: hffValue,
          Notch: notchValue,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      )
      .then((response) => {
        setIsDataSaved(false);
        const { code, data, status } = response.data;

        if (code === 200) {
          const actualData1 = data;
          const newCharts = [];

          actualData1.forEach((item, index) => {
            const canvasRef = chartRefs.current[index];
            const location =
              (item.patient_data?.location || "N/A").split("-")[0] || "";
            let chart = charts[index];
            if (chart) {
              chart.destroy();
              const newChart = createGraph(
                canvasRef,
                item,
                `CH1_Voltage`,
                location,
                dataCount
              );
              canvasRef.chart = newChart;
              newCharts.push(newChart);
            }
          });

          setTimeout(() => {
            setCharts(newCharts);
          }, 3000);
        } else if (code === 401) {
          localStorage.clear();
          window.location.reload();
        } else {
          if (status === false) {
            // Handle false status appropriately
          } else {
            setLoading(true);
            setIsFilter(false);
            setFilteredEegData([]);
            setFilteredEegData(data);
            setIsFilter(true);
            setLoading(false);
          }
        }
      })
      .catch((error) => {
        console.error("Error applying filters:", error);
        setIsDataSaved(false);
      });
  };

  return (
    <div>
      {alert ? (
        <Modal.Dialog>
          <Modal.Header>
            <Modal.Title>Data Not Available</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>No data was found for this patient visit</p>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="primary"
              onClick={(e) => {
                history.goBack();
              }}
            >
              Go Back
            </Button>
          </Modal.Footer>
        </Modal.Dialog>
      ) : (
        <div className="recording2">
          <div className="recording-graph-panel">
            <div className="info-container">
              <div className="personal-info">
                <div className="btn-back" onClick={handleBack}>
                  <span style={{ width: "20px", marginRight: "1rem" }}>
                    <img src={BackIcon} alt="back-icon" />
                  </span>
                  Back
                </div>

                <h1>{formatName(patientData?.name)}</h1>
                <div className="info-wrapper">
                  <div className="info">
                    <p>Age / Gender: </p>
                    <p>
                      {patientData?.age || "N/A"}{" "}
                      {genderMap[patientData?.gender] || "N/A"}
                    </p>
                  </div>
                  <div className="info">
                    <p>Patient#: </p>
                    <p>{patientData?.id_number || "N/A"}</p>
                  </div>
                  <div className="info">
                    <p>Recording Date: </p>
                    <p>{formatDate(patientData?.timestamp) || "N/A"}</p>
                  </div>
                  <div className="info">
                    <p>Condition: </p>
                    <p>
                      {conditionMap[patientData?.clinical_condition] || "N/A"}
                    </p>
                  </div>
                </div>
              </div>
              <div className="btns-container">
                <button className="button btn-invert-rec">Add Notes</button>
                <button className="button btn-default-rec">Share Report</button>
              </div>
            </div>

            <div className="eeg-graph-panel">
              {loading ? (
                <div className="linear-loader">
                  <LinearWithValueLabel />
                </div>
              ) : (
                <>
                  <div className="eeg-header">
                    <div className="header-stats">
                      <div className="header-stat">
                        <p>
                          Display: <span>10s</span>
                        </p>
                      </div>
                      <div className="header-stat">
                        <p>
                          Scale: <span>10uv/mm</span>
                        </p>
                      </div>
                      <div className="header-stat">
                        <p>LFF: </p>
                        <div
                          className="dropdown"
                          style={{ paddingLeft: "4px" }}
                        >
                          <button
                            className="btn green-btn2 btn-sm dropdown-toggle"
                            type="button"
                            id="dropdownMenu2"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                            defaultValue="1Hz"
                            style={{ fontWeight: "bold", fontSize: "10" }}
                          >
                            {lffValue}
                          </button>
                          <div
                            className="dropdown-menu"
                            aria-labelledby="dropdownMenu2"
                          >
                            <button
                              className="dropdown-item"
                              type="button"
                              onClick={() => setLffValue("N/A")}
                            >
                              N/A
                            </button>
                            <button
                              className="dropdown-item"
                              type="button"
                              onClick={() => setLffValue("1Hz")}
                            >
                              1Hz
                            </button>
                            <button
                              className="dropdown-item"
                              type="button"
                              onClick={() => setLffValue("5Hz")}
                            >
                              5Hz
                            </button>
                            <button
                              className="dropdown-item"
                              type="button"
                              onClick={() => setLffValue("15Hz")}
                            >
                              15Hz
                            </button>
                            <button
                              className="dropdown-item"
                              type="button"
                              onClick={() => setLffValue("20Hz")}
                            >
                              20Hz
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="header-stat">
                        <p>HFF: </p>
                        <div
                          className="dropdown"
                          style={{ paddingLeft: "4px" }}
                        >
                          <button
                            className="btn green-btn2 btn-sm dropdown-toggle"
                            type="button"
                            id="dropdownMenu2"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                            defaultValue="HFF4"
                            style={{ fontWeight: "bold", fontSize: "10" }}
                          >
                            {hffValue}
                          </button>
                          <div
                            className="dropdown-menu"
                            aria-labelledby="dropdownMenu2"
                          >
                            <button
                              className="dropdown-item"
                              type="button"
                              onClick={() => setHffValue("N/A")}
                            >
                              N/A
                            </button>
                            <button
                              className="dropdown-item"
                              type="button"
                              onClick={() => setHffValue("15Hz")}
                            >
                              15 Hz
                            </button>
                            <button
                              className="dropdown-item"
                              type="button"
                              onClick={() => setHffValue("30Hz")}
                            >
                              30 Hz
                            </button>
                            <button
                              className="dropdown-item"
                              type="button"
                              onClick={() => setHffValue("40Hz")}
                            >
                              40 Hz
                            </button>
                            <button
                              className="dropdown-item"
                              type="button"
                              onClick={() => setHffValue("70Hz")}
                            >
                              70 Hz
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="header-stat">
                        <p>Notch: </p>
                        <div
                          className="dropdown"
                          style={{ paddingLeft: "4px" }}
                        >
                          <button
                            className="btn btn-sm green-btn2 dropdown-toggle"
                            type="button"
                            id="dropdownMenu2"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                            defaultValue="HFF4"
                            style={{ fontWeight: "bold", fontSize: "10" }}
                          >
                            {notchValue}
                          </button>
                          <div
                            className="dropdown-menu"
                            aria-labelledby="dropdownMenu2"
                          >
                            <button
                              className="dropdown-item"
                              type="button"
                              onClick={(e) => {
                                setNotchValue("N/A");
                              }}
                            >
                              N/A
                            </button>
                            <button
                              className="dropdown-item"
                              type="button"
                              onClick={(e) => {
                                setNotchValue("50Hz");
                              }}
                            >
                              50 Hz
                            </button>
                            <button
                              className="dropdown-item"
                              type="button"
                              onClick={(e) => {
                                setNotchValue("60Hz");
                              }}
                            >
                              60 Hz
                            </button>
                          </div>
                        </div>
                        <button
                          className="btn green-btn32 btn-sm"
                          type="button"
                          style={{
                            fontWeight: "bold",
                            fontSize: "6",
                          }}
                          disabled={requestStatus}
                          onClick={() => {
                            applyFilters(lffValue, hffValue, notchValue);
                          }}
                        >
                          Apply Filters
                        </button>
                      </div>
                    </div>
                    <div className="location-btns">
                      <button
                        className="btn loc-btn"
                        type="button"
                        onClick={() => {
                          if (currentIndex > 0) {
                            setCurrentIndex((prevIndex) =>
                              prevIndex > 0 ? prevIndex - 1 : 0
                            );
                          }
                        }}
                        disabled={currentIndex === 0}
                      >
                        <ArrowBackIos
                          style={{ fontSize: 12, marginRight: 4 }}
                        />
                        Back
                      </button>
                      <span className="loc-name">
                        {visitDetails?.data?.[currentIndex]?.location ?? "N/A"}
                      </span>
                      <button
                        className="btn loc-btn"
                        type="button"
                        onClick={() => {
                          if (
                            currentIndex < relatedIds.length - 1 ||
                            currentIndex <= 4
                          ) {
                            setCurrentIndex((prevIndex) => prevIndex + 1);
                          }
                        }}
                        disabled={
                          currentIndex === relatedIds.length - 1 ||
                          currentIndex > 4
                        }
                      >
                        Next
                        <ArrowForwardIos
                          style={{ fontSize: 12, marginLeft: 4 }}
                        />
                      </button>
                    </div>
                  </div>
                  <div className="eeg-graph">
                    {[1, 2]?.map((data, index) => (
                      <div key={index} className="graph-box">
                        <canvas
                          ref={(el) => (chartRefs.current[index] = el)}
                          height="200"
                        />
                      </div>
                    ))}
                  </div>
                  <div className="eeg-graph-controls">
                    <button
                      disabled={dataCount === 0}
                      className="control-button"
                      onClick={() => handleGraphButton("fast-backward")}
                    >
                      <img src={fastBackwardIcon} alt="fast backward" />
                    </button>

                    <button
                      disabled={dataCount === 0}
                      className="control-button"
                      onClick={() => handleGraphButton("backward")}
                    >
                      <img src={backwardIcon} alt="backward" />
                    </button>

                    <button
                      className="control-button"
                      onClick={() =>
                        handleGraphButton(isPlaying ? "pause" : "play")
                      }
                    >
                      <img
                        src={isPlaying ? pauseIcon : playIcon}
                        alt={isPlaying ? "pause" : "play"}
                      />
                    </button>

                    <button
                      disabled={dataCount === dataLength - 1}
                      className="control-button"
                      onClick={() => handleGraphButton("forward")}
                    >
                      <img src={forwardIcon} alt="forward" />
                    </button>

                    <button
                      disabled={dataCount === dataLength - 1}
                      className="control-button"
                      onClick={() => handleGraphButton("fast-forward")}
                    >
                      <img src={fastForwardIcon} alt="fast forward" />
                    </button>
                  </div>
                </>
              )}
            </div>
            <div className={loading ? "pwelch-hide" : "pwelch-show"}>
              <PwelchAndHeatMap
                timeLength={
                  eegData
                    ? eegData[0]?.length[
                        dataCount === -1
                          ? eegData[0]?.length?.length - 1
                          : dataCount
                      ]
                    : []
                }
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default TestRecording;
