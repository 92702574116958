import React, { useState, useEffect } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

function CircularProgressWithLabel({ value }) {
  return (
    <div className="loader-wrapper d-flex justify-content-center align-items-center">
      <Box sx={{ position: "relative", display: "inline-flex" }}>
        <CircularProgress variant="determinate" value={value} size="6rem" />
        <Box
          sx={{
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: "absolute",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography
            variant="caption"
            component="div"
            sx={{ color: "text.secondary", fontSize: "1rem" }}
          >{`${Math.round(value)}%`}</Typography>
        </Box>
      </Box>
    </div>
  );
}

export default function CircularLabelLoader({ value }) {
  return <CircularProgressWithLabel value={value} />;
}
