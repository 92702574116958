import { React, useEffect, useState, useRef } from "react";
import AdminPanelHeader from "./AdminPanelHeader";
import { Chart } from "react-chartjs-2";
import { Line } from "react-chartjs-2";
import axios from "axios";
import { useSelector } from "react-redux";
import zoomPlugin from "chartjs-plugin-zoom";
import ClipLoader from "react-spinners/ClipLoader";

export default function EpochsVisuals(props) {
  const token = useSelector((state) => state.loginData.token);
  const [chartData, setChartData] = useState();
  const [myChart, setMyChart] = useState(null);
  const [dataLength, setDataLength] = useState(null);
  const myRef = useRef();
  const isMounted = useRef(false);
  const [isData, setIsData] = useState(false);
  const [epochDetails, setEpochDetails] = useState();
  const [loading, setLoading] = useState(true);

  Chart.register(zoomPlugin);

  useEffect(() => {
    const getData = async () => {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/mongo_recordings/${props.match.params.key}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
      if (response.data.code === 401) {
        isMounted.current = true;
        setLoading(false);
        localStorage.clear();
        window.location.reload();
      } else {
        isMounted.current = true;
        setLoading(false);
        if (response.data.message !== "error") {
          setChartData(response.data.data.CH1_Voltage);
          setDataLength(response.data.data.length);
          setEpochDetails(response.data.data.epoch_details);
          setIsData(true);
        } else {
        }
      }
    };

    getData();
  }, []);

  // Second UseEffect to be executed, it populate the EEG data on the graph
  useEffect(() => {
    if (isMounted.current) {
      if (chartData.length === 0) {
        return;
      }
      if (myChart != null) {
        myChart.destroy();
      }
      setMyChart(
        new Chart(myRef.current, {
          type: "line",
          data: {
            labels: dataLength,
            datasets: [
              {
                label: "CH1 Voltage",
                data: chartData,
                backgroundColor: ["rgba(55, 212, 123,0.6)"],
                pointRadius: 0,
                borderWidth: 1.3,
                borderColor: ["rgba(55, 212, 123,0.8)"],
              },
            ],
          },
          width: 400,
          height: 1000,
          options: {
            scales: {
              x: {
                title: {
                  display: true,
                  text: "Time (seconds)",
                },
              },
              y: {
                title: {
                  display: true,
                  text: "Voltages",
                },
              },
            },
            normalized: true,
            maintainAspectRatio: false,
            animation: false,
            spanGaps: true,
            datasets: {
              line: {
                pointRadius: 0, // disable for all `'line'` datasets
              },
            },
            elements: {
              point: {
                radius: 0, // default to disabled in all datasets
              },
            },
            plugins: {
              decimation: {
                enabled: true,
              },
              zoom: {
                pan: {
                  enabled: true,
                  mode: "x",
                  rangeMin: { x: 0 },
                  rangeMax: { x: 100 },
                },
                zoom: {
                  wheel: {
                    enabled: true,
                    mode: "xy",
                    rangeMin: { x: 0 },
                    rangeMax: { x: 100 },
                  },
                  pinch: {
                    enabled: true,
                  },
                  mode: "x",
                },
              },
            },
          },
        })
      );
    }
  }, [chartData, isData]);

  return (
    <div>
      <AdminPanelHeader />
      <div className="overview-title" style={{ textAlign: "center" }}>
        <h3>Epoch Details </h3>
      </div>
      <table class="table">
        <thead>
          <tr
            style={{
              color: "#166e78",
              fontSize: "22px",
              textAlign: "center",
            }}
          >
            <th scope="col">Mongo Key</th>
            <th scope="col">Patient visit ID</th>
            <th scope="col">Start Time</th>
            <th scope="col">End Time</th>
          </tr>
        </thead>
        <tbody>
          <tr style={{ textAlign: "center" }}>
            <td>{props.match.params.key}</td>
            <td>{epochDetails ? epochDetails.patient_visit_id : "-"}</td>
            <td>{epochDetails ? epochDetails.start_time + "s" : "-"}</td>
            <td>{epochDetails ? epochDetails.end_time + "s" : "-"}</td>
          </tr>
        </tbody>
      </table>
      {loading ? (
        <div
          style={{
            textAlign: "center",
            paddingTop: "2%",
          }}
        >
          {" "}
          <ClipLoader />{" "}
          <h3 style={{ fontFamily: "lato" }}>Graph Loading...</h3>
        </div>
      ) : (
        <div className="graph-box">
          <canvas id="chart" ref={myRef} height="400" />
        </div>
      )}
    </div>
  );
}
