import React from "react";
import "../assets/css/accountsettings.css";
import Header from "./header/Header";

import Tabs from "./Tabs/Tabs";
import TabsData from "./Tabs/TabsData";
import { CardText } from "react-bootstrap-icons";

function AccountSetting() {
  return (
    <div>
      <Header />
      {/* <section className="account-setting bg-light-pa">
    <div className="container h-100">
      <div className="row mx-auto">
        <div className="col">
          <div className="py-4 d-flex justify-content-between div-space">
            <div className="d-flex">
              <h3 className="pateint-heading space-white mb-0">Account Settings</h3>
            </div>
          </div>
        </div>
      </div>

      <div className="row mx-auto h-75">
        <div className="col body-s left">
          <ul className="nav nav-tabs nav-set">
            <li className="nav-item bg-white color-active">
              <a className="nav-link active" data-bs-toggle="tab" href="#home">Personal Settings</a>
            </li>
            <li className="nav-item bg-white color-active">
              <a className="nav-link" data-bs-toggle="tab" href="#menu1">Security</a>
            </li>
            <li className="nav-item bg-white color-active">
              <a className="nav-link" data-bs-toggle="tab" href="#menu2">Clinical Settings</a>
            </li>
            <li className="nav-item bg-white color-active">
              <a className="nav-link" data-bs-toggle="tab" href="#menu3">Billing</a>
            </li>
          </ul>
        </div>
        <div className="col flex-1 body-s right position-relative">
        <form>
        <div className="tab-content pt-4 px-3">
              <div className="tab-pane container active" id="home">
                  <div className="row g-3 d-flex justify-content-between">
                    <div className="col-auto">
                      <label for="inputPassword6" className="col-form-label">Full Name:</label>
                    </div>
                    <div className="col-auto ">
                      <input type="password" id="inputPassword6" name="name"   className="form-control" aria-describedby="passwordHelpInline"/>
                    </div>
                  </div>
                  <div className="row g-3 mt-3 d-flex justify-content-between">
                    <div className="col-auto">
                      <label for="inputPassword6" className="col-form-label">Phone Number:</label>
                    </div>
                    <div className="col-auto ">
                      <input type="password" id="inputPassword6" className="form-control" aria-describedby="passwordHelpInline"/>
                    </div>
                  </div>
                  <div className="row g-3 mt-3 d-flex justify-content-between">
                    <div className="col-auto">
                      <label for="inputPassword6" className="col-form-label">Date of Birth:</label>
                    </div>
                    <div className="col-auto ">
                      <input type="password" id="inputPassword6" className="form-control" aria-describedby="passwordHelpInline"/>
                    </div>
                  </div>
                    <div className="row g-3 mt-3 d-flex justify-content-between mb-4">
                      <div className="col-auto">
                        <label for="inputPassword6" className="col-form-label">Email Address:</label>
                      </div>
                      <div className="col-auto ">
                        <input type="password" id="inputPassword6" className="form-control" aria-describedby="passwordHelpInline"/>
                      </div>
                      
                    </div>
                    <div className="d-flex justify-content-end">
                      <button className="btn btn-primary">Verfiy Email</button>
                    </div>
                    <div className="d-flex justify-content-end mt-4  btn-po">
                      <button className="btn btn-outline-primary m-right">Clear Changes</button>
                      <button className="btn btn-primary">Save changes</button>
                    </div>
              </div>
            <div className="tab-pane container fade" id="menu1">
              <div className="row g-3 d-flex justify-content-between">
                <div className="col-auto">
                  <label for="inputPassword6" className="col-form-label">Full Name:</label>
                </div>
                <div className="col-auto ">
                  <input type="password" id="inputPassword6" className="form-control" aria-describedby="passwordHelpInline"/>
                </div>
              </div>
              <div className="row g-3 mt-3 d-flex justify-content-between">
                <div className="col-auto">
                  <label for="inputPassword6" className="col-form-label">Phone Number:</label>
                </div>
                <div className="col-auto ">
                  <input type="password" id="inputPassword6" className="form-control" aria-describedby="passwordHelpInline"/>
                </div>
              </div>
              <div className="row g-3 mt-3 d-flex justify-content-between">
                <div className="col-auto">
                  <label for="inputPassword6" className="col-form-label">Date of Birth:</label>
                </div>
                <div className="col-auto ">
                  <input type="password" id="inputPassword6" className="form-control" aria-describedby="passwordHelpInline"/>
                </div>
              </div>
              <div className="row g-3 mt-3 d-flex justify-content-between">
                <div className="col-auto">
                  <label for="inputPassword6" className="col-form-label">Password:</label>
                </div>
                <div className="col-auto ">
                  <input type="password" id="inputPassword6" className="form-control" aria-describedby="passwordHelpInline"/>
                </div>
              </div>
              <div className="row g-3 mt-3 d-flex justify-content-between">
                <div className="col-auto">
                  <label for="inputPassword6" className="col-form-label">Email Address:</label>
                </div>
                <div className="col-auto ">
                  <input type="password" id="inputPassword6" className="form-control" aria-describedby="passwordHelpInline"/>
                </div>
              </div>
              <div className="row g-3 mt-3 d-flex justify-content-between">
                <div className="col-auto">
                  <label for="inputPassword6" className="col-form-label">House Address:</label>
                </div>
                <div className="col-auto ">
                  <input type="password" id="inputPassword6" className="form-control" aria-describedby="passwordHelpInline"/>
                </div>
              </div>
            
              <div className="d-flex justify-content-end mt-4">
                <button className="btn btn-primary">Verfiy Email</button>
              </div>
              <div className="d-flex justify-content-end mt-4  btn-po">
                <button className="btn btn-outline-primary m-right">Clear Changes</button>
                <button className="btn btn-primary">Save changes</button>
                                  
              </div>
            </div>
            <div className="tab-pane container fade" id="menu2">  
              <div className="row g-3 d-flex justify-content-between">
                <div className="col-auto">
                  <label for="inputPassword6" className="col-form-label">Full Name:</label>
                </div>
                <div className="col-auto ">
                  <input type="password" id="inputPassword6" className="form-control" aria-describedby="passwordHelpInline"/>
                </div>
              </div>
              <div className="row g-3 mt-3 d-flex justify-content-between">
                <div className="col-auto">
                  <label for="inputPassword6" className="col-form-label">Phone Number:</label>
                </div>
                <div className="col-auto ">
                  <input type="password" id="inputPassword6" className="form-control" aria-describedby="passwordHelpInline"/>
                </div>
              </div>
              <div className="row g-3 mt-3 d-flex justify-content-between">
                <div className="col-auto">
                  <label for="inputPassword6" className="col-form-label">Date of Birth:</label>
                </div>
                <div className="col-auto ">
                  <input type="password" id="inputPassword6" className="form-control" aria-describedby="passwordHelpInline"/>
                </div>
              </div>
              <div className="row g-3 mt-3 d-flex justify-content-between">
                <div className="col-auto">
                  <label for="inputPassword6" className="col-form-label">Password:</label>
                </div>
                <div className="col-auto ">
                  <input type="password" id="inputPassword6" className="form-control" aria-describedby="passwordHelpInline"/>
                </div>
              </div>
              <div className="d-flex justify-content-end mt-4">
                <button className="btn btn-primary">Verfiy Email</button>
              </div>
              <div className="d-flex justify-content-end mt-4  btn-po">
                <button className="btn btn-outline-primary m-right">Clear Changes</button>
                <button className="btn btn-primary">Save changes</button>
              </div>
            </div>
            <div className="tab-pane container fade" id="menu3">  
              <div className="row g-3 d-flex justify-content-between">
                <div className="col-auto">
                  <label for="inputPassword6" className="col-form-label">Full Name:</label>
                </div>
                <div className="col-auto ">
                  <input type="password" id="inputPassword6" className="form-control" aria-describedby="passwordHelpInline"/>
                </div>
              </div>
              <div className="row g-3 mt-3 d-flex justify-content-between">
                <div className="col-auto">
                  <label for="inputPassword6" className="col-form-label">Phone Number:</label>
                </div>
                <div className="col-auto ">
                  <input type="password" id="inputPassword6" className="form-control" aria-describedby="passwordHelpInline"/>
                </div>
              </div>
              <div className="row g-3 mt-3 d-flex justify-content-between">
                <div className="col-auto">
                  <label for="inputPassword6" className="col-form-label">Date of Birth:</label>
                </div>
                <div className="col-auto ">
                  <input type="password" id="inputPassword6" className="form-control" aria-describedby="passwordHelpInline"/>
                </div>
              </div>
              <div className="row g-3 mt-3 d-flex justify-content-between">
                <div className="col-auto">
                  <label for="inputPassword6" className="col-form-label">Password:</label>
                </div>
                <div className="col-auto ">
                  <input type="password" id="inputPassword6" className="form-control" aria-describedby="passwordHelpInline"/>
                </div>
              </div>
              <div className="row g-3 mt-3 d-flex justify-content-between">
                <div className="col-auto">
                  <label for="inputPassword6" className="col-form-label">Email Address:</label>
                </div>
                <div className="col-auto ">
                  <input type="password" id="inputPassword6" className="form-control" aria-describedby="passwordHelpInline"/>
                </div>
              </div>
              <div className="row g-3 mt-3 d-flex justify-content-between">
                <div className="col-auto">
                  <label for="inputPassword6" className="col-form-label">House Address:</label>
                </div>
                <div className="col-auto ">
                  <input type="password" id="inputPassword6" className="form-control" aria-describedby="passwordHelpInline"/>
                </div>
              </div>
              <div className="row g-3 mt-3 d-flex justify-content-between">
                <div className="col-auto">
                  <label for="inputPassword6" className="col-form-label">Location:</label>
                </div>
                <div className="col-auto ">
                  <input type="password" id="inputPassword6" className="form-control" aria-describedby="passwordHelpInline"/>
                </div>
              </div>
              <div className="d-flex justify-content-end mt-4">
                <button className="btn btn-primary">Verfiy Email</button>
              </div>
              <div className="d-flex justify-content-end mt-4  btn-po">
                <button className="btn btn-outline-primary m-right">Clear Changes</button>
                <button className="btn btn-primary">Save changes</button>
              </div>
            </div>
          </div>
        </form>
        </div>
      </div>
      </div>
  </section> */}
      <div>
        <Tabs data={TabsData.jobs} />
      </div>
    </div>
  );
}

export default AccountSetting;
