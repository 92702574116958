import React, { useEffect, useState } from "react";
import { Button, Col, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
// import Swiper core and required modules
import SwiperCore, { Pagination, Scrollbar } from "swiper";
// modules styles
import "swiper/components/navigation/navigation.min.css";
import "swiper/components/pagination/pagination.min.css";
// swiper bundle styles
import "swiper/swiper-bundle.min.css";
// swiper core styles
import "swiper/swiper.min.css";
import {
  createPatientVisit,
  listPatientVisit,
} from "../actions/patientActions";
import "../assets/css/style2.css";
import AdminPanelHeader from "../components/AdminPanelHeader";
import DashSideNav from "../components/DashSideNav";
import { PATIENTVISIT_CREATE_RESET } from "../constants/patientConstants";
// install Swiper modules
SwiperCore.use([Pagination, Scrollbar]);

export default function FileUpload() {
  const { id: patientID } = useParams();

  const { patientCreate, token, patientList, doctorList } = useSelector(
    (state) => ({
      patientCreate: state.patientVisitCreate,
      token: state.loginData.token,
      patientList: state.patientList,
      doctorList: state.patientVisitList,
    })
  );

  const { success, data: success_data } = patientCreate;
  const { error, loading, patients } = patientList;
  const [name, setName] = useState("");
  const [hospital_patient_id, setPatientid] = useState("");
  const [doctors, setDoctors] = useState(localStorage.userId);
  const [timestamp, setTimestamp] = useState(null);
  const [duration, setDuration] = useState("");
  const [file, setFile] = useState("");
  const [location, setLocation] = useState("");
  const [charge, setCharge] = useState("");
  const [clinical_condition, setCondition] = useState("");
  // use useHistory to redirect to the previous page
  const history = useHistory();

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(listPatientVisit(token));

    if (success && success_data) {
      history.push(`/recording/${success_data.data.id}`);
      dispatch({ type: PATIENTVISIT_CREATE_RESET });
    }
  }, [dispatch, history, success, success_data, token]);

  const handleFileSelect = (event) => {
    setFile(event.target.files[0]);
  };

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(
      createPatientVisit({
        name,
        hospital_patient_id, // FIXME: where you are getting this?
        duration, // FIXME: why this? if we have timestamp?
        doctor_id: doctors,
        location,
        in_charge: charge,
        timestamp,
        clinical_condition,
        eeg_file: file,
        token,
        patient_id: patientID,
      })
    );
  };

  return (
    <>
      <AdminPanelHeader />
      <section className="dashboard-sections">
        <div className="container-fluid">
          <div className="dashboard-inner row">
            <div className="left-sidebar col-lg-6">
              <nav className="navbar navbar-expand-lg">
                <button
                  className="navbar-toggler"
                  type="button"
                  data-toggle="collapse"
                  data-target="#navbarSupportedContent"
                  aria-controls="navbarSupportedContent"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <span className="navbar-toggler-icon" />
                </button>
                <div
                  className="collapse navbar-collapse"
                  id="navbarSupportedContent"
                >
                  <DashSideNav />
                </div>
              </nav>
            </div>
            <div className="patient-overview col-lg-9 ">
              <div>
                {/* <!-- Account details card--> */}
                <div className="card mb-4">
                  <div className="card-header text-cyan">
                    File Upload Patient
                  </div>

                  <div className="card-body">
                    <Form onSubmit={submitHandler}>
                      {/* <!-- Form Group (username)--> */}
                      <div className="row">
                        <div className="row mb-3">
                          {/* <!-- Form Group (organization name)--> */}

                          {/* <!-- Form Group (location)--> */}
                          <div className="col-md-6">
                            <Form.Label className="small mb-1">
                              Doctor:
                            </Form.Label>
                            <span
                              style={{ color: "red", marginLeft: "0.1rem" }}
                            >
                              *
                            </span>

                            <Form.Select
                              aria-label="Default select example"
                              defaultValue={doctors}
                              onChange={(e) => {
                                setDoctors(e.target.value);
                              }}
                              required
                            >
                              {/* <option value={localStorage.userId}>
                                Select a doctorp
                              </option> */}
                              {doctorList?.patients?.length > 0 &&
                                doctorList?.patients?.map((doctor, index) => {
                                  return (
                                    <option
                                      key={doctor.id}
                                      value={doctor.id}
                                      selected={
                                        localStorage.userId == doctor.id
                                      }
                                    >
                                      {doctor.name}
                                    </option>
                                  );
                                })}
                            </Form.Select>
                          </div>
                          <div className="col-md-6">
                            <div className="mb-3">
                              <Form.Group controlId="location">
                                <Form.Label className="small mb-1">
                                  Location{" "}
                                  <span className="text-danger">*</span>
                                </Form.Label>
                                <Form.Control
                                  row="5"
                                  value={location}
                                  onChange={(e) => setLocation(e.target.value)}
                                  placeholder="e.g C3, F7"
                                  required
                                ></Form.Control>
                              </Form.Group>
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* <!-- Form Row--> */}

                      <div className="row mb-3">
                        {/* <!-- Form Group (organization name)--> */}

                        <div className="col-md-6">
                          <Form.Group
                            as={Col}
                            md="12"
                            xs="12"
                            controlId="validationCustom03"
                          >
                            <Form.Label className="small mb-1">
                              First Visit Date{" "}
                              <span className="text-danger">*</span>
                            </Form.Label>
                            <Form.Control
                              onChange={(e) => setTimestamp(e.target.value)}
                              type="datetime-local"
                              required
                            />
                            <Form.Control.Feedback type="invalid">
                              Enter a valid birth date.
                            </Form.Control.Feedback>
                            <Form.Control.Feedback>
                              Looks good!
                            </Form.Control.Feedback>
                          </Form.Group>
                        </div>

                        <div className="col-md-6">
                          <Form.Group controlId="charge">
                            <Form.Label className="small mb-1">
                              Technician in charge:
                            </Form.Label>
                            <Form.Control
                              row="5"
                              value={charge}
                              onChange={(e) => setCharge(e.target.value)}
                            ></Form.Control>
                          </Form.Group>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          {/* <div className="mb-3">
                            <Form.Group controlId="duration">
                              <Form.Label className="small mb-1">
                                Duration
                              </Form.Label>
                              <Form.Control
                                row="5"
                                value={duration}
                                onChange={(e) => setDuration(e.target.value)}
                              ></Form.Control>
                            </Form.Group>
                          </div> */}
                          <div className="mb-3">
                            <Form.Label className="small mb-1">
                              Clinical Condition:{" "}
                              <span className="text-danger">*</span>
                            </Form.Label>

                            <Form.Select
                              aria-label="Default select example"
                              onChange={(e) => {
                                setCondition(e.target.value);
                              }}
                              defaultValue={clinical_condition}
                              required
                            >
                              <option value="">Select Patient condition</option>
                              <option value="2">Concussions</option>
                              <option value="1">Seizure</option>
                              <option value="3">Stroke</option>
                            </Form.Select>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="row gx-3 mb-3">
                            {/* <!-- Form Group (organization name)--> */}

                            {/* <!-- Form Group (location)--> */}

                            <div className="mb-3">
                              <Form.Group
                                controlId="formFileLg"
                                className="mb-3"
                              >
                                <Form.Label>
                                  EEG File{" "}
                                  <span className="text-danger">*</span>
                                </Form.Label>
                                <Form.Control
                                  type="file"
                                  accept=".xlsx, .xls, .csv"
                                  onChange={handleFileSelect}
                                  required
                                />
                              </Form.Group>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="card-body p-3">
                        <Form.Group controlId="button">
                          <Button type="submit" variant="cyan">
                            Submit{" "}
                            {loading && (
                              <span className="spinner-border text-white spinner-border-sm ms-1"></span>
                            )}
                          </Button>
                        </Form.Group>
                      </div>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Swiper JS */}
    </>
  );
}
