import { styled } from "styled-components";

export const TableHead = styled.div`
    display: grid;
    grid-template-columns: repeat(${props => props.len + 2}, 1fr);
    border-bottom: 2px solid #D0D3D4;
    padding: 10px;
    /* min-width:1350px; */

    @media (max-width: 1390px) {
        grid-template-columns: repeat(${props => props.len + 2}, 200px);
    }

    @media (max-width: 1280px) {
        grid-template-columns: repeat(${props => props.len + 2}, 180px);
    }

    @media (max-width: 1190px) {
        grid-template-columns: repeat(${props => props.len + 2}, 150px);
    }

    @media (max-width: 1040px) {
        grid-template-columns: repeat(${props => props.len + 2}, 130px);
    }

    @media (max-width: 940px) {
        grid-template-columns: repeat(${props => props.len + 2}, 1fr);
        /* min-width:1350px; */
        min-width: 100vw;
    }
`;

export const Checkbox = styled.input`
    width: 18px;
    height: 18px;
`;

export const TableBody = styled.div`
    /* min-width:1350px; */
`;

export const TableRow = styled.div`
    display: grid;
    grid-template-columns: repeat(${props => props.len + 2}, 1fr);
    padding: 10px;
    background-color: ${props => props.selected || props.selectAll ? '#D9EDEE' : ''};

    @media (max-width: 1390px) {
        grid-template-columns: repeat(${props => props.len + 2}, 200px);
    }

    @media (max-width: 1280px) {
        grid-template-columns: repeat(${props => props.len + 2}, 180px);
    }

    @media (max-width: 1190px) {
        grid-template-columns: repeat(${props => props.len + 2}, 150px);
    }

    @media (max-width: 1040px) {
        grid-template-columns: repeat(${props => props.len + 2}, 130px);
    }

    @media (max-width: 940px) {
        grid-template-columns: repeat(${props => props.len + 2}, 1fr);
    }

    &:hover {
        background-color: #D9EDEE;
    }

    &:hover .hover-options {
        opacity: 1;
    }

    img {
        cursor: pointer;
    }
`;