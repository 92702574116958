import { createSlice } from "@reduxjs/toolkit";

export const logins = createSlice({
  name: "loginData",
  initialState: {
    status: localStorage.getItem("status")
      ? localStorage.getItem("status")
      : false,
    userId: localStorage.getItem("userId")
      ? localStorage.getItem("userId")
      : null,
    token: localStorage.getItem("token") ? localStorage.getItem("token") : null,
    patientVisitId: localStorage.getItem("patientVisitId")
      ? localStorage.getItem("patientVisitId")
      : null,
  },
  reducers: {
    loginUser: (state, action) => {
      state.status = true;
      state.userId = action.payload.userId;
      state.token = action.payload.token;
    },
    logoutUser: (state) => {
      state.status = false;
      state.userId = null;
      state.token = null;
      state.patientVisitId = null;
    },
    // incrementByAmount: (state, action) => {
    //   state.value += action.payload;
    // },
  },
});

// Action creators are generated for each case reducer function
export const { loginUser, logoutUser } = logins.actions;
export default logins.reducer;
