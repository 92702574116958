import React, { useState, useRef, useEffect } from 'react';

import { GridContainer, GridItem, Header, Body, TwoColumn } from "./GridView.styles";

import dotMenuIcon from "../../../assets/img/svg/dots-menu-icon.svg";
import { DropDownItem } from '../../header/Header';

import close from "../../../assets/img/svg/close-icon.svg";
import edit from "../../../assets/img/svg/edit-icon.svg";
import history from "../../../assets/img/svg/history-icon.svg";
import calendar from "../../../assets/img/svg/calendar-icon.svg";
import selectIcon from "../../../assets/img/svg/select-icon.svg";

import "./grid-view.css";

const GridView = ({ data, keys, handleDelete }) => {
  return (
    <GridContainer>
      {data.map((item, index) => <Card key={index} data={item} index={index} keys={keys} handleDelete={handleDelete} />)}
    </GridContainer>
  );
}

export default GridView;

const Card = ({data, keys, index, handleDelete}) => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [isSelected, setIsSelected] = useState(false);
    const toggleIsMenuOpen = () => setIsMenuOpen(prevState => !prevState);
    const toggleIsSelected = () => setIsSelected(prevState => !prevState);
    const dotMenuRef = useRef(null);

    useEffect(() => {
      const handleClickOutside = (event) => {
          if (dotMenuRef.current && !dotMenuRef.current.contains(event.target)) {
              setIsMenuOpen(false);
          }
      };

      document.addEventListener('mousedown', handleClickOutside);

      return () => {
          document.removeEventListener('mousedown', handleClickOutside);
      };
    }, []);

  return (
    <GridItem isSelected={isSelected}>
        <Header>
            <h2 className='grid-item-name'>{data.name ? data.name : data['IRB#']}</h2>
            <div className='dot-menu-icon'>
              <img src={dotMenuIcon} alt="dot menu" onClick={toggleIsMenuOpen}/>
              {isMenuOpen && 
                <div ref={dotMenuRef} className='dot-menu'>
                        <div className='divider-item'>
                          <DropDownItem item={{title: 'Select', icon: selectIcon, to: "#"}} handleClick={toggleIsSelected}/>
                        </div>
                        <DropDownItem item={{title: 'Recording', icon: calendar, to: `/recording/${data.id}`}}/>
                        <DropDownItem item={{title: 'View History', icon: history, to: `/listrecordings/${data.id}`}}/>
                        <DropDownItem item={{title: 'Edit Patient', icon: edit, to: "#"}}/>
                        <DropDownItem item={{title: 'Delete Patient', icon: close, to: "#"}} handleClick={handleDelete} index={data.id}/>
                </div>
              }
            </div>
      </Header>
      <Body>
        <TwoColumn>
          <p>{keys[1]}: {data[keys[1]] ? data[keys[1]] : 'null'}</p>
          <p>{keys[2]}: {data[keys[2]]}</p>
        </TwoColumn>
        <TwoColumn>
          <p>{keys[3]}:</p> <p>{keys[3] === 'patient#' ? data['id'] : data[keys[3]]}</p>
        </TwoColumn>
        <TwoColumn>
          <p>{keys[4]?.split("_")[0]}{keys[4]?.split("_")[1]}:</p> <p>{data[keys[4]]}</p>
        </TwoColumn>
        <TwoColumn>
          <p>{keys[5]?.split("_")[0]}{keys[5]?.split("_")[1]}:</p> <p>{data[keys[5]]}</p>
        </TwoColumn>
      </Body>
    </GridItem>
  );
}