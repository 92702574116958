import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useEffect, useState } from "react";
import axios from "axios";

import "./PersonalSettings.css";

const onSubmit = (values, actions) => {
  console.log("values", values);

  actions.resetForm();
};

function PersonalSettingsForm() {
  const formik = useFormik({
    initialValues: {
      fullName: "",
      phoneNumber: "",
      dateOfBirth: "",
      email: "",
    },
    validationSchema: Yup.object({
      fullName: Yup.string()
        .max(30, "Must be 30 characters or less")
        .required("Required"),
      phoneNumber: Yup.string()
        .min(12, "Phone No will be at least 12 characters")
        .required("Required"),
      dateOfBirth: Yup.date().required("Required"),
      email: Yup.string()
        .email("Please enter a valid email")
        .required("Required"),
    }),
    onSubmit,
  });
  console.log(formik);
  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="tab-pane container pt-4 active" id="home">
        <div className="row g-3 d-flex justify-content-between">
          <div className="col-auto">
            <label htmlFor="fullName" className="col-form-label">
              Full Name:
            </label>
          </div>
          <div className="col-auto ">
            <input
              value={formik.values.fullName}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              placeholder="Enter your Full Name"
              id="fullName"
              className={`form-control control-css ${
                formik.errors.fullName &&
                formik.touched.fullName &&
                "input-error"
              }`}
              aria-describedby="passwordHelpInline"
            />
            {formik.errors.fullName && formik.touched.fullName && (
              <p className="error">{formik.errors.fullName}</p>
            )}
          </div>
        </div>
        <div className="row g-3 mt-3 d-flex justify-content-between">
          <div className="col-auto">
            <label htmlFor="phoneNumber" className="col-form-label">
              Phone Number:
            </label>
          </div>
          <div className="col-auto width-container">
            <input
              value={formik.values.phoneNumber}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              placeholder="Enter Your Phone Number"
              id="phoneNumber"
              className={`form-control control-css ${
                formik.errors.phoneNumber &&
                formik.touched.phoneNumber &&
                "input-error"
              }`}
              aria-describedby="passwordHelpInline"
            />
            {formik.errors.phoneNumber && formik.touched.phoneNumber && (
              <p className="error">{formik.errors.phoneNumber}</p>
            )}
          </div>
        </div>
        <div className="row g-3 mt-3 d-flex justify-content-between">
          <div className="col-auto">
            <label htmlFor="dateOfBirth" className="col-form-label">
              Date of Birth:
            </label>
          </div>
          <div className="col-auto width-side">
            <input
              value={formik.values.dateOfBirth}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              type="date"
              id="dateOfBirth"
              className={`form-control control-css ${
                formik.errors.dateOfBirth &&
                formik.touched.dateOfBirth &&
                "input-error"
              }`}
              aria-describedby="passwordHelpInline"
            />
            {formik.errors.dateOfBirth && formik.touched.dateOfBirth && (
              <p className="error">{formik.errors.dateOfBirth}</p>
            )}
          </div>
        </div>
        <div className="row g-3 mt-3 d-flex justify-content-between mb-4">
          <div className="col-auto">
            <label htmlFor="email" className="col-form-label">
              Email Address:
            </label>
          </div>
          <div className="col-auto ">
            <input
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              placeholder="Enter your Email Address"
              type="email"
              id="email"
              className={`form-control control-css ${
                formik.errors.email && formik.touched.email && "input-error"
              }`}
              aria-describedby="passwordHelpInline"
            />
            {formik.errors.email && formik.touched.email && (
              <p className="error">{formik.errors.email}</p>
            )}
          </div>
        </div>
        <div className="d-flex justify-content-end">
          <button className="btn btn-primary control-css">Verfiy Email</button>
        </div>
        <div className="d-flex justify-content-end mt-3  btn-po">
          <button
            className="btn btn-outline-primary mar-r control-css"
            onClick={formik.handleReset}
          >
            Clear Changes
          </button>
          <button className="btn btn-primary control-css" type="submit">
            Save changes
          </button>
        </div>
      </div>
    </form>
  );
}

export default PersonalSettingsForm;
