import "../assets/css/style2.css";
import axios from "axios";
import { Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { Form, Button, Row, Col } from "react-bootstrap";
import SideBar from "../components/SideBar";
import patient_image2 from "../assets/img/default.jpg";
import box from "../assets/img/inbox-expand.png";
import { Swiper, SwiperSlide } from "swiper/react";
import { useDispatch, useSelector } from "react-redux";
import DashSideNav from "../components/DashSideNav";
import { createPatient } from "../actions/patientActions";
import { PATIENT_CREATE_RESET } from "../constants/patientConstants";
// swiper bundle styles
import "swiper/swiper-bundle.min.css";
// swiper core styles
import "swiper/swiper.min.css";
// modules styles
import "swiper/components/navigation/navigation.min.css";
import "swiper/components/pagination/pagination.min.css";
// import Swiper core and required modules
import SwiperCore, { Pagination, Scrollbar } from "swiper";
// install Swiper modules
SwiperCore.use([Pagination, Scrollbar]);

export default function NewPatient() {
  const patientCreate = useSelector((state) => state.patientCreate);
  const { success } = patientCreate;
  const token = useSelector((state) => state.loginData.token);
  const [name, setName] = useState("");
  const [dob, setDOB] = useState("");
  const [gender, setGender] = useState("");
  const [first_visit_date, setFVD] = useState("");
  const [clinical_condition, setCondition] = useState("");
  const [blood_group, setBG] = useState("");
  // const userLogin = useSelector(state => state.userLogin)
  // const { userInfo } = userLogin

  const dispatch = useDispatch();

  useEffect(() => {
    if (success) {
      dispatch({ type: PATIENT_CREATE_RESET });
    }
  }, [dispatch, success]);

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(
      createPatient({
        name,
        dob,
        gender,
        first_visit_date,
        clinical_condition,
        blood_group,
        token,
      })
    );
  };

  return (
    <>
      <section className="dashbpard-sections">
        <div className="container-fluid">
          <div className="dashboard-inner row">
            <div className="left-sidebar col-lg-6">
              <nav className="navbar navbar-expand-lg">
                <button
                  className="navbar-toggler"
                  type="button"
                  data-toggle="collapse"
                  data-target="#navbarSupportedContent"
                  aria-controls="navbarSupportedContent"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <span className="navbar-toggler-icon" />
                </button>
                <div
                  className="collapse navbar-collapse"
                  id="navbarSupportedContent"
                >
                  <DashSideNav />
                </div>
              </nav>
            </div>
            <div className="patient-overview col-lg-9 ">
              <div>
                {/* <!-- Account details card--> */}
                <div className="card mb-4">
                  <div className="card-header text-cyan">New Patient</div>
                  <div className="card-body">
                    <Form onSubmit={submitHandler}>
                      {/* <!-- Form Group (username)--> */}
                      <div className="mb-3">
                        <Form.Group controlId="name">
                          <Form.Label className="small mb-1">Name</Form.Label>
                          <Form.Control
                            row="5"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                          ></Form.Control>
                        </Form.Group>
                      </div>
                      {/* <!-- Form Row--> */}

                      <div className="row gx-3 mb-3">
                        {/* <!-- Form Group (organization name)--> */}
                        <div className="col-md-6">
                          <Row>
                            <Form.Group
                              as={Col}
                              md="12"
                              xs="12"
                              controlId="validationCustom03"
                            >
                              <Form.Label className="small mb-1">
                                Date of Birth{" "}
                                <span
                                  style={{ color: "red", marginLeft: "0.1rem" }}
                                >
                                  *
                                </span>
                              </Form.Label>
                              <Form.Control
                                onChange={(e) => setDOB(e.target.value)}
                                type="date"
                                required
                              />
                              <Form.Control.Feedback type="invalid">
                                Enter a valid birth date.
                              </Form.Control.Feedback>
                              <Form.Control.Feedback>
                                Looks good!
                              </Form.Control.Feedback>
                            </Form.Group>
                          </Row>
                        </div>
                        {/* <!-- Form Group (location)--> */}
                        <div className="col-md-6">
                          <Form.Group controlId="gender">
                            <Form.Label className="small mb-1">
                              Gender
                            </Form.Label>
                            <Form.Control
                              row="5"
                              value={gender}
                              onChange={(e) => setGender(e.target.value)}
                            ></Form.Control>
                          </Form.Group>
                        </div>
                      </div>
                      {/* <!-- Form Group (email address)--> */}
                      <div className="mb-3">
                        
                        <Row>
                          <Form.Group
                            as={Col}
                            md="12"
                            xs="12"
                            controlId="validationCustom03"
                          >
                            <Form.Label className="small mb-1">
                              First Visit Date{" "}
                              <span
                                style={{ color: "red", marginLeft: "0.1rem" }}
                              >
                                *
                              </span>
                            </Form.Label>
                            <Form.Control
                              onChange={(e) => setFVD(e.target.value)}
                              type="date"
                              required
                            />
                            <Form.Control.Feedback type="invalid">
                              Enter a valid birth date.
                            </Form.Control.Feedback>
                            <Form.Control.Feedback>
                              Looks good!
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Row>
                      </div>
                      {/* <!-- Form Row--> */}
                      <div className="row gx-3 mb-3">
                        {/* <!-- Form Group (phone number)--> */}
                        <div className="col-md-6">
                          <Form.Group controlId="clinical_condition">
                            <Form.Label className="small mb-1">
                              Clinical Condition
                            </Form.Label>
                            <Form.Control
                              row="5"
                              value={clinical_condition}
                              onChange={(e) => setCondition(e.target.value)}
                            ></Form.Control>
                          </Form.Group>
                        </div>
                        {/* <!-- Form Group (birthday)--> */}
                        <div className="col-md-6">
                          <Form.Group controlId="blood_group">
                            <Form.Label className="small mb-1">
                              Blood Group
                            </Form.Label>
                            <Form.Control
                              row="5"
                              value={blood_group}
                              onChange={(e) => setBG(e.target.value)}
                            ></Form.Control>
                          </Form.Group>
                        </div>
                      </div>

                      <div className="card-body p-3">
                        <Form.Group controlId="button">
                          <Button type="submit" variant="cyan">
                            Submit
                          </Button>
                        </Form.Group>
                      </div>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Swiper JS */}
    </>
  );
}
