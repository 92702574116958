import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";

import "./listView.css";
import { TableHead, Checkbox, TableBody, TableRow } from "./ListView.styles";

import close from "../../../assets/img/svg/close-icon.svg";
import edit from "../../../assets/img/svg/edit-icon.svg";
import history from "../../../assets/img/svg/history-icon.svg";
import calendar from "../../../assets/img/svg/calendar-icon.svg";
import dotMenu from "../../../assets/img/svg/dots-menu-icon.svg";
import filterIcon from "../../../assets/img/svg/table-filter-icon.svg";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { DropDownItem } from "../../header/Header";

const ListView = ({
  len,
  data,
  keys,
  handleDelete,
  handleSort,
  restorePatient,
  deletePatientPermanently,
  handleDeletePatientVisit,
  restorePatientVisit,
  deletePatientVisitPermanently,
  forHistoryPage,
  forDeletedPage,
  forVisitDeletePage,
  forVisitDeleteHistoryPage,
}) => {
  const [selectAll, setSelectAll] = useState(false);
  const toggleSelectAll = () => setSelectAll((prevState) => !prevState);
  const token = useSelector((state) => state.loginData.token);

  return (
    <div className="list-table">
      <TableHead len={len}>
        <div className="checkbox">
          <Checkbox type="checkbox" onChange={toggleSelectAll} />
        </div>
        {keys.map((item, index) => (
          <p className="table-item" key={index}>
            {item?.split("_")[0]} {item?.split("_")[1]}{" "}
            <img src={filterIcon} alt="filter icon" onClick={handleSort} />
          </p>
        ))}
        <div className="options head">
          <div className="hover-options">
            <img src={calendar} alt="calender" />
            <img src={history} alt="history" />
            <img src={edit} alt="edit" />
            <img src={close} alt="close" />
          </div>
          <img src={dotMenu} alt="menu" />
        </div>
      </TableHead>
      <TableBody>
        {data.map((item, index) => (
          <TableRowComponent
            key={index}
            len={len}
            data={item}
            index={index}
            selectAll={selectAll}
            handleDelete={handleDelete}
            restorePatient={restorePatient}
            deletePatientPermanently={deletePatientPermanently}
            handleDeletePatientVisit={handleDeletePatientVisit}
            restorePatientVisit={restorePatientVisit}
            deletePatientVisitPermanently={deletePatientVisitPermanently}
            forHistoryPage={forHistoryPage}
            forDeletedPage={forDeletedPage}
            forVisitDeletePage={forVisitDeletePage}
            forVisitDeleteHistoryPage={forVisitDeleteHistoryPage}
          />
        ))}
      </TableBody>
    </div>
  );
};

export default ListView;

const TableRowComponent = ({
  len,
  data,
  selectAll,
  handleDelete,
  index,
  restorePatient,
  deletePatientPermanently,
  handleDeletePatientVisit,
  restorePatientVisit,
  deletePatientVisitPermanently,
  forHistoryPage,
  forDeletedPage,
  forVisitDeletePage,
  forVisitDeleteHistoryPage,
}) => {
  const { id, ...otherData } = data;
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isRowSelected, setIsRowSelected] = useState(false);
  const menuRef = useRef(null);

  const toggleIsMenuOpen = () => setIsMenuOpen((prevState) => !prevState);
  const toggleIsRowSeleceted = () =>
    setIsRowSelected((prevState) => !prevState);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setIsMenuOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [menuRef]);

  return (
    <TableRow len={len} selected={isRowSelected} selectAll={selectAll}>
      <div className="checkbox">
        <Checkbox
          type="checkbox"
          onChange={toggleIsRowSeleceted}
          checked={selectAll || isRowSelected}
        />
      </div>

      {Object.keys(otherData).map((item, index) => {
        if (item === "recording_link") {
          return (
            <Link to={data[item]}>
              <button
                className="btn btn-primary control-css"
                style={{
                  fontSize: "14px",
                  padding: "9px 12px",
                  fontWeight: "normal",
                }}
              >
                View Recording
              </button>
            </Link>
          );
        }
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />;
        return (
          <p className="data" key={index}>
            {data[item] ? data[item] : "null"}
          </p>
        );
      })}
      <div className="options">
        {forHistoryPage && (
          <div className="hover-options">
            <Link to={`#`}>
              <img src={calendar} alt="calender" title="Schedule follow up" />
            </Link>
            <Link to={`/listrecordings/${id}`}>
              <img src={history} title="Patient visits" alt="history" />
            </Link>
            <Link to={`/edit-patient/${id}`}>
              <img src={edit} alt="edit" title="Edit patient details" />
            </Link>
            <img
              src={close}
              alt="close"
              title="Delete patient"
              onClick={() => handleDelete(id)}
            />
          </div>
        )}
        {forHistoryPage && (
          <div className="dot-menu-icon" ref={menuRef}>
            <img src={dotMenu} alt="menu" onClick={toggleIsMenuOpen} />
            {isMenuOpen && (
              <div className="dot-menu">
                <DropDownItem
                  item={{
                    title: "View History",
                    icon: history,
                    to: `/listrecordings/${id}`,
                  }}
                />
                <DropDownItem
                  item={{
                    title: "Edit Patient",
                    icon: edit,
                    to: `/edit-patient/${id}`,
                  }}
                />
                <DropDownItem
                  item={{ title: "Delete Patient", icon: close, to: "#" }}
                  handleClick={() => handleDelete(id)}
                />
              </div>
            )}
          </div>
        )}
        {forDeletedPage && (
          <div className="deleted-page-options">
            {/* Apply flexbox to achieve side-by-side layout */}
            <div style={{ display: "flex" }}>
              <button
                type="button"
                className="btn btn-success control-css"
                style={{
                  fontSize: "14px",
                  padding: "9px 12px",
                  fontWeight: "bold",
                }}
                onClick={() => restorePatient(id)}
              >
                Restore Patient
              </button>
              {/* Add margin-left for spacing between buttons */}
              <margin className="btn-margin" style={{ marginLeft: "5px" }} />
              <button
                type="button"
                className="btn btn-danger control-css"
                style={{
                  fontSize: "14px",
                  padding: "9px 12px",
                  fontWeight: "bold",
                }}
                onClick={() => deletePatientPermanently(id)}
              >
                Permanently Delete
              </button>
            </div>
          </div>
        )}
        {forVisitDeletePage && (
          <div className="deleted-page-options">
            <div style={{ display: "flex" }}>
              <margin className="btn-margin" style={{ marginLeft: "1px" }} />
              <button
                type="button"
                className="btn btn-danger control-css"
                style={{
                  fontSize: "14px",
                  padding: "9px 12px",
                  fontWeight: "bold",
                }}
                onClick={() => handleDeletePatientVisit(id)}
              >
                DELETE VISIT
              </button>
            </div>
          </div>
        )}

        {forVisitDeleteHistoryPage && (
          <div className="deleted-page-options">
            {/* Apply flexbox to achieve side-by-side layout */}
            <div style={{ display: "flex" }}>
              <button
                type="button"
                className="btn btn-success control-css"
                style={{
                  fontSize: "14px",
                  padding: "9px 12px",
                  fontWeight: "bold",
                }}
                onClick={() => restorePatientVisit(id)}
              >
                Restore Visit
              </button>
              <margin className="btn-margin" style={{ marginLeft: "5px" }} />
              <button
                type="button"
                className="btn btn-danger control-css"
                style={{
                  fontSize: "14px",
                  padding: "9px 12px",
                  fontWeight: "bold",
                }}
                onClick={() => deletePatientVisitPermanently(id)}
              >
                Permanently Delete
              </button>
            </div>
          </div>
        )}
      </div>
    </TableRow>
  );
};
