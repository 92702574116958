import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Paper,
  Box,
  Collapse,
  Checkbox,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Link, useHistory } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./recording-list-v1.css";

const conditionMap = {
  1: "Seizure",
  2: "Concussions",
  3: "Stroke",
};

const genderMap = {
  1: "M",
  2: "F",
};

const formatDate = (dateString) => {
  try {
    const dateOptions = {
      year: "numeric",
      month: "short",
      day: "numeric",
    };
    const date = new Date(dateString);
    if (isNaN(date.getTime())) {
      throw new Error("Invalid date");
    }
    return new Intl.DateTimeFormat("en-US", dateOptions).format(date);
  } catch (error) {
    console.error("Error formatting date:", error);
    return "Invalid date";
  }
};

const PatientListRecords_v1 = () => {
  const { id } = useParams();
  const [patients, setPatients] = useState([]);
  const [patientInfo, setPatientInfo] = useState({});
  const [expandedRow, setExpandedRow] = useState(null);
  const [selectedVisits, setSelectedVisits] = useState([]);
  const token = useSelector((state) => state.loginData.token);

  useEffect(() => {
    const fetchPatients = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/patient_visit_detail_v1/${id}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setPatients(response.data.data.visits || []);
        setPatientInfo(response.data.data.patient || {});
      } catch (error) {
        console.error("Error fetching patient records:", error);
        toast.error("Failed to load patient data");
      }
    };

    fetchPatients();
  }, [id, token]);

  const toggleRow = (patientId) => {
    setExpandedRow((prevRow) => (prevRow === patientId ? null : patientId));
  };

  const handleDeleteVisitDetail = async (visitId, detailId) => {
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_BASE_URL}/patient/bulk-delete-patient-visit`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          data: {
            ids: [detailId],
          },
        }
      );
      if (response.status === 200) {
        setPatients((prevPatients) =>
          prevPatients.map((visit) => {
            if (visit.id === visitId) {
              return {
                ...visit,
                details: visit.details.filter(
                  (detail) => detail.id !== detailId
                ),
              };
            }
            return visit;
          })
        );
        toast.success("Detail deleted successfully");
      }
    } catch (error) {
      console.error("Error deleting visit detail:", error);
      toast.error("Failed to delete visit detail");
    }
  };

  const handleSelectVisit = (visitId) => {
    setSelectedVisits((prevSelected) =>
      prevSelected.includes(visitId)
        ? prevSelected.filter((id) => id !== visitId)
        : [...prevSelected, visitId]
    );
  };
  const history = useHistory();
  const handleCompareVisits = () => {
    if (selectedVisits.length === 2) {
      const queryString = selectedVisits.map((id) => `id=${id}`).join("&");
      history.push(`/compare-visits?${queryString}`);
    } else {
      alert("Please select exactly 2 visits to compare.");
    }
  };

  return (
    <div
      key={patients.length}
      style={{ backgroundColor: "white", padding: "30px" }}
    >
      <div className="info-container">
        <div className="personal-info">
          <h1>{patientInfo.name}</h1>
          <div className="info-wrapper">
            <div className="info">
              <p>Patient#: </p>
              <p>{patientInfo.patient_id}</p>
            </div>
            <div className="info">
              <p>Age / Gender: </p>
              <p>
                {patientInfo.age} / {genderMap[patientInfo.gender] || "Unknown"}
              </p>
            </div>
            <div className="info">
              <p>Condition: </p>
              <p>{conditionMap[patientInfo.condition] || "Unknown"}</p>
            </div>
            <div className="info">
              <p>Date of Visit: </p>
              <p>{formatDate(patientInfo.timestamp)}</p>
            </div>
            <div className="info">
              <p>Time of Visit: </p>
              <p>{new Date(patientInfo.timestamp).toLocaleTimeString()}</p>
            </div>
          </div>
        </div>
        <div className="btns-container">
          <button className="button btn-default" onClick={handleCompareVisits}>
            Compare visit
          </button>
        </div>
      </div>
      <TableContainer component={Paper}>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell>
                <Typography variant="h6">Date of visit</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="h6">Time</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="h6"># of locs</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="h6">Setting</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="h6">Key Notes</Typography>
              </TableCell>
              <TableCell />
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {patients.map((patient) => (
              <React.Fragment key={patient.id}>
                <TableRow>
                  <TableCell>
                    <Checkbox
                      checked={selectedVisits.includes(patient.id)}
                      onChange={() => handleSelectVisit(patient.id)}
                    />
                  </TableCell>
                  <TableCell>{formatDate(patient.timestamp)}</TableCell>
                  <TableCell>
                    {new Date(patient.timestamp).toLocaleTimeString()}
                  </TableCell>
                  <TableCell>{patient.details.length}</TableCell>
                  <TableCell>Clinic</TableCell>
                  <TableCell>Normal</TableCell>
                  <TableCell>
                    <Link to={`/recording/${patient.details[0]?.id}`}>
                      <button
                        className="button btn-default"
                        style={{
                          padding: "0.35rem 0.7rem",
                          fontSize: "0.9rem",
                        }}
                      >
                        View Recording
                      </button>
                    </Link>
                    <button
                      className="button btn-danger"
                      style={{ padding: "0.35rem 0.7rem", fontSize: "0.9rem" }}
                    >
                      Delete Visit
                    </button>
                  </TableCell>
                  <TableCell>
                    <IconButton
                      aria-label="expand row"
                      size="small"
                      onClick={() => toggleRow(patient.id)}
                    >
                      {expandedRow === patient.id ? (
                        <KeyboardArrowUpIcon />
                      ) : (
                        <KeyboardArrowDownIcon />
                      )}
                    </IconButton>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    style={{ paddingBottom: 0, paddingTop: 0 }}
                    colSpan={8}
                  >
                    <Collapse
                      in={expandedRow === patient.id}
                      timeout="auto"
                      unmountOnExit
                    >
                      <Box margin={1}>
                        <Typography
                          variant="h6"
                          gutterBottom
                          component="div"
                          style={{ fontWeight: "bold" }}
                        >
                          Recordings
                        </Typography>
                        <Table size="small" aria-label="details">
                          <TableHead>
                            <TableRow>
                              <TableCell>Location</TableCell>
                              <TableCell>Length</TableCell>
                              <TableCell>Quality</TableCell>
                              <TableCell>Key Notes</TableCell>
                              <TableCell></TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {patient.details.map((detail) => (
                              <TableRow key={detail.id}>
                                <TableCell>{detail.location}</TableCell>
                                <TableCell>0:40</TableCell>
                                <TableCell>Acceptable</TableCell>
                                <TableCell>Key Notes</TableCell>
                                <TableCell>
                                  <button
                                    className="button btn-danger"
                                    style={{
                                      margin: "0.2rem",
                                      padding: "0.35rem 0.7rem",
                                      fontSize: "0.9rem",
                                    }}
                                    onClick={() =>
                                      handleDeleteVisitDetail(
                                        patient.id,
                                        detail.id
                                      )
                                    }
                                  >
                                    Delete
                                  </button>
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </Box>
                    </Collapse>
                  </TableCell>
                </TableRow>
              </React.Fragment>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <ToastContainer />
    </div>
  );
};

export default PatientListRecords_v1;
