import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory , useParams} from "react-router-dom";
import "../assets/css/verifyaccount.css";
import axios from "axios";

export default function VerifyAccount() {
  const [verificationStatus, setVerificationStatus] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const history = useHistory();
  const params = useParams();
  const encodedToken = params.token;

  useEffect(() => {
    if (verificationStatus === true) {
      setTimeout(() => {
        history.push("/login");
      }, 3000);
    }
  }, [verificationStatus, history]);

  function handleSubmit(e) {
    setLoading(true);

    axios
      .post(`${process.env.REACT_APP_BASE_URL}/verify_account/?token=${encodedToken}`)
      .then((res) => {
        setVerificationStatus(res.data.status);
        if (res.data.status === true) {
          setShowSuccessMessage(true);
          window.alert("Account verification successful!");
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }

  return (
    <div className="container">
      <img src="https://censyn-firmware-data.s3.amazonaws.com/CenSyn-logo.svg" alt="Censyn Logo" />

      <div className="message">
        <p>To complete the verification, please press the button below.</p>
      </div>

      <br />

      {!showSuccessMessage && (
        <div className="message">
          <button className="reset-button" onClick={handleSubmit}>Verify Account</button>
        </div>
      )}

      {showSuccessMessage && (
        <div className="message" style={{ color: 'green' }}>
          <p>Verification successful. Redirecting to login page...</p>
        </div>
      )}

      <br />

      <div className="message" style={{ color: 'black', fontSize: '16px', fontWeight: '400' }}>
        <p>Thanks,<br />The CenSyn Team.</p>
      </div>

      <div className="border-line"></div>

      <div className="footer">
        <p>CenSyn, Inc. 5270 California Ave, Suite#200, Irvine, CA 92617.</p>
      </div>
    </div>
  );
};
