import styled from "styled-components";

export const GridContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, auto));
    gap: 20px;

    @media (max-width: 1200px){
        grid-template-columns: repeat(3, 1fr);
    }

    @media (max-width: 768px){
        grid-template-columns: repeat(2, 1fr);
    }

    @media (max-width: 576px){
        grid-template-columns: repeat(1, 1fr);
    }
    
`;

export const GridItem = styled.div`
    padding: 10px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    color: #092540;
    background-color: #FCFCFC;
    border-radius: 5px;
    background-color: ${props => props.isSelected && '#D9EDEE'};
`;

export const Header = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;

    h2 {
        margin: 0;
        font-size: 30px;
    }
`;

export const Body = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;

    p {
        font-size: 16px;
    }
`;

export const TwoColumn = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;

    p {
        text-transform: capitalize;
    }
`;
