import React from "react";

import "./TabList.css";

function TabList(props) {
  const Clicked = () => {
    props.onClick(props.index);
  };

  return (
    <li key={props.index}>
      <button
        className="section__Jobs-buttonCompany"
        onClick={Clicked}
        // style={
        //   props.activeTabId === props.index
        //     ? { color: "#64ffda" }
        //     : { color: "#8892b0" }
        // }
      >
        {props.data}
      </button>
    </li>
  );
}

export default TabList;
